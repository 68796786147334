import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  setSearchTerm,
  setShowOffcanvas,
  setOffcanvasType,
  setActionCard,
  setSelectedCompany,
  setSortConfig,
  setIsEdit,
  setCompanies,
} from '../../Redux/Slices/EsgConsultantFlow/CompaniesSlice';
import Table from '../../Reusable/ReusableComponents/Table';
import { fetchCompanies, removeCompany } from '../../Redux/Actions/Actions';
import AddCompany from '../EsgConsultantCompaniesFlow/AddCompany';
import FilterOffcanvas from '../EsgConsultantCompaniesFlow/FilterOffcanvas';
import ActionCard from '../EsgConsultantCompaniesFlow/ActionCard';
import Pagination from '../../Reusable/ReusableComponents/Pagination';
import { CiSearch } from "react-icons/ci";
import { IoFilter } from "react-icons/io5";
import { TiStarOutline } from "react-icons/ti";
import { BsThreeDotsVertical, } from "react-icons/bs";
import moment from 'moment';
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TopNavbar from '../../Reusable/ReusableComponents/TopNavbar';
import Loader from '../../config/Loader.jsx';

const CompaniesList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const actionCardRef = useRef(null);
  const companies = useSelector((state) => state.companies.companies);
  const searchTerm = useSelector((state) => state.companies.searchTerm);
  const showOffcanvas = useSelector((state) => state.companies.showOffcanvas);
  const offcanvasType = useSelector((state) => state.companies.offcanvasType);
  const actionCard = useSelector((state) => state.companies.actionCard);
  const selectedCompany = useSelector((state) => state.companies.selectedCompany);
  const sortConfig = useSelector((state) => state.companies.sortConfig);
  const isEdit = useSelector((state) => state.companies.isEdit);
  const [actionDropdown, setActionDropdown] = useState(null);
  const [expandedRow, setExpandedRow] = useState({ company_id: null, option: '', selectedRole: null });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(7);
  const totalPages = companies ? Math.ceil(companies.length / itemsPerPage) : 0;
  const [loading, setLoading] = useState(false);
  const [filteredCompanies, setFilteredCompanies] = useState([])

  const roles = localStorage.getItem("selected_roleId")

  const isESGConsultantOwner = roles.includes(1);
  const isESGConsultantAdmin = roles.includes(2);
  const isEditor = roles.includes(3);
  const isViewer = roles.includes(4);

  const columns = [   
    {
      key: 'logo',
      header: 'Logo',
      render: (row) => (
        row.company_logo ? (
          <img
            src={row.company_logo}
            alt={row.company_name}
            className="company-logo-table"
          />
        ) : (
          <img 
          src="./images/icons8-logo.svg" 
          alt="default logo" 
          className="company-logo-table default-logo-icon"
        />
        )
      )
    },
    {
      key: 'company_name',
      header: 'Company Name',
      render: (row) => (
        <>{row.company_name}</>
      )
    },
    {
      key: 'location',
      header: 'Location',
      render: (row) => (
        <>
          {row.hq_country ?? ''}{row.hq_country && row.company_city ? ', ' : ''}{row.company_city ?? ''}
        </>
      )
    },
    {
      key: 'industry',
      header: 'Industry',
      render: (row) => (
        <>{row.industry}</>
      )
    },
    {
      key: 'actions',
      header: 'Actions',
      render: (row) => (
        <BsThreeDotsVertical
          className="actions-icon"
          onClick={(e) => handleActionClick(e, row)}
        />
      )
    }
  ];

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const user_info_id = localStorage.getItem('user_info_id');
      const role_id = localStorage.getItem('selected_roleId');

      try {
        await dispatch(fetchCompanies({ user_info_id, role_id }));
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 500);
      }
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (actionCardRef.current && !actionCardRef.current.contains(event.target)) {
        dispatch(setActionCard({ ...actionCard, show: false }));
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [actionCard, dispatch]);
  
  const handleFilterClick = () => {
    dispatch(setOffcanvasType('filter'));
    dispatch(setShowOffcanvas(true));
  };

  const addCompany = (newCompany) => {
    if (newCompany.company_id) {
      setCompanies(companies.map((company) => (company.company_id === newCompany.company_id ? newCompany : company)));
    } else {
      setCompanies([...companies, newCompany]);
    }
  };

  const handleAddCompanyClick = () => {
    dispatch(setSelectedCompany(null));
    dispatch(setIsEdit(false));
    dispatch(setOffcanvasType('addCompany'));
    dispatch(setShowOffcanvas(true));
  };

  const handleCloseOffcanvas = () => dispatch(setShowOffcanvas(false));

  const handleActionClick = (e, company) => {
    const rect = e.target.getBoundingClientRect();
    dispatch(setActionCard({ show: true, position: { top: rect.bottom, left: rect.left } }));
    dispatch(setSelectedCompany(company));
  };

  const handleCloseActionCard = () => {
    dispatch(setActionCard({ show: false, position: { top: 0, left: 0 } }));
    dispatch(setSelectedCompany(null));
  };

  const handleViewCompanyDetails = () => {
    navigate("/companyDetails",{ state: { company: selectedCompany } });
    dispatch(setActionCard({ show: false, position: { top: 0, left: 0 } }));
  };

  const handleEditCompany = () => {
    dispatch(setIsEdit(true));
    dispatch(setOffcanvasType('addCompany'));
    dispatch(setShowOffcanvas(true));
    dispatch(setActionCard({ show: false, position: { top: 0, left: 0 } }));
  };

  const handleRemoveCompany = async (e) => {
    setExpandedRow({ company_id: selectedCompany.company_id, option: 'remove', selectedRole: null });
    dispatch(setActionCard({ show: false, position: { top: 0, left: 0 } }));
  };

  const handleViewDashboard = async (e) =>{
    dispatch(setActionCard({ show: false, position: { top: 0, left: 0 } }));
  }

  const handleSearchChange = (e) => {
    dispatch(setSearchTerm(e.target.value));
  };

  useEffect(() => {
    if (searchTerm) {
      const tableFilter = companies.filter((company) => {
        const companyName = company.company_name || '';
        const hqCountry = company.hq_country || '';
        const companyCity = company.company_city || '';
        const industry = company.industry || '';
        return (
          companyName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          hqCountry.toLowerCase().includes(searchTerm.toLowerCase()) ||
          companyCity.toLowerCase().includes(searchTerm.toLowerCase()) ||
          industry.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
      setFilteredCompanies(tableFilter);
    } else {
      setFilteredCompanies(companies);
    }
  }, [searchTerm, companies]);

  const sortedCompanies = React.useMemo(() => {
    let sortableCompanies = [...companies];
    if (sortConfig.key !== '') {
      sortableCompanies.sort((a, b) => {
        if (a[sortConfig.key] && b[sortConfig.key]) {
          return sortConfig.direction === 'ascending'
            ? a[sortConfig.key].localeCompare(b[sortConfig.key])
            : b[sortConfig.key].localeCompare(a[sortConfig.key]);
        }
        return 0;
      });
    }
    return sortableCompanies;
  }, [companies, sortConfig]);

  const getUniqueValues = (array, key) => {
    return [...new Set(array.map((item) => item[key]))].map((value) => ({
      value,
      label: value,
    }));
  };

  const locations = getUniqueValues(companies, 'hq_country');
  const industries = getUniqueValues(companies, 'industry');
  const companyNames = getUniqueValues(companies, 'company_name');

  const applyFilters = ({ selectedLocations, selectedIndustries, selectedCompanyNames }) => {
    if (selectedLocations.length === 0 && selectedIndustries.length === 0 && selectedCompanyNames.length === 0) {
      setFilteredCompanies(sortedCompanies);
    } else {
      const filteredList = sortedCompanies.filter((company) => {
        const matchesLocation = selectedLocations.length === 0 || selectedLocations.includes(company.hq_country);
        const matchesIndustry = selectedIndustries.length === 0 || selectedIndustries.includes(company.industry);
        const matchesCompanyName = selectedCompanyNames.length === 0 || selectedCompanyNames.includes(company.company_name);
          // Apply all non-empty filters
        return matchesLocation && matchesIndustry && matchesCompanyName;
      });
      setFilteredCompanies(filteredList);
    }
    dispatch(setShowOffcanvas(false));
  };  

  const handleCancel = () => {
    setExpandedRow({ company_id: null, option: '', selectedRole: null });
  };

  const [rowName, setCompany] = useState("")
  const handleRemoveUser = (company_id) => {
    dispatch(removeCompany(company_id));
    toast.success(
      <div className="custom-toast">
        <span className="toast-message">Company has been removed successfully.</span>
      </div>,
      {
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        className: 'custom-toast-container'
      }
    );
    setExpandedRow({ company_id: null, option: '', selectedRole: null });
    setCompany("")
  }

  const renderExpandedContent = (row) => {
    return (
      <div className='user-operations-container'>
        <p className='role-text'>Remove Company</p>
        <div className='role-update-container d-flex justify-content-between align-items-center'>
          <p className='confirmation-text'>Are you sure you want to remove this {row.company_name}?</p>
          <div className='update-role-btn-container mx-5'>
            <button className='cancel-update-role-btn' onClick={handleCancel}>Cancel</button>
            <button
              className='update-role-btn'
              onClick={() => { setCompany(row.company_name); handleRemoveUser(row.company_id) }}
            >
              Remove company
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {loading ? (
        <Loader /> // Show loader
      ) : (
        (isESGConsultantOwner || isESGConsultantAdmin || isViewer || isEditor) && (
          <div className="companies-container">
            <div className="spacer"></div>
            <div className="addcompany-table">
              <div className="row table-header-list mt-4">
                <div className="col-7">
                  <h6 className="list_company_heading">List of Companies</h6>
                </div>
                <div className={`${isEditor || isViewer ? 'col-1' : 'col-2'}`}>
                  <div className="search-container">
                    <img src="./images/icon_search.svg" alt="search" className="search-icon" />
                    <input
                      type="text"
                      className="search-field"
                      placeholder="Search"
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                  </div>
                </div>
                <div className="col-1">
                  <button
                    type="button"
                    className="filter-button filter_icon_company"
                    onClick={handleFilterClick}
                  >
                    <img src="./images/bi_filter.svg" alt="filter" className="filter-icon" />
                    Filters
                  </button>
                </div>
                {(isESGConsultantOwner || isESGConsultantAdmin) && (
                  <div className="col-1">
                    <button
                      type="button"
                      className="btn-success add-company-button add-company-text"
                      onClick={handleAddCompanyClick}
                    >
                      +Add Company
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className='table-content'>
                <div className='col-12'>
                  {filteredCompanies.length > 0 ? (
                    <Table className="company_list_table"
                      columns={columns}
                      data={filteredCompanies}
                      rowKey="company_id"
                      expandedRow={expandedRow}
                      renderExpandedContent={renderExpandedContent}
                    />
                  ) : (
                    <div className="no-records-container no-company d-flex flex-column">
                      <img src="./images/all_companies_no_data.svg" alt="no_data_image"></img>
                      <p className="no-records-company">No Companies Available</p>

                    </div>
                  )} 
                </div>
              </div>
            </div>
            {offcanvasType === "addCompany" && (
              <AddCompany
                show={showOffcanvas}
                handleClose={handleCloseOffcanvas}
                onSave={addCompany}
                isEdit={isEdit}
                companyData={selectedCompany}
                fetchCompanies={fetchCompanies}
              />
            )}
            {offcanvasType === "filter" && (
              <FilterOffcanvas
                show={showOffcanvas && offcanvasType === 'filter'}
                handleClose={handleCloseOffcanvas}
                locations={locations}
                industries={industries}
                companyNames={companyNames}
                applyFilters={applyFilters}
              />
            )}
            <ActionCard
              ref={actionCardRef}
              show={actionCard.show}
              position={actionCard.position}
              handleClose={handleCloseActionCard}
              viewCompanyDetails={
                (isESGConsultantOwner || isESGConsultantAdmin || isViewer || isEdit || isEditor)
                  ? handleViewCompanyDetails
                  : undefined
              }
              onViewDashboard={
                (isESGConsultantOwner || isESGConsultantAdmin || isViewer || isEdit || isEditor)
                  ? handleViewDashboard
                  : undefined
              }
              onEditCompany={
                (isESGConsultantOwner || isESGConsultantAdmin || isEditor) && !isViewer
                  ? handleEditCompany
                  : undefined
              }
              onRemoveCompany={
                (isESGConsultantOwner || isESGConsultantAdmin)
                  ? handleRemoveCompany
                  : undefined
              }
              companyData={selectedCompany}
            />
          </div>
        )
      )}
    </>
  );
}

export default CompaniesList;










