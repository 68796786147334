import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { sendPasswordResetEmail } from '../../../Redux/Actions/Actions.js'
import { setEmail, setEmailError } from '../../../Redux/Slices/UserLoginFlowSlices/ForgetpasswordSlice';
import NavbarContext from '../../../Reusable/ReusableComponents/NavbarContext';
import '../../UserLoginFlow/UserLoginFlowStyles/Signup.css';
import { ToastContainer, toast } from "react-toastify";
import { FaArrowLeft } from "react-icons/fa6";
import "react-toastify/dist/ReactToastify.css";

export default function ForgotPassword() {
  const { setIsLoginPage } = useContext(NavbarContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const { email, emailError, buttonDisabled, emailSent } = useSelector(state => state.forgotPassword);

  const loginClick = () => {
    navigate('/');
    setIsLoginPage(true);
  }

  const handleEmailChange = (e) => {
    dispatch(setEmail(e.target.value));
    dispatch(setEmailError("")); 
  };

  const validateEmail = () => {
    const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (email.trim() === "") {
      dispatch(setEmailError("Enter Email Address"));
      return false;
    } else if (!emailPattern.test(email)) {
      dispatch(setEmailError("Enter Valid Email Address"));
      return false;
    } else {
      dispatch(setEmailError(null)); // Clear the error message when email is valid
      return true;
    }
  };

  const handleSignup = (e) => {
    e.preventDefault();

    if (validateEmail()) {
      dispatch(sendPasswordResetEmail(email))
        .unwrap()
        .catch(error => {
          toast.error(error.message || "Something went wrong! Please try again.");
        });
    }
  };

  return (
    <div className='container-fluid'>
      <div className='bg_container row'>
        <div className='left_container col-12 col-md-6'>
          <div>
            <p className='login_heading_fpw'>Reduce <span className='login_heading_highlight_fpw'>90% </span> of your <br/>time and effort</p>
            <p className='login_paragraph_fpw'>By using ESGPilot, businesses and organizations can reduce the effort and resources required for ESG reporting, enabling them to focus more on their sustainability initiatives and less on the intricacies of report generation.
              <br></br>
              <br></br>
              With Smart ESG AI, you can rapidly extract the relevant ESG mandatory fields, identify gaps in your ESG reporting, and generate stunning reports.
            </p>
          </div>
        </div>
        <div className='right_container col-12 col-md-6'>
          {emailSent ? (
            <div className='form_container_email_sent d-flex justify-content-center align-items-center'>
              <div>
                <p className='Forget_password email_sent'>Email Sent</p>
                <p className='forget_password_content email_sent'>We have sent the password reset link to your registered email.</p>
                <button className="back_to_login" onClick={loginClick}><FaArrowLeft className='back_arrow'/>Back To Login</button>
              </div>
            </div>
          ) : (
            <div className='form_container_fpw d-flex justify-content-center align-items-center'>
              <form className='signup-form' onSubmit={handleSignup}>
                <div>
                  <p className='Forget_password'>{emailSent ? "Email Sent" : "Forgot Password"}</p>
                  <p className='forget_password_content'>Enter your email associated with your account</p>
                </div>
                <div>
                  <input
                    type='email'
                    placeholder='Email Address'
                    id='email'
                    name='email'
                    value={email}
                    onChange={handleEmailChange}
                    className='input_box'
                  />
                </div>
                {emailError && (<p style={{ color: 'red' }}>{emailError}</p>)}
                {!emailSent && (
                  <>
                    <button type='submit' className='signup_button' disabled={buttonDisabled}>Submit</button>
                    <p className='signup_text'><span onClick={loginClick} className='login_link_sgu'><img src="./images/backArrow.svg" alt='arrow' />Back To Login</span></p>
                  </>
                )}
              </form>
            </div>
          )}
        </div>
      </div>
      <ToastContainer 
      position='top-center'
      hideProgressBar={true}
      autoClose={2000}
      />
    </div>
  );
}




