import {useState} from 'react'
import WelcomeCard from './WelcomeCard';
import LetsGetStarted from './LetsGetStarted';
import LetsGetStartedCard from './LetsGetStartedCard';

export default function PopupModal() {
  const [welcomeCard, setWelcomeCard] = useState(true);
  const [letsGetStarted, setLetsGetStarted] = useState(false);
  const [letsGetStartedCard, setLetsGetStartedCard] = useState(false);
  const [companyId,setCompanyId]=useState('');

  const nextWelcomeCard = () => {
    setWelcomeCard(false);
    setLetsGetStarted(true);
  };

  const nextLetsGetStarted = () => {
    setLetsGetStarted(false);
    setLetsGetStartedCard(true)
  };

  const backToLetsGetStarted = () => {
    setLetsGetStartedCard(false);
    setLetsGetStarted(true);
  };

  const closeModal = () =>{
    setLetsGetStartedCard(false);
  }


  return (
    <div>
      <WelcomeCard
        showModal={welcomeCard}
        onAction={nextWelcomeCard}
      />
      <LetsGetStarted
        showModal={letsGetStarted}
        onAction={nextLetsGetStarted}
        setCompanyId={setCompanyId}
      />
      <LetsGetStartedCard
      showModal={letsGetStartedCard}
      onClose={closeModal}
      onBack={backToLetsGetStarted}
      />
    </div>
  )
}

