import { createSlice } from '@reduxjs/toolkit';
import { fetchCompanyUsers,resendInvite ,removeUser} from '../../Actions/Actions';

const initialState = {
  loading: false,
  error: null,
  users: [],
  companies: [],
  Our_Organisation: [],
};

const companyUsersSlice = createSlice({
  name: 'companyUsers',
  initialState,
  reducers: {
    // You can define reducers here if needed in the future
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanyUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCompanyUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.Our_Organisation = action.payload.Our_Organisation;
        state.companies = action.payload.companies; 
      })
      .addCase(fetchCompanyUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(resendInvite.pending, (state) => {
        state.loading = true;
      })
      .addCase(resendInvite.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(resendInvite.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(removeUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeUser.fulfilled, (state, action) => {
        state.loading = false;
        state.users = state.users.filter(user => user.userId !== action.payload.userId);
      })
      .addCase(removeUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })


  },
});

export const selectCompanyUsers = (state) => state.companyUsers.companies;
export const selectOrganisationUsers = (state)=> state.companyUsers.Our_Organisation;
export default companyUsersSlice.reducer;
