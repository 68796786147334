import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IoFilter } from "react-icons/io5";
import 'react-toastify/dist/ReactToastify.css';
import Pagination from '../ReusableComponents/Pagination';

const Table = ({ columns, data, rowKey, expandedRow = {},renderExpandedContent, withBorder = true}) => {

    const tableClass = withBorder ? 'table-default' : 'table-no-border';

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(7);
    const [sortBy, setSortBy] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');

    const totalPages = Math.ceil(data.length / itemsPerPage);

    const handlePageChange = (direction) => {
        setCurrentPage((prevPage) => {
            if (direction === 'prev' && prevPage > 1) {
                return prevPage - 1;
            }
            if (direction === 'next' && prevPage < totalPages) {
                return prevPage + 1;
            }
            return prevPage;
        });
    };

    const handleItemsPerPageChange = (value) => {
        setItemsPerPage(value);
        setCurrentPage(1);
    };

    const handlePageSelectChange = (value) => {
        setCurrentPage(value);
    };

    const startIndex = (currentPage - 1) * itemsPerPage;

    // Sorting logic
    let sortedData = [...data];
    if (sortBy) {
        sortedData.sort((a, b) => {
            const aValue = a[sortBy];
            const bValue = b[sortBy];
            if (aValue < bValue) {
                return sortOrder === 'asc' ? -1 : 1;
            }
            if (aValue > bValue) {
                return sortOrder === 'asc' ? 1 : -1;
            }
            return 0;
        });
    }

    const selectedData = sortedData.slice(startIndex, startIndex + itemsPerPage);

    const toggleSortOrder = (key) => {
        if (key === sortBy) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(key);
            setSortOrder('asc');
        }
    };


    return (
        <div className="table-container ">
            <table className={`table ${tableClass}`}>
                <thead className=''>
                    <tr className='tr_headings'>
                        {columns.map((column,index) => (
                            <th key={index}>
                                {column.header}
                                {(column.header !== "Actions" && column.header !== "Logo") && (
                                    <IoFilter
                                        className="sort-icon"
                                        onClick={() => toggleSortOrder(column.key)}
                                    />
                                )}
                                                              
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {selectedData.map((row,index) => (
                        <React.Fragment key={index}>
                            <tr>
                                {columns.map((column,index) => (
                                    <td key={index}>
                                        {column.render ? column.render(row) : row[column.key]}
                                    </td>
                                ))}
                            </tr>
                            {(expandedRow && rowKey=="users_list"?expandedRow.userId == row.user_info_id: expandedRow.userId == row[rowKey])  ? (
                                <tr className="expanded-row">
                                    <td colSpan={columns.length}>
                                        {renderExpandedContent(row)}
                                    </td>
                                </tr>
                            ) : (expandedRow && rowKey=="users_list"?expandedRow.userId == row.user_id: expandedRow.company_id === row[rowKey]) && (
                                <tr className="expanded-row">
                                    <td colSpan={columns.length}>
                                        {renderExpandedContent(row)}
                                    </td>
                                </tr>
                            )}
                        </React.Fragment>

                    ))}
                </tbody>
            </table>
            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                itemsPerPage={itemsPerPage}
                totalItems={data.length}
                onPageChange={handlePageChange}
                onItemsPerPageChange={handleItemsPerPageChange}
                onPageSelectChange={handlePageSelectChange}
            />
        </div>
    );
};

Table.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            header: PropTypes.string.isRequired,
            render: PropTypes.func,
        })
    ).isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    rowKey: PropTypes.string.isRequired,
    expandedRow: PropTypes.shape({
        userId: PropTypes.any,
        option: PropTypes.string,
    }),
    renderExpandedContent:PropTypes.func.isRequired,
};

export default Table;
