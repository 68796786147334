import { createSlice } from '@reduxjs/toolkit';
import { fetchDocuments, deleteDocument, renameDocument, downloadDocument, replaceDocument } from '../../../Redux/Actions/Actions'

const documentsSlice = createSlice({
  name: 'documents',
  initialState: {
    documents: [],
    loading: false,
    error: null,
    doc_id: null,
  },
  reducers: {
    setDocId: (state, action) => {
      state.doc_id = action.payload;
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDocuments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDocuments.fulfilled, (state, action) => {
        state.loading = false;
        state.documents = action.payload;
      })
      .addCase(fetchDocuments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deleteDocument.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteDocument.fulfilled, (state, action) => {
        state.loading = false;
        state.documents = state.documents.filter(doc => doc.doc_id !== action.meta.arg);
      })
      .addCase(deleteDocument.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(renameDocument.pending, (state) => {
        state.loading = true;
      })
      .addCase(renameDocument.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.documents.findIndex(doc => doc.doc_id === action.meta.arg.doc_id);
        if (index !== -1) {
          state.documents[index].document_name = action.meta.arg.new_name;
        }
      })
      .addCase(renameDocument.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(downloadDocument.pending, (state) => {
        state.loading = true;
      })
      .addCase(downloadDocument.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(downloadDocument.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(replaceDocument.pending, (state) => {
        state.loading = true;
      })
      .addCase(replaceDocument.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.documents.findIndex(doc => doc.doc_id === action.meta.arg.doc_id);
        if (index !== -1) {
          state.documents[index] = action.payload; // Assuming payload contains the updated document
        }
      })
      .addCase(replaceDocument.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { setDocId } = documentsSlice.actions;

export default documentsSlice.reducer;

