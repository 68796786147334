import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { fetchDocuments } from '../../Redux/Actions/Actions'


const CompanyInformation = () => {

    const { state } = useLocation();
    const company = state?.company || {};
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchDocuments(company.company_id));
    }, [dispatch, company]);

    return (
        <div className="card_comapny_details">
            <div className="first_row company_details_line">
                <h6 className="company_details_text">Company Details</h6>
                <div className="row">
                    <p className="col-4 company_details_item company_detail_logo">Company Logo</p>
                    <p className="col-4 ">
                        <img className='company_detail_logo' src={company.company_logo} alt={company.company_name} />
                    </p>
                </div>
                <div className="row">
                    <p className="col-4 company_details_item">Company Name</p>
                    <p className="col-4 company_details_item">{company.company_name}</p>
                </div>
                <div className="row">
                    <p className="col-4 company_details_item">Industry</p>
                    <p className="col-4 company_details_item">{company.industry}</p>
                </div>
                <div className="row">
                    <p className="col-4 company_details_item">Total Employees</p>
                    <p className="col-4 company_details_item">{company.total_employees}</p>
                </div>
                <div className="row">
                    <p className="col-4 company_details_item">Total Departments</p>
                    <p className="col-4 company_details_item">{company.total_departments}</p>
                </div>
            </div>
            <div className="first_row">
                <h6 className="company_details_text">Contact Details</h6>
                <div className="row">
                    <p className="col-4 company_details_item">Email</p>
                    <p className="col-4 company_details_item">{company.company_email}</p>
                </div>
                <div className="row">
                    <p className="col-4 company_details_item">Phone Number</p>
                    <p className="col-4 company_details_item">{company.company_phone_number}</p>
                </div>
                <div className="row">
                    <p className="col-4 company_details_item">Address</p>
                    <p className="col-4 company_details_item">{company.company_address}</p>
                </div>
                <div className="row">
                    <p className="col-4 company_details_item">City</p>
                    <p className="col-4 company_details_item">{company.company_city}</p>
                </div>
                <div className="row">
                    <p className="col-4 company_details_item">Country</p>
                    <p className="col-4 company_details_item">{company.hq_country}</p>
                </div>
                <div className="row">
                    <p className="col-4 company_details_item">Postal Code</p>
                    <p className="col-4 company_details_item">{company.company_postal_code}</p>
                </div>
            </div>
        </div>
    );
};

export default CompanyInformation;
