import { ROLES,is_company_primary_admin,is_editor,is_esg_consultant,is_esg_consultant_owner,is_viewer,is_workspace_admin,our_organization  } from "./constants";

export const shouldHideUserActions = (role) => {
    if (role == is_viewer ) {
      return false; 
    } else if (role == is_editor) {
      return false; 
    } else if (role == is_workspace_admin) {
      return false; 
    } else if(role == is_company_primary_admin){
      return false; 
    }
    else{
      return true; 
    }
  };