// forgotPasswordSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { sendPasswordResetEmail } from '../../../Redux/Actions/Actions.js';

const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState: {
    email: '',
    emailError: '',
    buttonDisabled: false,
    emailSent: false,
  },
  reducers: {
    setEmail(state, action) {
      state.email = action.payload;
    },
    setEmailError(state, action) {
      state.emailError = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendPasswordResetEmail.pending, (state) => {
        state.buttonDisabled = true;
      })
      .addCase(sendPasswordResetEmail.fulfilled, (state) => {
        state.buttonDisabled = false;
        state.emailSent = true;
      })
      .addCase(sendPasswordResetEmail.rejected, (state, action) => {
        state.buttonDisabled = false;
        state.emailError = action.error.message;
        state.emailSent = false;
      });
  },
});

export const { setEmail, setEmailError,} = forgotPasswordSlice.actions;

export default forgotPasswordSlice.reducer;

