// companiesSlice.js
import { createSlice,} from '@reduxjs/toolkit';
import { removeCompany, fetchCompanies} from '../../Actions/Actions';


const initialState = {
  companies: [],
  searchTerm: '',
  showOffcanvas: false,
  offcanvasType: '',
  actionCard: { show: false, position: { top: 0, left: 0 } },
  selectedCompany: null,
  sortConfig: { key: '', direction: '' },
  setCompanies:[],
  isEdit: false,
  status: 'idle',
  error: null,
  roles: []
};

const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    setSearchTerm(state, action) {
      state.searchTerm = action.payload;
    },
    setShowOffcanvas(state, action) {
      state.showOffcanvas = action.payload;
    },
    setOffcanvasType(state, action) {
      state.offcanvasType = action.payload;
    },
    setActionCard(state, action) {
      state.actionCard = action.payload;
    },
    setSelectedCompany(state, action) {
      state.selectedCompany = action.payload;
    },
    setSortConfig(state, action) {
      state.sortConfig = action.payload;
    },
    setIsEdit(state, action) {
      state.isEdit = action.payload;
    },
    setCompanies(state, action) {
      state.companies = action.payload;
      },
      setRoles(state, action) {
        state.roles = action.payload;
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanies.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCompanies.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.companies = action.payload;
      })
      .addCase(fetchCompanies.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
    .addCase(removeCompany.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(removeCompany.fulfilled, (state, action) => {
        state.isLoading = false;
        state.companies = state.companies.filter(
          (company) => company.company_id !== action.meta.arg
        );
      })
      .addCase(removeCompany.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const {
  setSearchTerm,
  setShowOffcanvas,
  setOffcanvasType,
  setActionCard,
  setSelectedCompany,
  setSortConfig,
  setIsEdit,
  setCompanies,
  setRoles
} = companiesSlice.actions;

export default companiesSlice.reducer;



