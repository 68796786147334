import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { FaChevronDown } from 'react-icons/fa';
import { IoNotificationsOutline } from 'react-icons/io5';
import { HiOutlineUserCircle } from 'react-icons/hi2';
import HeaderComponent from './UserCompanyName';
import Form from 'react-bootstrap/Form';
import { fetchCompanies, fetchCompanyActivities } from '../../Redux/Actions/Actions.js';
import { useSelector, useDispatch } from 'react-redux';
import Select from "react-select";
import NavbarContext from './NavbarContext.jsx';
const constants = require('../../config/constants.jsx');

export const customStyles = {
    control: (provided) => ({
        ...provided,
        width: "100%",
        padding: '2px 15px',
        borderStyle: 'none',
        borderColor:"transparent",
        cursor:'pointer',
      
    }),
    option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? 'transparent' : provided.backgroundColor,
        // color: state.isSelected ? '#000' : provided.color,
        ':active': {
          ...provided[':active'],
          backgroundColor: state.isSelected ? 'transparent' : provided[':active'].backgroundColor,
        },
        ':hover': {
      ...provided[':hover'],
      backgroundColor: '#00A656', // Set your desired hover color here
    },
         color:'#000',
         cursor: 'pointer',
      }),
    singleValue: (provided) => ({
        ...provided,
        //   color: '#777E90',
        color:'#000',
        cursor: 'pointer',
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: '#00A656',
    }),
};
const TopNavbar = () => {
    const dispatch = useDispatch();
    const [userProfile, setUserProfile] = useState(null);
    const [showUserProfile, setShowUserProfile] = useState(false);
    const [userCompanies, setUserCompanies] = useState([])
    let [selectedCompany, setSelectedCompany] = useState()
    const { setCompany } = useContext(NavbarContext)
    const navigate = useNavigate()
    let companies = []
    let path = window.location.pathname
    let user_companies = useSelector((state) => state?.login?.user_info)
    
    useEffect(() => {
        let comapnies_list =user_companies?.filter(company =>{
            if(company.company_id != null)
            return company
        })
        let all = { company_id: comapnies_list[0]?.company_id, company_name: "All Companies" }
        comapnies_list.unshift(all)
        let company_list = comapnies_list?.map(item => {
            return { label: item.company_name, value: item.company_id }
        })
        setCompany(company_list[0])
        setSelectedCompany(company_list[0])
        setUserCompanies(company_list)

        const fetchUserProfile = async () => {

            try {
                
                const userInfoId = localStorage.getItem('user_info_id');
                const response = await axios.post(`${constants.BACKEND_SERVER_BASEURL}/users/user_profile`, {

                    user_info_id: userInfoId,
                    is_pending_invite:0
                });
                setUserProfile(response.data.user_profile);
            } catch (error) {
                console.error('Failed to fetch user profile:', error);
            }
        };

        fetchUserProfile();
    }, []);


    const userName = userProfile?.first_name && userProfile?.last_name
        ? `${userProfile.first_name} ${userProfile.last_name}`
        : 'User';
    const profileImage = userProfile?.profile_image
        ? <img src={userProfile.profile_image} alt="Profile" className='profile-icon' />
        : <HiOutlineUserCircle className='profile-icon' />;

    const handleProfiile = () => {
        if (!showUserProfile) {
            navigate('/UserProfile')
            setShowUserProfile(true)
        } else {
            window.history.back()
            setShowUserProfile(false)
        }
    }
    const getCompany = (company) => {
        setSelectedCompany(company)
        setCompany(company)
    }
    return (
        <nav className="top-navbar">
            {/* <div className="logo">
                <HeaderComponent />
            </div> */}
          {path == '/dashboard' && userCompanies?.length >1 ? <>
            <div className='w-25' >
                <Select
                    value={selectedCompany}
                    options={userCompanies}
                    className='select_input_box b-0'
                    id="company"
                    styles={customStyles}
                    // classNamePrefix="react-select"
                    placeholder="Select company"
                    onChange={getCompany}
                />
                {/* <select className='form-select'onChange={getCompany}>
                    {userCompanies.map(company => <option value={company.company_name} key={company.company_id}>{company.company_name}</option>)}
                </select> */}
            </div> <div >
                <input type= "text"  className="search_anything_searchbar" placeholder='Search for anything...'></input>
            </div>
          </>
            :
            <div className="logo">
                <HeaderComponent />
            </div>}
            <div className="top-navbar-right">

                <span className='notification-icon-container'><IoNotificationsOutline className="notification-icon" size={24} /></span>
                <div className="user-profile">
                    <div onClick={handleProfiile}>
                        <span className="username"><span className='hello-text'>Hello,</span> {userName}</span>
                        {profileImage}
                        <FaChevronDown className='down-arrow-icon' />
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default TopNavbar;
