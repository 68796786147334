import React, { useState, useEffect } from 'react';
import HeaderComponent from '../../../Reusable/ReusableComponents/UserCompanyName.jsx';
import { useParams, useNavigate } from 'react-router-dom';
import axios from '../../../config/axiosConfig.jsx';
import PdfGenerator from '../../Reports/ReportsComponents/PdfGenerator.jsx';
import CircularProgressBar from '../../../Reusable/ReusableComponents/CircularProgressbar.jsx';
// import { saveAs } from 'file-saver';
// import * as XLSX from 'xlsx';
import Form from 'react-bootstrap/Form';
import Loader from '../../../config/Loader.jsx';
import { PiPencilSimpleLight } from "react-icons/pi";
import CryptoJS from 'crypto-js';
import '../../pages/PagesStyles/home.css';

const constants = require('../../../config/constants.jsx');
function findPercentage(completedDataPoints, incompleteDataPoints)
{
    var percent = (completedDataPoints/(completedDataPoints+incompleteDataPoints))*100;
    return Math.round(percent);
}

const SummaryReport = () => {

  
    const [totalDataPoints, setTotalDataPoints]           = useState(0);
    const [eDataPointsSummary, setEDataPointsSummary]     = useState(0);
    const [sDataPointsSummary, setSDataPointsSummary]     = useState(0);
    const [gDataPointsSummary, setGDataPointsSummary]     = useState(0);
    const { iterationId, companyId, encrypted_email}      = useParams();
    const navigate                                        = useNavigate();
    const [dropDownOptions, setDropDownOptions]           = useState([]);
    const [selectedValue, setSelectedValue]               = useState('');
    const [juridictionName, setJuridictionName]           = useState('');
    const [loading, setLoading]                           = useState(false);
    const [isMobile, setIsMobile]                         = useState(window.innerWidth <= 767);


    useEffect(() => {
      const handleResize = () => setIsMobile(window.innerWidth <= 767);
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    
    useEffect(() => {
      if (localStorage.getItem('access_token')) {
        axios.get(`${constants.BACKEND_SERVER_BASEURL}/dataPoints/getJurisdictionsByCompanyIdAndIterationId/${iterationId}/${companyId}`)
          .then((response) => {
            const options = response.data;
            setDropDownOptions(options);
            if (options.length > 0) {
              getDataCollection(options[0].juri_id);
            }
          })
          .catch((error) => {
            console.error('error:', error);
          });

        axios.get(`${constants.BACKEND_SERVER_BASEURL}/iterations/getIterationDetails/${iterationId}`)
          .then((response) => {
            // setiterationName(response.data[0].iteration_name)
          })
          .catch((error) => {
            console.error('error:', error);
          });

      }
    }, [iterationId, companyId, encrypted_email]);

    const getDataCollection = async (juriId) => {
      try {
        setLoading(true); // Show loader
        setSelectedValue(juriId);
    
        // Fetch summary report data
        const summaryReportResponse = await axios.get(`${constants.BACKEND_SERVER_BASEURL}/dataPoints/getSummaryReport/${iterationId}/${companyId}/${juriId}`);
        setTotalDataPoints(findPercentage(summaryReportResponse.data.totalDataPointsSummary[0].non_empty_count, summaryReportResponse.data.totalDataPointsSummary[0].empty_count));
        setEDataPointsSummary(findPercentage(summaryReportResponse.data.eDataPointsSummary[0].non_empty_count, summaryReportResponse.data.eDataPointsSummary[0].empty_count));
        setSDataPointsSummary(findPercentage(summaryReportResponse.data.sDataPointsSummary[0].non_empty_count, summaryReportResponse.data.sDataPointsSummary[0].empty_count));
        setGDataPointsSummary(findPercentage(summaryReportResponse.data.gDataPointsSummary[0].non_empty_count, summaryReportResponse.data.gDataPointsSummary[0].empty_count));
    
        // Fetch jurisdictions
        const jurisdictionsResponse = await axios.get(`${constants.BACKEND_SERVER_BASEURL}/dataPoints/getJurisdictionsByCompanyIdAndIterationId/${iterationId}/${companyId}`);
        setJuridictionName(jurisdictionsResponse.data[juriId - 1].juri_name);
    
        // Hide loader after 1 second
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } catch (error) {
        console.error('Error:', error);
        setLoading(false); // Hide loader in case of error
      }
    };

    const handleEditDataPointsButton = async () => {
      setLoading(true); 
      // Simulate a delay of 1 second before making the axios request
      setTimeout(async () => {
        try {
          await axios.get(`${constants.BACKEND_SERVER_BASEURL}/dataPoints/getAllDataPointsByIterationIdAndCompanyId/${iterationId}/${companyId}`);
          // After the request is complete, hide the loader and navigate
          setLoading(false); 
          navigate(`/report/${iterationId}/${companyId}`);
        } catch (error) {
          console.error('Error:', error);
          setLoading(false);
        }
      }, 1000); 
    };
    
    
    // const handleExportButton = async () => {
    //   if (selectedValue !== '') {
    //     try {
    //       const juriLevelDataPoints = await axios.get(
    //         `${constants.BACKEND_SERVER_BASEURL}/dataPoints/getAllDataPointsForExportByIterationIdAndCompanyIdAndJuriId/${iterationId}/${companyId}/${selectedValue}`
    //       );
    //       const data = juriLevelDataPoints.data;
    
    //       const worksheet = XLSX.utils.json_to_sheet(data);
    
    //       const workbook = XLSX.utils.book_new();
    //       XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet 1');
    
    //       const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //       const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    
    //       saveAs(blob, 'Data Points.xlsx');
    //     } catch (error) {
    //       console.error('Error exporting to Excel:', error);
    //     }
    //   }
    // };
    
  return (
    <div>
    {localStorage.access_token && <div className='home-container'>
    {loading && <Loader />}
        <HeaderComponent />
        <div className="container-fluid">
          <div className="row px-2 mb-4 d-flex flex-row justify-content-between align-items-center mobile-view-container">
            {/* <div className="col-12">
              <h3 className="text-center">{iterationName}</h3>
            </div> */}

            <div className='col-6 col-md-6 col-lg-3'>
              <Form.Select onChange={(event) => getDataCollection(event.target.value)} className={`btn btn-light light-btn   reports_dropdown`}disabled={loading}>
                {dropDownOptions.map(option => (
                  <option key={option.juri_id} value={option.juri_id} className={`selectOptions`}>{option.juri_name}</option>
                ))}
              </Form.Select>
            </div>
            <div className='col-6 col-md-6 col-lg-9 d-flex flex-row justify-content-end align-items-center '>
             
              {!isMobile ? (<button className={`btn btn-default  pdf_edit_button`} onClick={handleEditDataPointsButton}disabled={loading}>Edit</button> ) : (< PiPencilSimpleLight className='edit_icon' onClick={handleEditDataPointsButton}disabled={loading}/>)}
              {<PdfGenerator iterationId={iterationId} companyId={companyId} juridictionName={juridictionName} juriId={selectedValue} />}
            </div>
          </div>
          <div className="row px-2">
            <div className="col-6 col-md-6 col-lg-3 mb-3">
              <div className='d-flex flex-column justify-content-center align-items-center overall_bg'>
                <div className='d-flex flex-column justify-content-center align-items-center'>
                  <div className='reports_img_container d-flex justify-content-center'>
                    <img src="./images/ThunderIcon.svg" alt='Lightining' className='Lightining_img align-self-center' />
                  </div>
                  <p className='report-heading1'>Overall</p>
                </div>
                <CircularProgressBar percentage={totalDataPoints} colour="#23719F" backgroundColor="#F4F5F9" textColor="#23719F"/>
                {/* <p className='report-heading5'>You are {totalDataPoints}% complaint with MiniESG</p> */}
              </div>
            </div>
            <div className="col-6 col-md-6 col-lg-3 mb-3">
              <div className='report-bg d-flex flex-column justify-content-center align-items-center environment_bg'>
                <div className='d-flex flex-column justify-content-center align-items-center'>
                  <div className='reports_img_container environment_icon_color d-flex justify-content-center'>
                    <img src="./images/plant_icon.svg" alt='plant' className='plant_image align-self-center' />
                  </div>
                  <p className='report-heading'>Environment</p>
                </div>
                <CircularProgressBar percentage={eDataPointsSummary} colour="#00A656" textColor="#242731" />
              </div>
            </div>
            <div className="col-6 col-md-6 col-lg-3 mb-3">
              <div className='report-bg d-flex flex-column justify-content-center align-items-center environment_bg'>
                <div className='d-flex flex-column justify-content-center align-items-center'>
                  <div className='reports_img_container social_icon_color d-flex justify-content-center'>
                    <img src="./images/peopleIcon.svg" alt='WaterDrop' className='plant_image align-self-center' />
                  </div>
                  <p className='report-heading'>Social</p>
                </div>
                <CircularProgressBar percentage={sDataPointsSummary} colour="#EA580C" textColor="#242731" />
              </div>
            </div>

            <div className="col-6 col-md-6 col-lg-3 mb-3">
              <div className='report-bg d-flex flex-column justify-content-center align-items-center environment_bg'>
                <div className='d-flex flex-column justify-content-center align-items-center'>
                  <div className='reports_img_container governence_icon_color d-flex justify-content-center'>
                    <img src="./images/buildingIcon.svg" alt='Tier' className='plant_image align-self-center' />
                  </div>
                  <p className='report-heading'>Governance</p>
                </div>
                <CircularProgressBar percentage={gDataPointsSummary} colour="#7E22CE" textColor="#242731" />
              </div>
            </div>
          </div>

        </div>
    </div>}
    </div>
  );
};

export default SummaryReport;
