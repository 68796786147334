import { configureStore, combineReducers, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session'
// import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import loginReducer from '../Redux/Slices/UserLoginFlowSlices/LoginSlice';
import signupReducer from '../Redux/Slices/UserLoginFlowSlices/SignupSlice';
import letsGetStartedReducer from '../Redux/Slices/popupCardSlices/letsGetStartedSlice';
import forgotPasswordReducer from '../Redux/Slices/UserLoginFlowSlices/ForgetpasswordSlice';
import resetPasswordReducer from '../Redux/Slices/UserLoginFlowSlices/ResetpasswordSlice';
import companiesReducer from '../Redux/Slices/EsgConsultantFlow/CompaniesSlice';
import addcompaniesReducer from '../Redux/Slices/EsgConsultantFlow/AddUpdateCompanySlice';
import userProfileReducer from './Slices/UserLoginFlowSlices/userProfileSlice';
import documentsReducer from '../Redux/Slices/EsgConsultantFlow/DocumentsSlice';
import companyActivityReducer from './Slices/EsgConsultantFlow/CompanyActivitiesSlice';
import companyUsersReducer from './Slices/EsgConsultantFlow/companyUsersSlice';
import inviteUserReducer from './Slices/EsgConsultantFlow/inviteUserSlice';
import dashboardDataReducer from './Slices/DashboardSlice';
import usersReducer from './Slices/EsgConsultantFlow/UsersSlice'

const persistConfig = {
  key: 'root',
  storage : storageSession,
};

const rootReducer = combineReducers({
  login: loginReducer,
  signup: signupReducer,
  letsGetStarted: letsGetStartedReducer,
  forgotPassword: forgotPasswordReducer,
  resetPassword: resetPasswordReducer,
  companies: companiesReducer,
  addcompanies: addcompaniesReducer,
  companyActivities: companyActivityReducer,
  userProfile: userProfileReducer,
  documents: documentsReducer,
  companyUsers: companyUsersReducer,
  inviteUser: inviteUserReducer,
  dashboardData: dashboardDataReducer,
  users:usersReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

const persistor = persistStore(store);

export { store, persistor };
