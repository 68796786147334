
// import React, { forwardRef } from 'react';
// import { useNavigate } from 'react-router-dom';
// // import "../css/ActionCard.css";

//   const ActionCard = (forwardRef({ show, position, company, handleClose,onViewDashboard, onEditCompany, onRemoveCompany,viewCompanyDetails ,onReplaceDoc,onDeleteDoc,onDownloadDoc,onRenameDoc},ref) => {
//   const navigate = useNavigate();

//   if (!show) return null;

//   const style = {
//     position: 'absolute',
//     top: position.top,
//     left: position.left-45,
//     zIndex: 1000,
//   };



//   return (
//     <div className="action-card" style={style}>
//       <ul>
//       {onViewDashboard && (
//           <li onClick={onViewDashboard}>View Dashboard</li>
//         )}
//         {viewCompanyDetails && (
//           <li onClick={viewCompanyDetails}>View Company Details</li>
//         )}
//         {onEditCompany && (
//           <li onClick={onEditCompany}>Edit Company</li>
//         )}
//         {onRemoveCompany && (
//           <li onClick={onRemoveCompany}>Remove Company</li>
//         )}

       
     
//         {onReplaceDoc && (
//           <li onClick={onReplaceDoc}>Replace</li>
//         )}
//         {onRenameDoc && (
//           <li onClick={onRenameDoc}>Rename</li>
//         )}
//         {onDeleteDoc && (
//           <li onClick={onDeleteDoc}>Delete</li>
//         )}
//          {onDownloadDoc && (
//           <li onClick={onDownloadDoc}>Download File</li>
//         )}

        
        
//       </ul>
//     </div>
//   );
// });

// export default ActionCard;


import React, { forwardRef } from 'react';
import { useNavigate } from 'react-router-dom';

const ActionCard = forwardRef(({ show, position, company, handleClose, onViewDashboard, onEditCompany, onRemoveCompany, viewCompanyDetails, onReplaceDoc, onDeleteDoc, onDownloadDoc, onRenameDoc }, ref) => {
  const navigate = useNavigate();

  if (!show) return null;

  const style = {
    position: 'absolute',
    top: position.top,
    left: position.left - 45,
    zIndex: 1000,
  };

  return (
    <div className="action-card" style={style} ref={ref}>
      <ul>
        {onViewDashboard && (
          <li onClick={onViewDashboard}>View Dashboard</li>
        )}
        {viewCompanyDetails && (
          <li onClick={viewCompanyDetails}>View Company Details</li>
        )}
        {onEditCompany && (
          <li onClick={onEditCompany}>Edit Company</li>
        )}
        {onRemoveCompany && (
          <li onClick={onRemoveCompany}>Remove Company</li>
        )}

         {onRenameDoc && (
          <li onClick={onRenameDoc} className='doc_label_action'><img src="./images/Rename.svg" alt="drop" className="doc_icon_label" />Rename</li>
        )}

        {onReplaceDoc && (
          <li onClick={onReplaceDoc} className='doc_label_action'><img src="./images/Replace.svg" alt="drop" className="doc_icon_label" />Replace</li>
        )}
       
         {onDownloadDoc && (
          <li onClick={onDownloadDoc} className='doc_label_action' ><img src="./images/download_doc.svg" alt="drop" className="doc_icon_label" />Download</li>
        )}
        {onDeleteDoc && (
          <li onClick={onDeleteDoc} className='doc_label_action'><img src="./images/delete_doc.svg" alt="drop" className="doc_icon_label" />Delete File</li>
        )}
       
      </ul>
    </div>
  );
});

export default ActionCard;


