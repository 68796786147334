import React, { useState, useEffect, useRef } from 'react';
import Table from '../../Reusable/ReusableComponents/Table';
import { TiStarOutline } from "react-icons/ti";
import { BsThreeDotsVertical } from "react-icons/bs";
import moment from 'moment'; 
import 'react-toastify/dist/ReactToastify.css';
import InviteUser from '../EsgConsultantUsersFlow/InviteUser';
import { useDispatch, useSelector } from 'react-redux';
import { selectCompanyUsers, selectOrganisationUsers } from '../../Redux/Slices/EsgConsultantFlow/companyUsersSlice';
import { fetchCompanyUsers, fetchUserProfile, resendInvite, removeUser } from '../../Redux/Actions/Actions';
import Loader from '../../config/Loader.jsx';
import { ROLES, is_company_primary_admin, is_editor, is_esg_consultant, is_esg_consultant_owner, is_viewer, is_workspace_admin, our_organization } from "../../config/constants";
import { toast, ToastContainer } from 'react-toastify';
import CompanyUserProfile from '../../components/EsgConsultantCompaniesFlow/CompanyUserProfile.jsx';
import { shouldHideUserActions } from '../../config/Utilities';


const CompanyUsers = (props) => {
  const dispatch = useDispatch();
  const actionDropdownRef = useRef(null);

  const company_users = useSelector(selectCompanyUsers);
  const all_users = useSelector(selectOrganisationUsers);
  const [activeUserTab, setActiveUserTab] = useState(our_organization);
  const [showInviteUser, setShowInviteUser] = useState(false);
  const [showViewProfile, setShowViewProfile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [actionDropdown, setActionDropdown] = useState(null);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [expandedRow, setExpandedRow] = useState({ userId: null, option: '', selectedRole: null });

  const current_role = localStorage.getItem("selected_roleId");
  let company_name = props?.company?.company_name;
  let company_id = props?.company?.company_id;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (actionDropdownRef.current && !actionDropdownRef.current.contains(event.target)) {
        setActionDropdown(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const OurOrganizationTable = ({ columns, data, renderExpandedContent }) => (
    <Table
      columns={columns}
      data={data}
      rowKey="user_id"
      expandedRow={expandedRow} 
      renderExpandedContent={renderExpandedContent}
    />
  );

  const CompanyTable = ({ columns, data, renderExpandedContent }) => (
    <Table
      columns={columns}
      data={data}
      rowKey="user_id"
      expandedRow={expandedRow} 
      renderExpandedContent={renderExpandedContent}
    />
  );

  useEffect(() => {
    if (company_id) {
      setLoading(true);
      dispatch(fetchCompanyUsers(company_id))
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    }
  }, [dispatch, company_id]);

  const columns = [
    {
      key: 'user_name',
      header: 'Name',
    },
    { key: 'email', header: 'Email' },
    {
      key: 'role_name',
      header: 'Role',
      render: (row) => (
        <>
          {row.role_name} {row.role_name === 'Admin' && <TiStarOutline className='admin-icon' />}
        </>
      )
    },
    {
      key: 'lastActivity',
      header: 'Last Activity',
      render: (row) => {
        if (row.last_login_datetime === 'Pending Invite') {
          return (
            <span className='pending_invite'>
              Pending Invite
            </span>
          );
        } else if (row.last_login_datetime === 'Not Logged In') {
          return 'Not Logged In';
        } else if (row.last_login_datetime) {
          const formattedDate = moment(row.last_login_datetime).calendar(null, {
            sameDay: '[Today,] LT',
            lastDay: '[Yesterday,] LT',
            lastWeek: 'DD MMM YYYY',
            sameElse: 'DD MMM YYYY'
          });

          return formattedDate;
        } else {
          return 'No activity recorded';
        }
      }
    },
    {
      key: 'actions',
      header: 'Actions',
      render: (row) => (
        <div className="actions-cell">
          <BsThreeDotsVertical 
            className="actions-icon" 
            onClick={() => handleActionClick(row.user_id)} 
          />
          {actionDropdown === row.user_id && (
            <div className="actions-dropdown" ref={actionDropdownRef}>
              {row.last_login_datetime === "Pending Invite" ? (
                <>
                  {shouldHideUserActions(current_role) && (
                    <>
                      <div onClick={() => handleResendInvite(row.user_id)}>Resend Invite</div>
                      <div onClick={() => handleDropdownOptionClick(row.user_id, 'Remove User')}>Remove User</div>
                    </>
                  )}
                </>
              ) : (
                <>
                  {shouldHideUserActions(current_role) && <div onClick={() => handleDropdownOptionClick(row.user_id, 'Remove User')}>Remove User</div>}
                  <div onClick={() => handleViewProfile(row.user_info_id ? { user_info_id: row.user_info_id } : { user_id: row.user_id })}>View Profile</div>
                </>
              )}
            </div>
          )}
        </div>
      )
    }
  ];

  const handleActionClick = (userId) => {
    setActionDropdown((prev) => (prev === userId ? null : userId));
  };

  const handleResendInvite = (userId) => {
    const user = all_users.find(user => user.user_id === userId) || company_users.find(user => user.user_id === userId);

    const invites = [{
      email: user.email,
      role_id: user.role_id
    }];

    const payload = {
      invites,
      company_id,
      company_name,
      admin_user_id: localStorage.getItem('user_info_id')
    };

    setLoading(true);
    dispatch(resendInvite(payload))
      .then(() => {
        toast.success('Invite resent successfully', { toastId: 30 });
      })
      .catch(() => {
        toast.error('Failed to resend invite', { toastId: 31 });
      })
      .finally(() => {
        setLoading(false);
        setActionDropdown(null);
      });
  };

  const handleViewProfile = (_id) => {
    setSelectedUserId(_id);
    dispatch(fetchUserProfile({ _id }));
    setShowViewProfile(true);
  };

  const handleViewProfileClose = () => {
    setShowViewProfile(false);
    setSelectedUserId(null);
  };

  const handleDropdownOptionClick = (userId, option) => {
    setExpandedRow((prev) => (prev.userId === userId && prev.option === option ? { userId: null, option: '' } : { userId, option }));
    setActionDropdown(null);
  };

  const handleCancel = () => {
    setExpandedRow({ userId: null, option: '', selectedRole: null });
  };

const handleRemoveUser = (company_id, user_id, user_info_id) => {
  const logged_in_user_info_id = localStorage.getItem('user_info_id');
  const logged_in_role_id = localStorage.getItem('selected_roleId');
  
  let postParameters;

  if (user_info_id) {
    postParameters = {
      logged_in_user_info_id,
      logged_in_role_id,
      company_id,
      user_info_id,
      is_pending_invite: 0
    };
  } else {
    postParameters = {
      logged_in_user_info_id,
      logged_in_role_id,
      company_id,
      user_id,
      is_pending_invite: 1
    };
  }
  
    setLoading(true);
    dispatch(removeUser(postParameters))
      .then(() => {
        toast.success(
          <div className="custom-toast">
            <span className="toast-message">User has been removed successfully.</span>
          </div>,
          {
            position: 'top-center',
            autoClose: 3000,
            hideProgressBar: true,
            className: 'custom-toast-container'
          }
        );
        setExpandedRow({ userId: null, option: '', selectedRole: null });
      })
      .catch(() => {
        toast.error('Failed to remove user', { toastId: 32 });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderExpandedContent = (row) => {
    const isPendingInvite = row.last_login_datetime === 'Pending Invite';
  
    switch (expandedRow.option) {
      case 'Remove User':
        return (
          <div className='user-operations-container'>
            <p className='role-text'>Remove User</p>
            <div className='role-update-container d-flex justify-content-between align-items-center'>
              <p className='confirmation-text'>Are you sure you want to remove {row.user_name}?</p>
              <div className='update-role-btn-container mx-5'>
                <button className='cancel-update-role-btn' onClick={handleCancel}>Cancel</button>
                <button
                  className='update-role-btn'
                  onClick={() => handleRemoveUser(row.company_id, row.user_id, row.user_info_id,)}
                >
                  Remove User
                </button>
              </div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const handleInviteUserOpen = () => {
    setShowInviteUser(true);
  };

  const handleInviteUserClose = () => {
    setShowInviteUser(false);
  };

  const shouldHideInviteUser = (role, tab) => {
    if (role == is_viewer) {
      return false; // Don't show invite user button
    } else if (role == is_editor && tab == our_organization) {
      return false; // Don't show invite user button
    } else if (role == is_workspace_admin && tab == our_organization) {
      return false; // Don't show invite user button
    } else if (role == is_company_primary_admin && tab == our_organization) {
      return false; // Don't show invite user button
    } else {
      return true; // Default behavior (show invite user button)
    }
  };

  return (
    <div className='company-users'>
      <div className='row'>
        <div className='col-8 d-flex align-items-center'>
          <p
            className={`user-tab ${activeUserTab === our_organization ? 'active' : ''}`}
            onClick={() => setActiveUserTab(our_organization)}
          >
            Our Organization
          </p>
          <p
            className={`user-tab ${activeUserTab === company_name ? 'active' : ''}`}
            onClick={() => setActiveUserTab(company_name)}
          >
            {company_name}
          </p>
        </div>
        {shouldHideInviteUser(current_role, activeUserTab) && 
          <div className='col-4 d-flex justify-content-end'>
            <button className='btn company-user-invite' onClick={handleInviteUserOpen}>Invite User</button>
          </div>
        }
      </div>
      <div className='row'>
        <div className='col-12'>
          {loading ? (
            <Loader />
          ) : (
            <>
              {(activeUserTab === our_organization && all_users.length === 0) ||
              (activeUserTab !== our_organization && company_users.length === 0) ? (
                <div className="company-users">
                  <div className="centered-image">
                    <img src="./images/Welcome.png" alt="file" className="small-image" />
                    <div className="pt-2">
                      <h2>No Users Onboarded</h2>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  {activeUserTab === our_organization ? (
                    <OurOrganizationTable
                      columns={columns}
                      data={all_users}
                      renderExpandedContent={renderExpandedContent}
                    />
                  ) : (
                    <CompanyTable
                      columns={columns}
                      data={company_users}
                      renderExpandedContent={renderExpandedContent}
                    />
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>
      <InviteUser
        show={showInviteUser}
        handleClose={handleInviteUserClose}
        companyId={company_id}
        companyName={company_name}
        activeCompanyUserTab = {activeUserTab}
      />
      <CompanyUserProfile
        show={showViewProfile}
        handleClose={handleViewProfileClose}
        userId={selectedUserId}
      />
      <ToastContainer
        position='top-center'
        hideProgressBar={true}
        autoClose={2000}
      />
    </div>
  );
};

export default CompanyUsers;

