// axiosConfig.js

import axios from 'axios';

// Create an instance of Axios
const instance = axios.create({
  // Configure your Axios instance here
});

// Add a request interceptor
instance.interceptors.request.use(
  (config) => {
    // Show the loader before sending the request
    document.body.classList.add('show-loader');
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    // Hide the loader when the response is received
    document.body.classList.remove('show-loader');
    return response;
  },
  (error) => {
    // Hide the loader if an error occurs
    document.body.classList.remove('show-loader');
    return Promise.reject(error);
  }
);

export default instance;
