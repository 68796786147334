// import earthESG from '../assets/earthESG.gif'; 
import '../CssStyles/Loader.css';

const Loader = () => {
  
  return (
    <div>
        <div className="loading-spinner-overlay">
          <div className="loading-spinner">
            <img src="./images/earthESGLoader.gif" alt="Loading" />
          </div>
        </div>
    </div>
  );
};

export default Loader;