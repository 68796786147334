import React from 'react';

const ProgressBar = ({ value, max, label, color }) => {
    const percentage = (value / max) * 100;
  
    return (
      <div className="progress-bar">
        <div className="progress-bar-background">
          <div
            className="progress-bar-fill"
            style={{ width: `${percentage}%`, backgroundColor: color }}
          ></div>
        </div>
      </div>
    );
  };
  
  export default ProgressBar;