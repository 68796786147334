import React from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { CircularProgressbar,buildStyles } from 'react-circular-progressbar';
import '../../components/pages/PagesStyles/home.css'

const CircularProgressBar = ({ percentage,colour,textColor }) => {


  return (
    <div className='progress_bar_container mb-4 d-flex justify-content-center align-items-center'>
      <CircularProgressbar
        value={percentage}
        text={`${percentage}%`}
        circleRatio={0.7}
        strokeWidth={10}
        styles={buildStyles({
            rotation: 0.65,
            pathColor: colour, 
            trailColor: '#F4F5F9', 
            strokeLinecap: 'round',
            textSize: '20px', 
            textColor: textColor, 
            fontWeight: '700', 
            fontFamily: 'DM Sans', 
          })}
          ></CircularProgressbar>
    
    </div>
  );
};

export default CircularProgressBar;
