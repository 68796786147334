import '../../Reusable/ReusableStyles/UserCompanyName.css';
import {useState, useEffect,useContext} from 'react'
// import Form from 'react-bootstrap/Form';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import NavbarContext from './NavbarContext';
const constants = require('../../config/constants.jsx');

export default function HeaderComponent() {
  const [userCompanies, setUserCompanies] = useState([])
  const [loading, setLoading] = useState(true);
  const access_token = localStorage.getItem('access_token')

  // const {setCompanyName} = useContext(NavbarContext)

  const headers = {
    'Authorization': `Bearer ${access_token}`, 
    'Content-Type': 'application/json',
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const url = `${constants.BACKEND_SERVER_BASEURL}/users/getUserCompanies`;
  //       const response=await axios.get(url, {headers})
  //       const companies = response.data.companies
  //       setUserCompanies(companies);

  //       if (companies.length > 0) {
  //         setCompanyName(companies[0].company_name);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching user companies:', error);
  //     }finally {
  //       setLoading(false); 
  //     }
  //   };

  //   fetchData();
  // }, [setCompanyName, headers]);

  return (
    <div className='container-fluid '>
        <div className='row topbar-container px-2'>
            {/* <div className="col-12 col-md-6 col-lg-3">
              <Form.Select className='company-select'>
                {userCompanies.map(company => <option value={company.company_name} key={company.company_id}>{company.company_name}</option>)}
              </Form.Select>
            </div> */}
            {/* <div className="col-12 col-md-6 col-lg-3 company-container">
              <div className='company-box'>
                {userCompanies.map(company => (
                  <div className='company-item' key={company.company_id}>
                    {company.company_name}
                  </div>
                ))}
              </div>
            </div> */}
        </div>
    </div>
  );
}