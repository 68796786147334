import { createSlice } from '@reduxjs/toolkit';
import { fetchUsersByCompanyId, removeUser,removeUserAssociatedCompanies,resendInvite } from '../../Actions/Actions';
const usersSlice = createSlice({
    name: 'users',
    initialState: {
        users: [],
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUsersByCompanyId.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchUsersByCompanyId.fulfilled, (state, action) => {
                state.loading = false;
                state.users = action.payload;
            })
            .addCase(fetchUsersByCompanyId.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(removeUserAssociatedCompanies.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(removeUserAssociatedCompanies.fulfilled, (state, action) => {
                state.loading = false;
                state.users = state.users.filter(user => user.email !== action.meta.arg.remove_user_email);
            })
            .addCase(removeUserAssociatedCompanies.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(resendInvite.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(resendInvite.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(resendInvite.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});
export default usersSlice.reducer;