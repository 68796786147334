import React, { useEffect, useState } from 'react';
import { Doughnut, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import ProgressBar from './Progressbar';
ChartJS.register(...registerables);
const Card = (props) => {
  let object_data = props?.data_item ?? props?.data_item
  const role_id = localStorage.getItem('selected_roleId');
  let labels = []
  let chart_data = []
  let frame_work = typeof(object_data[object_data.frame_work]) == 'object'? object_data[object_data.frame_work]:{}
  for (let key in frame_work) {
    labels.push(key)
  }
  for (let key in frame_work) {
    chart_data.push(frame_work[key])
  }
  let data_lables = labels.pop()
  let final_data = chart_data.pop()
  const data = {
    labels: labels,
    datasets: [{
      data: chart_data,
      backgroundColor: [
        '#4ADE80',
        '#16A34A',
        '#15803D',
        '#22C55E'
      ],
      hoverOffset: 4
    }]
  };

  return (
    <div className="card">
      <div className='row justify-content-between p-3'>
        <div className='col-lg-4'><img style={{ width: '100%', height: '100%' }} src={object_data?.company_logo} alt={'logo'}></img></div>
        <div className='col-lg-8'>
          <h6 >{object_data?.company_name}</h6>
        </div>
      </div>
      <div className="card-body">
        <h6>Overall metrics</h6>
        <div className="py-2">
          <div>{frame_work?.overall_summary}</div>
          <ProgressBar value={frame_work?.overall_summary} max={100} label="" color="#00A656" />
        </div>
        <div className='row'>
          <Pie data={data} options={{
            responsive: true,
            plugins: {
              tooltip: {
                callbacks: {
                  label: function (tooltipItems) {
                    if (tooltipItems) {
                      return tooltipItems.formattedValue + "%"
                    }
                  }
                }
              },
              legend: {
                position: "bottom",
                display: true,
                labels: {
                  usePointStyle: true,
                  padding: window.innerWidth == 1024 ? 15 : 20,
                  pointStyle: "circle",
                  generateLabels: (chart) => {
                    const data = chart.data;
                    if (data.labels.length && data.datasets.length) {
                      return data.labels.map((label, i) => {
                        const meta = chart.getDatasetMeta(0);
                        const total = data.datasets[0].data.reduce((sum, value) => sum + value, 0);
                        const value = data.datasets[0].data[i];
                        const percentage = ((value / total) * 100).toFixed(2) + '%';
                        // const percentage = value + '%';


                        return {
                          text: `${label}: ${percentage}`,
                          fillStyle: data.datasets[0].backgroundColor[i],
                          strokeStyle: data.datasets[0].backgroundColor[i],
                          lineWidth: 1,
                          hidden: isNaN(data.datasets[0].data[i]) || meta.data[i].hidden,
                          index: i,
                          pointStyle: 'circle',
                        };
                      });
                    }
                  }
                }
              }
            }
          }}></Pie>
        </div>

      </div>
      {role_id == 1 && <>
        <hr />
        <div className='footer'>
          <div ><img className='pb-1' src="./images/phone_user.svg"></img> <span>ESG Consultant</span></div>
          <div >
            <span><b>{object_data?.esg_consultant_name}</b></span>
          </div>
        </div></>}

    </div>
  );


  //  return(
  //   <>
  //   {/* <div className='col-lg-3 col-md-3 col-sm-3 col-xs-3'> */}
  //    <div className="card w-25">
  //   <div className="card-body">
  // <div className='d-flex'>
  //   <div className='col-lg-4 col-md-4 col-sm-4 col-xs-4'><img src={object_data.comapny_image} alt={object_data.company_name}></img></div>
  //   <div className='col-lg-8 col-md-8 col-sm-8 col-xs-8 p-3'>
  // <h6 >{object_data.company_name}</h6>

  //   </div>
  //     </div>
  //     <div className='row'>
  //     <Pie data={data} options={{
  //       responsive: true,
  //       plugins: {
  //         tooltip: {
  // 					callbacks: {
  // 						label: function(tooltipItems) {
  // 							if (tooltipItems) {
  // 								return tooltipItems.formattedValue + "%"
  // 							}
  // 						}
  // 					}
  // 				},
  //         legend: {
  //           position:"bottom",
  //             display: true,
  //             labels: {
  //               usePointStyle:true,
  //               padding:window.innerWidth==1024?15:20,
  //               pointStyle:"circle",
  //               generateLabels: (chart) => {
  //                 const data = chart.data;
  //                 if (data.labels.length && data.datasets.length) {
  //                   return data.labels.map((label, i) => {
  //                     const meta = chart.getDatasetMeta(0);
  //                     const total = data.datasets[0].data.reduce((sum, value) => sum + value, 0);
  //                     const value = data.datasets[0].data[i];
  //                     const percentage = ((value / total) * 100).toFixed(2) + '%';
  //                     // const percentage = value + '%';


  //                     return {
  //                       text: `${label}: ${percentage}`,
  //                       fillStyle: data.datasets[0].backgroundColor[i],
  //                       strokeStyle: data.datasets[0].backgroundColor[i],
  //                       lineWidth: 1,
  //                       hidden: isNaN(data.datasets[0].data[i]) || meta.data[i].hidden,
  //                       index: i,
  //                       pointStyle: 'circle',
  //                     };
  //                   });
  //                 }
  //               }
  //             }
  //         }
  //     }
  //     }}></Pie>
  //     </div>
  //     <p className="card-text">{object_data.esg_consultant_name}</p>
  //   </div>
  // </div>
  // {/* </div> */}
  //   </>
  //  )

}
export default Card;