import React, { useState, useEffect } from 'react';
import { Offcanvas, Button, Table, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { IoFilter } from "react-icons/io5";
import { ROLES } from '../../config/constants';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { changeUserRole, removeUser, fetchCompanyUsers } from '../../Redux/Actions/Actions';
import { shouldHideUserActions } from '../../config/Utilities';

const CompanyUserProfile = ({ show, handleClose, userId ,}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userProfile = useSelector((state) => state.userProfile.profile);
  const userCompanies = useSelector((state) => state.userProfile.companies) || [];
  const loggedInRoleId = useSelector((state) => state.login.role_id);
  const current_role = localStorage.getItem("selected_roleId");
  const logged_in_user_info_id = localStorage.getItem('user_info_id');

  const [image, setImage] = useState('/images/default_profile.svg');
  const [editedProfile, setEditedProfile] = useState({
    name: '',
    jobTitle: '',
    email: '',
    phone: '',
    companies: []
  });

  useEffect(() => {
    if (userProfile) {
      setImage(userProfile.profile_image || '/images/default_profile.svg');
      setEditedProfile({
        name: `${userProfile.first_name ?? ''} ${userProfile.last_name ?? ''}`.trim() || '',
        jobTitle: userProfile.designation || '',
        email: userProfile.email,
        phone: userProfile.phone_number || '',
        companies: userCompanies.map(company => ({
          companyId: company.company_id,
          companyName: company.company_name,
          roleId: company.role_id,
          roleName: ROLES[company.role_id],
          companyEmail: company.company_email
        }))
      });
    }
  }, [userProfile]);

  const handleRoleChange = (index, newRole) => {
    const updatedCompanies = editedProfile.companies.map((company, i) => 
      i === index ? { ...company, roleName: newRole, roleId: Object.keys(ROLES).find(key => ROLES[key] === newRole) } : company
    );
    setEditedProfile(prevState => ({ ...prevState, companies: updatedCompanies }));
  };

  const removeCompany = (index) => {
    const updatedCompanies = editedProfile.companies.filter((_, i) => i !== index);
    setEditedProfile(prevState => ({ ...prevState, companies: updatedCompanies }));
  };

  const handleSave = () => {
    editedProfile.companies.forEach((company, index) => {
      const currentRole = userCompanies[index].role_name;
      if (currentRole !== company.roleName) {
        dispatch(changeUserRole({
          loggedInUserInfoId: logged_in_user_info_id,
          loggedInRoleId,
          userInfoId: userProfile.user_info_id,
          currentRoleId: userCompanies[index].role_id,
          roleRequested: company.roleId,
          // roleRequested: Object.keys(ROLES).find(key => ROLES[key] === company.role),
          companyId: company.companyId
        }))
        .unwrap()
        .then((result) => {
          toast.success('Role changed successfully!');
          
        })
        .catch((error) => {
          toast.error('Failed to change role!');
        });
      }
    });
  };

  // const handleCancel = () => {
  //   if (userProfile) {
  //     setImage(userProfile.profile_image || '/images/default_profile.svg');
  //     setEditedProfile({
  //       name: `${userProfile.first_name} ${userProfile.last_name}`,
  //       jobTitle: userProfile.designation || '',
  //       email: userProfile.email,
  //       phone: userProfile.phone_number || '',
  //       companies: userCompanies.map(company => ({
  //         companyId: company.company_id,
  //         companyName: company.company_name,
  //         roleId: company.role_id,
  //         roleName: ROLES[company.role_id],
  //         companyEmail: company.company_email
  //       }))
  //     });
  //   }
  //   handleClose();   

  // };

  const handleRemoveUser = (row) => {
    const companyId = row.companyId;
    const user_info_id = userProfile.user_info_id;
    const is_pending_invite = 0; // Set according to your logic
    const postParameters = {
      logged_in_user_info_id,
      logged_in_role_id: current_role,
      company_id: companyId,
      user_info_id,
      is_pending_invite
    };
    
    dispatch(removeUser(postParameters))
      .unwrap()
      .then((result) => {
        toast.success('User removed successfully!');
        removeCompany(editedProfile.companies.indexOf(row));
        handleClose(); 
        dispatch(fetchCompanyUsers(companyId));  
        navigate('/companyDetails');   
      })
      .catch((error) => {
        toast.error('Failed to remove user!');
      });
  };

  const handleActionClick = (e, row) => {
    handleRemoveUser(row);
  };

  const columns = [
    { key: 'company', header: 'Company', render: (row) => <>{row.companyName}</> },
    { 
      key: 'role', 
      header: 'Role', 
      render: (row, index) => (
        !shouldHideUserActions(current_role) 
        ? row.roleName 
        : (
          <select 
            value={row.roleName} 
            onChange={(e) => handleRoleChange(index, e.target.value)}
            className='company-users-select'
          >
            {Object.values(ROLES).map(role => (
              <option key={role} value={role}>{role}</option>
            ))}
          </select>
        )
      ) 
    }
  ];
  
  if (shouldHideUserActions(current_role)) {
    columns.push({
      key: 'actions', 
      header: 'Actions', 
      render: (row) => (
        <img 
          src='./images/DeleteIcon.svg' 
          className="actions-icon" 
          onClick={(e) => handleActionClick(e, row)} 
        />
      )
    });
  }
  

  const noBorderStyle = {
    border: 'none',
    color: 'var(--Black-90, #2C2D33)',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal'
  };

  const noBorderCellStyle = {
    border: 'none',
    color: 'var(--Black-90, #2C2D33)',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal'
  };

  const stylesTcell = {
    border: 'none',
    color: 'var(--black, #000)',
    fontFamily: ' Mulish',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    alignItems: 'center'
  };

  return (
    <Offcanvas show={show} onHide={handleClose} style={{ width: "35%" }} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title className="heading-filter">User Profile</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className='profile-card d-flex align-items-center mb-3'>
          <img
            src={image}
            alt="Profile"
            className="profile-img-user me-5"
          />
          <div>
            {editedProfile?.name && <p className='company_user_name m-0'>{editedProfile.name}</p>}
            {editedProfile?.jobTitle && (
              <p className='company_user_paragraph mb-0'>
                <img src="images/tabler_briefcase.svg" alt="Job Title" /> {editedProfile.jobTitle}
              </p>
            )}
            {editedProfile?.email && (
              <p className='company_user_paragraph mb-0'>
                <img src="images/ic_outline-email.svg" alt="Email" /> {editedProfile.email}
              </p>
            )}
            {editedProfile?.phone && (
              <p className='company_user_paragraph'>
                <img src="images/tabler_phone.svg" alt="Phone" /> {editedProfile.phone}
              </p>
            )}
          </div>

        </div>
        
        <div className="mb-5 w-100">
          <p className='companies-text'>Companies working with</p>
          <Table style={noBorderStyle} className=''>
            <thead >
              <tr className='company_list_table'>
                {columns.map(column => (
                  <th  className="heading-user-profile" key={column.key} style={noBorderCellStyle}>{column.header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {editedProfile.companies.map((company, index) => (
                <tr className='company_list_table' key={index}>
                  {columns.map(column => (
                    <td 
                    key={column.key} 
                    style={stylesTcell}
                    className={column.key === 'role' ? 'no-border-dropdown' : undefined}
                    >
                      {column.key === 'role' ? column.render(company, index) : column.render(company)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </Offcanvas.Body>
      {shouldHideUserActions(current_role) && 
        <div className="filter_btn_container d-flex mb-4 flex-row justify-content-end">
          <Button type="button" className="btn btn-success invite_cancel_btn" onClick={handleClose}>
            Cancel
          </Button>
          <Button type="button" className="btn btn-success send_invite_btn" style={{ marginRight: "25px" }} onClick={handleSave}>
            Save
          </Button>
        </div>
      }
      <ToastContainer position='top-center'
       hideProgressBar={true} 
      autoClose={1000} />
    </Offcanvas>
  );
};

export default CompanyUserProfile;




