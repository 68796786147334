import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CiSearch } from "react-icons/ci";
import { IoFilter } from "react-icons/io5";
import InviteUser from '../EsgConsultantUsersFlow/InviteUser';
import Table from '../../Reusable/ReusableComponents/Table';
import FilterUsers from '../../Reusable/ReusableComponents/FilterUsers';
import { BsThreeDotsVertical } from "react-icons/bs";
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { shouldHideUserActions } from '../../config/Utilities';
import { fetchUsersByCompanyId,fetchUserProfile,resendInvite,removeUserAssociatedCompanies } from '../../Redux/Actions/Actions';
import Loader from '../../config/Loader';
import CompanyUserProfile from '../EsgConsultantCompaniesFlow/CompanyUserProfile';

const Users = () => {

    const dispatch = useDispatch();
    const [showInviteUser, setShowInviteUser] = useState(false);
    const [showFilterUser, setShowFilterUser] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [actionDropdown, setActionDropdown] = useState(null);
    const actionDropdownRef = useRef(null);
    const [expandedRow, setExpandedRow] = useState({ userId: null, option: '' });
    const [expandedLogos, setExpandedLogos] = useState({});
    const current_role = localStorage.getItem("selected_roleId");
    const { users, loading } = useSelector((state) => state.users);
    const [showViewProfile,setShowViewProfile] = useState(false)
    const [selectedUserId, setSelectedUserId] = useState(null);



    let role_company_id = useSelector((state) => state?.login?.selected_role_company_id)


    useEffect(() => {
        const companyId = role_company_id
        if (companyId || current_role == 1) {
            dispatch(fetchUsersByCompanyId(companyId))
            setFilteredData(users)
        }
    }, [dispatch])

    useEffect(() => {
        const handleClickOutside = (event) => {
          if (actionDropdownRef.current && !actionDropdownRef.current.contains(event.target)) {
            setActionDropdown(null);
          }
        };
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, []);

    const columns = [
        {
            key: 'full_name',
            header: 'Name',
            render: (row) => row.full_name || 'User'
        },
        { key: 'email', header: 'Email' },
        {
            key: 'company_logos',
            header: 'Company',
            render: (row) => (
                <div className="company-logos-container">
                    {row.working_companies && row.working_companies.length > 0 && (
                        expandedLogos[row.user_info_id] ? (
                            row.working_companies.map((company, index) => (
                                <div key={index} className="company-logo-table">
                                    {company.company_logo ? (
                                        <img src={company.company_logo} alt={company.company_name} />
                                    ) : (
                                        <div className="company-initials">
                                            {company.company_name ? company.company_name.slice(0, 2).toUpperCase(): "$$"}
                                        </div>
                                    )}
                                </div>
                            ))
                        ) : (
                            <>
                                {row.working_companies.slice(0, 2).map((company, index) => (
                                    <div key={index} className="company-logo-table">
                                        {company.company_logo ? (
                                            <img src={company.company_logo} alt={company.company_name} />
                                        ) : (
                                            <div className="company-initials">
                                                {company.company_name ? company.company_name.slice(0, 2).toUpperCase(): "$$"}
                                            </div>
                                        )}
                                    </div>
                                ))}
                                {row.working_companies.length > 2 && (
                                    <span
                                        className="company-logos-count"
                                        onClick={() => handleLogoClick(row.user_info_id)}
                                    >
                                        +{row.working_companies.length - 2} more
                                    </span>
                                )}
                            </>
                        )
                    )}
                </div>
            )
        },
        {
            key: 'lastActivity',
            header: 'Last Activity',
            render: (row) => {
                if (row.is_pending_invite == 1) {
                    return (
                        <span className='pending_invite'>
                            Pending Invite
                        </span>
                    );
                } else if (row.last_login_datetime) {
                    const formattedDate = moment(row.last_login_datetime).calendar(null, {
                        sameDay: '[Today,] LT',
                        lastDay: '[Yesterday,] LT',
                        lastWeek: 'DD MMM YYYY',
                        sameElse: 'DD MMM YYYY'
                    });

                    return formattedDate;
                } else {
                    return 'No activity recorded';
                }
            }
        },
        {
            key: 'actions',
            header: 'Actions',
            render: (row) => (
                <div className="actions-cell">
                    <BsThreeDotsVertical
                        className="actions-icon"
                        onClick={() => handleActionClick(row.user_info_id ? row.user_info_id : row.user_id)}
                    />
                    {(actionDropdown === row.user_info_id || actionDropdown === row.user_id) && (
                        <div className="actions-dropdown" ref={actionDropdownRef}>
                            {row.is_pending_invite == 1 ? (
                                <> 
                                    {shouldHideUserActions(current_role) && <>
                                        <div>Resend Invite</div>
                                        <div onClick={() => handleDropdownOptionClick(row.user_info_id ? row.user_info_id : row.user_id, 'Remove User')}>Remove User</div>
                                    </>}
                                </>
                            ) : (
                                <>
                                    {/* <div onClick={() => handleDropdownOptionClick(row.user_info_id, 'View Profile', row.role_name)}>View Profile</div> */}
                                    <div onClick={() => handleViewProfile(row.user_info_id ? {user_info_id : row.user_info_id} : {user_id: row.user_id})}>View Profile</div>
                                    {shouldHideUserActions(current_role) && <div onClick={() => handleDropdownOptionClick(row.user_info_id ? row.user_info_id : row.user_id, 'Remove User')}>Remove User</div>}
                                </>
                            )}
                        </div>
                    )}
                </div>
            )
        }
    ];

    const handleResendInvite = (row) => {
        const invites = [{
            email: row.email,
            role_id: row.role_id 
        }];
        const payload = {
            invites,
            company_id: role_company_id,
            company_name: 'Company Name',  // Replace with actual company name
            admin_user_id: localStorage.getItem('user_info_id')
        };
        
        dispatch(resendInvite(payload))
            .unwrap()
            .then(() => {
                toast.success('Invite resent successfully', { toastId: 30 });
            })
            .catch(() => {
                toast.error('Failed to resend invite', { toastId: 31 });
            })
            .finally(() => {
                setActionDropdown(null);
            });
    };

    const handleInviteUserOpen = () => {
        setShowInviteUser(true);
    };

    const handleInviteUserClose = () => {
        setShowInviteUser(false);
    };

    const handleFilterUserOpen = () => {
        setShowFilterUser(true);
    };

    const handleFilterUserClose = () => {
        setShowFilterUser(false);
    };

    const handleApplyFilters = (filters) => {
        const { userStatus, userName, company } = filters;
        let filtered = users;

        if (userStatus.length > 0) {
            filtered = filtered.filter(user =>
                (userStatus.includes('Active') && user.last_login_datetime !== null) ||
                (userStatus.includes('Inactive') && user.last_login_datetime === null)
            );
        }

        if (userName.length > 0) {
            filtered = filtered.filter(user => userName.includes(user.full_name));
        }

        if (company.length > 0) {
            filtered = filtered.filter(user =>
                user.working_companies.some(companyItem => company.includes(companyItem.company_name))
            );
        }

        setFilteredData(filtered);
    };

    const handleActionClick = (userId) => {
        setActionDropdown((prev) => (prev === userId ? null : userId));
    };

    const handleDropdownOptionClick = (userId, option) => {
        const newExpandedRow = (expandedRow?.userId === userId && expandedRow?.option === option) ? { userId: null, option: '' } : { userId, option };
        setExpandedRow(newExpandedRow);
        setActionDropdown(null);
        console.log(expandedRow,"in handleDropdownOptionClick")
    };

    const handleCancel = () => {
        setExpandedRow({ userId: null, option: '' });
    }

    const handleLogoClick = (userId) => {
        setExpandedLogos((prev) => ({
            ...prev,
            [userId]: !prev[userId]
        }));
    };

    const handleViewProfile = (_id) => {
        setSelectedUserId(_id);
        dispatch(fetchUserProfile({_id}));
        setShowViewProfile(true);
      };
      
      
      const handleViewProfileClose = () => {
        setShowViewProfile(false);
              setSelectedUserId(null);
      };


    const handleRemoveUser = (row) => {
        const logged_in_user_info_id = localStorage.getItem("user_info_id");
        const logged_in_role_id = localStorage.getItem("selected_roleId");
        let postParameters = {
            logged_in_user_info_id,
            logged_in_role_id: Number(logged_in_role_id),
            remove_user_email: row.email
          };
      dispatch(removeUserAssociatedCompanies(postParameters))
        .unwrap()
        .then(() => {
          toast.success(
            <div className="custom-toast">
              {" "}
              <span className="toast-message">
                User {row.full_name} has been removed successfully.
              </span>{" "}
            </div>,
            {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              className: "custom-toast-container",
            }
          );
          setExpandedRow({ userId: null, option: "" });
          dispatch(fetchUsersByCompanyId(role_company_id));
        })
        .catch((error) => {
          toast.error(
            <div className="custom-toast">
              {" "}
              <span className="toast-message">
                Failed to remove user: {error}
              </span>{" "}
            </div>,
            {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: true,
              className: "custom-toast-container",
            }
          );
        });
    };

    const renderExpandedContent = (row) => {
      switch (expandedRow.option) {
        case "Remove User":
          console.log("remove user")
          return (
            <div className="user-operations-container">
              {" "}
              <p className="role-text">Remove User</p>{" "}
              <div className="role-update-container d-flex justify-content-between align-items-center">
                {" "}
                <p className="confirmation-text">
                  Are you sure you want to remove {row?.full_name}?
                </p>{" "}
                <div className="update-role-btn-container mx-5">
                  {" "}
                  <button
                    className="cancel-update-role-btn"
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>{" "}
                  <button
                    className="update-role-btn"
                    onClick={() => handleRemoveUser(row)}
                  >
                    {" "}
                    Remove User{" "}
                  </button>{" "}
                </div>{" "}
              </div>{" "}
            </div>
          );
        default:
            console.log("default")
          return null;
      }
    };

    const usersNames = users
        .filter(user => user.full_name)
        .map(user => user.full_name);


        const companyNames = [
            ...new Set(users
                .filter(user => Array.isArray(user.working_companies))
                .flatMap(user => user.working_companies.map(company => company.company_name))
            )
        ];
        

    return (
        <div className='users_bg_container'>
            <div className='container-fluid'>
                <div className="row align-items-center mb-4">
                    <div className="col-12 col-md-8 justify-content-center">
                        <h2 className='users_heading'>Users</h2>
                    </div>
                    <div className="col-12 col-md-4 d-flex justify-content-end">
                        <div className="search-bar-container">
                            <CiSearch className="search_icon" />
                            <input type="text" className="search-input" placeholder="Search" />
                        </div>
                        <div className='d-flex align-items-center filter_container' onClick={handleFilterUserOpen}>
                            <IoFilter className='filter_icon' />
                            <span className='filter_text'>Filter</span>
                        </div>
                        {shouldHideUserActions(current_role) && <button className="invite_user_btn" onClick={handleInviteUserOpen}>
                            Invite User
                        </button>}
                    </div>
                </div>
                <div className='row'>
                    <div className='col-12'>
                        {loading ? (
                            <Loader/>
                        ) : filteredData.length > 0 ? (
                            <Table
                                columns={columns}
                                data={filteredData}
                                rowKey="users_list"
                                expandedRow={expandedRow}
                                renderExpandedContent={renderExpandedContent}
                                withBorder={false}
                            />
                        ) : (
                            <div className="no-records-container">
                                <p className="no-records">No records found</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <InviteUser show={showInviteUser} handleClose={handleInviteUserClose} />
            <FilterUsers
                show={showFilterUser}
                handleClose={handleFilterUserClose}
                onApply={handleApplyFilters}
                allUserNames={usersNames}
                allCompanies={companyNames}
            />
            <CompanyUserProfile
                show={showViewProfile}
                handleClose={handleViewProfileClose}
                userId={selectedUserId}
            />
            <ToastContainer />
        </div>
    );

};

export default Users;
