import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loader from '../../../config/Loader.jsx';
import '../../Reports/ReportStyles/DataCollection.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'react-accessible-accordion/dist/fancy-example.css';
import 'react-circular-progressbar/dist/styles.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const constants = require('../../../config/constants.jsx');

export const FormDataSection = () => {

  const location = useLocation();
  const navigate = useNavigate();

  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedSubcategory, setSelectedSubcategory] = useState(location.state?.fields_data || []);
  let [subCategory, setSubCategory] = useState('');
  const [inputValues, setInputValues] = useState(location.state?.initialValues || {});
  const textareaRefs = useRef([]);
  const [loading, setLoading] = useState(false);
  const { iterationId, companyId } = useParams();

  const [subcategoryInputs, setSubcategoryInputs] = useState({});



  const handleSubcategorySelect = (fields, subcategory) => {
    //   setSelectedSubcategory(fields);
    setSubCategory(subcategory);
    // Load saved input values for the selected subcategory
    const initialValues = subcategoryInputs[subcategory] || {};
    fields.forEach((item) => {
      initialValues[item.data_point_id] = initialValues[item.data_point_id] || item.data_point_value || '';
    });
    setInputValues(initialValues);
  };
  const handleInputChange = (e, dataPointId, subcategory) => {
    const { value } = e.target;

    setInputValues((prevState) => ({
      ...prevState,
      [dataPointId]: value,
    }));

    setSubcategoryInputs((prevInputs) => ({
      ...prevInputs,
      [subcategory]: {
        ...prevInputs[subcategory],
        [dataPointId]: value,
      },
    }));
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    let updatedData = selectedSubcategory.map((item) => ({
      ...item,
      value: inputValues[item.data_point_id] || subcategoryInputs[item.data_point_id] || '',
    }));
    try {
      const response = await axios.post(
        `${constants.BACKEND_SERVER_BASEURL}/dataPoints/updateDataPointsById`,
        {
          updatedDataPointsData: updatedData,
        }
      );
      if (response.data.message === "Update process started") {
        toast.success("Data Point updated successfully", {
          toastId: 19
        });
      } else {
        throw new Error("Update process failed");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.warning("An error occurred while updating data point", {
        toastId: 19
      });
    } finally {
      setLoading(false);
    }
  };



  useEffect(() => {
    // Adjust textarea heights on initial render and whenever inputValues change
    textareaRefs.current.forEach((textarea) => {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    });
  }, [inputValues]);



  const handleTextareaRef = (textarea, index) => {
    if (textarea && !textareaRefs.current.includes(textarea)) {
      textareaRefs.current[index] = textarea;
    }
  };

  function findPercentage(completedDataPoints, incompleteDataPoints) {
    var percent = (completedDataPoints / (completedDataPoints + incompleteDataPoints)) * 100;
    return Math.round(percent);
  }
  const handleSubcategorySelectWrapper = (fields, subcategory) => {
    handleSubcategorySelect(fields, subcategory);
  };

  const handleInputChangeWrapper = (e, dataPointId) => {
    handleInputChange(e, dataPointId, subCategory);
  };

  const handleSummaryReportButton = () => {
    // Set loading state to true when button is clicked
    navigate(`/summaryReport/${iterationId}/${companyId}`);

  };

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <div className="container-fluid bg-white data_container">
          <div className="row px-2 bg-white" >
            <div className="col-md-6 col-lg-9">
              <div className="inputs" id='inputs'>
                {selectedSubcategory.length > 0 && (
                  <form className="form-section" onSubmit={handleSubmit}>
                    {selectedSubcategory.map((item, index) => (
                      <div key={item.data_point_id} className="form-group input-form-group">
                        <label htmlFor={item.field} className='data-point'>{item.field}</label>
                        <textarea
                          ref={(textarea) => handleTextareaRef(textarea, index)}
                          type="text"
                          id={item.field}
                          name={item.field}
                          value={inputValues[item.data_point_id] || ''}
                          onChange={(e) => handleInputChangeWrapper(e, item.data_point_id)}
                          className="form-control answer-text"
                        />
                      </div>
                    ))}
                    <div className='btn-container'>
                      <button type="submit" className="btn btn-success  save_btn_mobile">
                        Save
                      </button>
                      <button className={`edit-page-button generate-button generate_reports_mobile_view`} onClick={handleSummaryReportButton} disabled={loading}>Generate Reports</button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer
        position="top-center"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  )
}

export default FormDataSection