import { createSlice } from '@reduxjs/toolkit';
import { updateUserProfile } from '../../Actions/Actions';


const initialState = {
  formData: {
    firstName: '',
    lastName: '',
    designation: '',
    department: '',
    profileImage: '',
    email: '',
    phoneNumber: '',
    country: '',
    State: '',
    city: '',
    zipcode: '',
    timeZone: '',
  },
};

const letsGetStartedSlice = createSlice({
  name: 'letsGetStarted',
  initialState,
  reducers: {
    updateFormData(state, action) {
      state.formData = { ...state.formData, ...action.payload };
    },
    resetFormData(state) {
      state.formData = initialState.formData;
    },
  },
});

export const { updateFormData, resetFormData } = letsGetStartedSlice.actions;
export const selectLetsGetStartedFormData = (state) => state.letsGetStarted.formData;

export default letsGetStartedSlice.reducer;
