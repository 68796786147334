import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import "../Timelines/timeline.css"
import { Link ,useLocation } from 'react-router-dom';
import Users from '../EsgConsultantUsersFlow/Users';
import { fetchCompanyActivities } from "../../Redux/Actions/Actions"
import { useNavigate } from 'react-router-dom';
import CompanyUsers from './CompanyUsers';
import CompanyInformation from '../EsgConsultantCompaniesFlow/CompanyInformation';
import CompanyDocuments from '../EsgConsultantCompaniesFlow/CompanyDocuments';

const CompanyDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState('Information');
  // const [activeTab, setActiveTab] = useState(localStorage.getItem('activeTab') || 'Information'); 

  const { state } = useLocation();
  const company = state?.company || {};

  const allCompanyActivities = useSelector((state) => state?.companyActivities?.company_activity);
  const selectedCompany = useSelector((state) => state?.companies?.selectedCompany?.company_id);

  useEffect(() => {
    dispatch(fetchCompanyActivities(selectedCompany));
  }, [dispatch]);


  const handleTabClick = (tab) => {
    setActiveTab(tab);
    localStorage.setItem('activeTab', tab); 
  };

  const renderTabContent = () => {
    
    switch (activeTab) {
      case 'Information':
        return <CompanyInformation/>;

      case 'Documents':
        return <CompanyDocuments/>;

      case 'Users':
        return <CompanyUsers company={company} />;

      case 'Activities':
        return (
          <>
            {allCompanyActivities.length > 0 && (
              <div className="card_comapny_details timeline">
                {allCompanyActivities.map((activity, index) => (
                  <div className="timeline-item" key={activity.activity_id}>
                    <div className="timeline-item-content">
                      <div className="timeline-item-header">
                        {activity.user_name} -{" "}
                        {moment(activity.created_datetime).format("D MMM YYYY, h:mm A")}
                      </div>
                      {activity.activities.map((active, idx) => (
                        <div className="extra-timeline-info" key={idx}>
                          <div className="timeline-event">
                            <h5 className="activity_heading">
                              <img
                                src="./images/pointer_image.svg"
                                alt=""
                                className="pointer_image"
                              />
                              <span>{active.activity_name}</span>
                            </h5>
                            <p className="grey">{active.activity_description}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        );               
      default:
        return null;
    }
  };

  return (
    <>
    <div className="container">
        

      <div className="spacer"></div>
      <div className="back_company_list">
        <h6 className="back_company_list_text">
        <Link to="/companieslist">
        <img src="./images/backArrow.svg" alt="Back" />
          Back to companies list</Link>
        </h6>
      </div>
      <div className="vwr_international">
        <h5 className="vwr_international_text">{company.company_name}</h5>
      </div>
      <div className="d-flex flex-row justify-content-start row_company_details">
        {['Information', 'Documents', 'Users', 'Activities'].map(tab => (
          <p
            key={tab}
            className={`px-4 child_details ${activeTab === tab ? 'active' : ''}`}
            onClick={() => handleTabClick(tab)} 
          >
            {tab}
          </p>
        ))}
      </div>
      {renderTabContent()}
    </div>
    </>
  );
};

export default CompanyDetails;
