import React from 'react'
import FileUpload from '../../../Reusable/ReusableComponents/FileUpload';
import '../../PopUpCards/PopupCardStyles/UploadDocumentsPopup.css';

const UploadDocumentsPopup = ({closeUploadFile,uploadPopup}) => {
    if(!uploadPopup) return null
  return (
    <div>
      <div className='overlay-styles'>
            <div className='upload-document-modal'>
                <div className='d-flex file_upload_card'>
                    <FileUpload popupCard={true}/>
                    <p className="close_upload_popup" onClick={()=>closeUploadFile()}>&times;</p>
                </div>
            </div>
          </div>
    </div>
  )
}

export default UploadDocumentsPopup