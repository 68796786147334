import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import TimezoneSelect from 'react-timezone-select';
import { useDispatch, useSelector } from 'react-redux';
import { Country, State, City } from 'country-state-city';
import { updateFormData, selectLetsGetStartedFormData } from '../../Redux/Slices/popupCardSlices/letsGetStartedSlice.js';
import Loader from '../../config/Loader.jsx';
import { updateUserProfile } from '../../Redux/Actions/Actions.js'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const customStyles = {
  control: (provided) => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
    padding: '0px',
  }),
  singleValue: (provided) => ({
    ...provided,
    color: '#777E90',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: '#000',
  }),
};

const LetsGetStartedCard = ({ showModal, onClose, onBack }) => {
  const dispatch = useDispatch();
  const formData = useSelector(selectLetsGetStartedFormData);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const [errors, setErrors] = useState({});
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [countryCode, setCountryCode] = useState('')

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    const logedEmail = localStorage.getItem('user_email');
    if (logedEmail) {
      dispatch(updateFormData({ ...formData, email: logedEmail }));
    }
    // Cleanup listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const validateInputFields = () => {
    const newErrors = {};

    if (!formData.email || formData.email.trim() === '') {
      newErrors.email = 'Email is required';
    }
    if (!formData.phoneNumber || formData.phoneNumber.trim() === '') {
      newErrors.phoneNumber = 'Phone number is required';
    }
    if (!formData.country || !formData.country.name) {
      newErrors.country = 'Country is required';
    }
    if (!formData.State || !formData.State.name) {
      newErrors.State = 'State is required';
    }
    if (!formData.city || !formData.city.name) {
      newErrors.city = 'City is required';
    }
    if (!formData.zipcode || formData.zipcode.trim() === '') {
      newErrors.zipcode = 'Zipcode is required';
    }
    if (!formData.timeZone || !formData.timeZone.value) {
      newErrors.timeZone = 'Time zone is required';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(updateFormData({ ...formData, [name]: value }));
    setErrors((prev) => ({ ...prev, [name]: '' }));
  };

  const handleCountryChange = (selectedOption) => {
    dispatch(updateFormData({ ...formData, country: selectedOption, State: null, city: null,phoneNumber:null }));
    setCountryCode(selectedOption.value)

    setStates(State.getStatesOfCountry(selectedOption.value).map(state => ({
      value: state.isoCode,
      label: state.name
    })));
    setCities([]);
    setErrors((prev) => ({ ...prev, country: '', State: '', city: '' }));
  };

  const handleStateChange = (selectedOption) => {
    dispatch(updateFormData({ ...formData, State: selectedOption, city: null }));
    setCities(City.getCitiesOfState(formData.country.value, selectedOption.value).map(city => ({
      value: city.name,
      label: city.name,
      latitude: city.latitude,
      longitude: city.longitude
    })));
    setErrors((prev) => ({ ...prev, State: '', city: '' }));
  };

  const handleCityChange = (selectedOption) => {
    dispatch(updateFormData({ ...formData, city: selectedOption }));
    setErrors((prev) => ({ ...prev, city: '' }));
  };

  const handleTimezoneChange = (timezone) => {
    dispatch(updateFormData({ ...formData, timeZone: timezone }));
    setErrors((prev) => ({ ...prev, timeZone: '' }));
  };

  const handlePhoneNumberChange = (value) => {
    dispatch(updateFormData({ ...formData, phoneNumber: value }));
    setErrors((prev) => ({ ...prev, phoneNumber: '' }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    localStorage.setItem("popUpData",0)

  
    try {
  
      const response = await dispatch(updateUserProfile(formData));
      setIsLoading(false); 
  
      if (response.error) {
        toast.error("Something went wrong: " + response.error.message);
        onClose();
      } else {
        toast.success("Profile updated successfully!");
        onClose();
        window.location.reload();
      }
    } catch (error) {
      setIsLoading(false); 
      console.error(error.message);
      toast.error("An error occurred: " + error.message);
    }
  };
  
  

  if (!showModal) return null;

  const countries = Country.getAllCountries().map(country => ({
    value: country.isoCode,
    label: country.name
  }));

  return (
    <div className="overlay">
      <div className="container lgs-modal">
        <div className="lets_get_started_container">
          <div className="container_gap col-12">
            {isMobileView && (
              <img
                src="./images/eva_arrow-back-outline.svg"
                className="arrow_image mb-3"
                alt="arrowImage"
                onClick={onBack}
              />
            )}
            <p className="form_heading">Let's get you started</p>
            <p className="form_paragraph">
              This helps us determine the frameworks you have to fill in
            </p>
          </div>

          <form className="form_card" onSubmit={handleSubmit}>
            <div className="basic-info-card">
              <span className="step-text">Step 2/2</span>
              <span className="basic-info">Contact info</span>
            </div>
            <div className="input_field_container d-flex flex-wrap justify-content-between align-items-center">
              <div className="input_fields">
                <label htmlFor="email" className="input_label">
                  Email
                  {/* <span className="required-asterisk">*</span> */}
                </label>
                <input
                  type="email"
                  className="contact_info_input_box greyed_out_field"
                  id="email"
                  name="email"
                  autoComplete="off"
                  value={formData.email}
                  onChange={handleChange}
                  readOnly
                />
                {errors.email && (
                  <span className="error-message">{errors.email}</span>
                )}
              </div>
              
              <div className="input_fields">
                <label htmlFor="country" className="input_label">
                  Country
                </label>
                <Select
                  options={countries}
                  value={formData.country}
                  onChange={handleCountryChange}
                  styles={customStyles}
                  placeholder="Select Country"
                  className="select_input_box"
                />
                {errors.country && (
                  <span className="error-message">{errors.country}</span>
                )}
              </div>
              <div className="input_fields">
                <label htmlFor="State" className="input_label">
                  State
                </label>
                <Select
                  options={states}
                  value={formData.State}
                  onChange={handleStateChange}
                  styles={customStyles}
                  placeholder="Select State"
                  isDisabled={!formData.country}
                  className="select_input_box"
                />
                {errors.State && (
                  <span className="error-message">{errors.State}</span>
                )}
              </div>
              <div className="input_fields">
                <label htmlFor="city" className="input_label">
                  City
                </label>
                <Select
                  options={cities}
                  value={formData.city}
                  onChange={handleCityChange}
                  styles={customStyles}
                  placeholder="Select City"
                  isDisabled={!formData.State}
                  className="select_input_box"
                />
                {errors.city && (
                  <span className="error-message">{errors.city}</span>
                )}
              </div>
              <div className="input_fields">
                <label htmlFor="phoneNumber" className="input_label">
                  Phone Number
                </label>
                <PhoneInput
                  className="contact_info_input_box"
                  id="phoneNumber"
                  name="phoneNumber"
                  placeholder="Enter phone number"
                  value={formData.phoneNumber}
                  onChange={handlePhoneNumberChange}
                  defaultCountry ={countryCode}
                />
                {errors.phoneNumber && (
                  <span className="error-message">{errors.phoneNumber}</span>
                )}
              </div>
              <div className="input_fields">
                <label htmlFor="zipcode" className="input_label">
                  Zipcode
                </label>
                <input
                  type="text"
                  className="contact_info_input_box"
                  id="zipcode"
                  name="zipcode"
                  autoComplete="off"
                  value={formData.zipcode}
                  onChange={handleChange}
                />
                {errors.zipcode && (
                  <span className="error-message">{errors.zipcode}</span>
                )}
              </div>
              <div className="input_fields">
                <label htmlFor="timeZone" className="input_label">
                  Time Zone
                </label>
                <TimezoneSelect
                  className="select_input_box"
                  id="timeZone"
                  name="timeZone"
                  value={formData.timeZone}
                  onChange={handleTimezoneChange}
                  styles={customStyles}
                />
                {errors.timeZone && (
                  <span className="error-message">{errors.timeZone}</span>
                )}
              </div>
            </div>
            <div className="d-flex align-self-end lgs_button_container">
              <button
                type="button"
                className="lgs-back-btn"
                onClick={onBack}
              >
                Back
              </button>
              <button
                type="submit"
                className="lets_get_you_started_button lgs-next-btn"
              >
                Save
              </button>
            </div>
          </form>
            {isLoading && <Loader />}
        </div>
      </div>
    </div>
  );
};

export default LetsGetStartedCard;
