import '../../pages/PagesStyles/home.css';
import HeaderComponent from '../../../Reusable/ReusableComponents/UserCompanyName';
import FileUpload from '../../../Reusable/ReusableComponents/FileUpload';
import { useEffect,useState } from 'react';
import { useScrollTrigger } from '@mui/material';
import PopupModal from '../../PopUpCards/PopupModal.jsx';
export default function Home() {
  const [newlog,setNewlog]=useState(false)

  useEffect(()=>{
    let user=localStorage.getItem("popUpData")
    if(user==1){
      setNewlog(true)
    }
  })
  
  return (
        <div className='home-container'>
         {newlog &&  <PopupModal/>}
          <div className='home-page'>
            <FileUpload/>
          </div>
     
        </div>
  );
}