import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux'
import { ToastContainer,toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { setSelectedRoleCompanyId } from '../../Redux/Slices/UserLoginFlowSlices/LoginSlice';

export default function SelectRole() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { roles, email,is_first_time_login } = location.state || { roles: [], email: '',is_first_time_login:''};
  const [selectedRole, setSelectedRole] = useState(null);
  let user_roles = useSelector((state) => state?.login?.unique_user_info)

  const handleRoleSelect = (role) => {
    setSelectedRole(role);
  };

  const handleStart = async () => {
    if (selectedRole) {   

      const loginData = { email, password: 'userpassword' };

      try {
        // const response = await dispatch(userLogin(loginData));
        localStorage.setItem("selected_roleId",selectedRole.role_id)
        dispatch(setSelectedRoleCompanyId(selectedRole.company_id))
        if (is_first_time_login === true) {
          localStorage.setItem("popUpData", 1);
          // navigate('/home');
          navigate('/dashboard')
        } else {
          localStorage.setItem("popUpData", 0);
          // navigate('/home');
          navigate('/dashboard')
        }
      } catch (error) {
        toast.warning("Failed to log in. Please try again.", {
          toastId: 22
        });
      }
    } else {
      toast.warning("Please select a role before starting", {
        toastId: 23
      });
    }
  };

  return (
    <div>
      <div className='overlay'>
        <div className='container welcome-custom-modal'>
          <div className='column'>
            <div className='col-12 col-md-10 col-lg-12'>
              <div className='select_role_div'>
                <h6 className='select_role'>Select Role</h6>
                <p className='select_role_paragraph'>This helps us to display the list of companies assigned to your role</p>
              </div>
              <ul className='role-list'>
                {user_roles.map((role, index) => (
                  <li
                    key={index}
                    className={`role-item ${selectedRole === role ? 'selected' : ''}`}
                    onClick={() => handleRoleSelect(role)}
                  >
                    <span>{role.role_name}</span>
                    {selectedRole === role && (
                      <img src="./images/right_icon.svg" alt="Image" className="role-icon" />
                    )}
                  </li>
                ))}
              </ul>
            </div>
            <div className='d-flex justify-content-end'>
              <button className='confirm_button' onClick={handleStart}>Confirm</button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
       position='top-center'
       hideProgressBar={true}
       autoClose={2000}
       />
    </div>
  );
}

