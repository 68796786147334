import React, { useEffect,useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../Reusable/ReusableComponents/Table';
import { Link, useLocation } from 'react-router-dom';
import { BsThreeDotsVertical } from "react-icons/bs";
import ActionCard from '../EsgConsultantCompaniesFlow/ActionCard';
import { fetchDocuments, deleteDocument, renameDocument, downloadDocument, replaceDocument } from '../../Redux/Actions/Actions';
import Loader from '../../config/Loader.jsx';

const CompanyDocuments = () => {
    const { state } = useLocation();
    const company = state?.company || {};
    const dispatch = useDispatch();

    const [actionCardState, setActionCardState] = useState({ show: false, position: { top: 0, left: 0 }, document: null });
    const [activeTab, setActiveTab] = useState('All Files');
    const [searchTerm, setSearchTerm] = useState('');
    const actionCardRef = useRef(null);
    const documents = useSelector((state) => state.documents.documents);
    const loading = useSelector((state) => state.documents.loading);
    const error = useSelector((state) => state.documents.error);
    const [isLoading, setLoading] = useState(false);

    const roles = localStorage.getItem("selected_roleId");
    
    const isESGConsultantOwner = roles.includes(1);
    const isESGConsultantAdmin = roles.includes(2);
    const isEditor = roles.includes(3);
    const isViewer = roles.includes(4);

    // useEffect(() => {
    //     dispatch(fetchDocuments(company.company_id));
    // }, [dispatch, company]);

    useEffect(() => {
        const fetchData = async () => {
          setLoading(true);
          try {
            await dispatch(fetchDocuments(company.company_id));
          } finally {
            setTimeout(() => {
              setLoading(false);
            }, 500); 
          }
        };
      
        fetchData();
      }, [dispatch,company]);


    useEffect(() => {
        const handleClickOutside = (event) => {
            if (actionCardRef.current && !actionCardRef.current.contains(event.target)) {
                setActionCardState({ ...actionCardState, show: false });
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [actionCardState]);

    const columns = [
        { key: 'document_name', header: 'File Name', render: (row) => <>{row.document_name}</> },
        { key: 'uploaded_date_time', header: 'Date Uploaded', render: (row) => <>{row.uploaded_date_time}</> },
        { key: 'updated_date_time', header: 'Date Updated', render: (row) => <>{row.updated_date_time}</> },
        {
            key: 'actions', header: 'Actions', render: (row) => (
                <BsThreeDotsVertical className="actions-icon" onClick={(e) => handleActionClick(e, row)} />
            )
        }
    ];

    const handleActionClick = (e, document) => {
        const rect = e.target.getBoundingClientRect();
        setActionCardState({ show: true, position: { top: rect.top, left: rect.left }, document });
    };

    const handleActionClose = () => {
        setActionCardState({ ...actionCardState, show: false });
    };

    const handleDelete = (doc_id, user_info_id) => {
        dispatch(deleteDocument({ doc_id, user_info_id }));
        handleActionClose();
    };

    const handleRename = (doc_id, new_name, user_info_id) => {
        dispatch(renameDocument({ doc_id, new_name, user_info_id }));
        handleActionClose();
    };

    const handleDownload = (doc_id, user_info_id) => {
        dispatch(downloadDocument({ doc_id, user_info_id }));
        handleActionClose();
    };

    const handleReplace = (doc_id, file, user_info_id) => {
        dispatch(replaceDocument({ doc_id, file, user_info_id }));
        handleActionClose();
    };

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    const filteredDocuments = documents.filter((doc) =>
        doc.document_name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <>
          {loading ? (
            <Loader /> // Show loader
          ) : (
            documents.length > 0 ? (
              <div className="card_comapny_details">
                <div className="d-flex flex-row justify-content-start align-items-center row" style={{ width: "100%" }}>
                  <div className="col-6 d-flex align-items-center tabs-container">
                    {['All Files', 'PDFs', 'Documents', 'Spreadsheets', 'Images'].map((tab) => (
                      <div
                        key={tab}
                        className={`tab-item ${activeTab === tab ? 'active' : ''}`}
                        onClick={() => handleTabClick(tab)}
                      >
                        {tab}
                      </div>
                    ))}
                  </div>
                  <div className="col-3"></div>
                  <div className="col-2">
                    <div className="search-container-document">
                      <img src="./images/icon_search.svg" alt="search" className="search-icon-document" />
                      <input
                        type="text"
                        className="company_documents_search"
                        placeholder="Search"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-1">
                    <button type="button" className="filter-button filter_documents_company">
                      <img src="./images/bi_filter.svg" alt="filter" className="filter-icon" />
                      Filters
                    </button>
                  </div>
                </div>
                <div className="table_content">
                  <Table className="company_list_table" columns={columns} data={filteredDocuments} rowKey="company_id" />
                </div>
              </div>
            ) : (
              <div className="centered-image d-flex flex-column justify-content-center align-items-center">
                <img src="./images/fillesImage.svg" alt="file" />
                <div className="pt-2 d-flex flex-column justify-content-center align-items-center">
                  <h2>No Documents Available</h2>
                </div>
              </div>
            )
          )}
      
          {actionCardState.show && (
            <ActionCard
              ref={actionCardRef}
              show={actionCardState.show}
              position={actionCardState.position}
              handleClose={handleActionClose}
              company={actionCardState.document}
              onDownloadDoc={
                (isESGConsultantOwner || isESGConsultantAdmin || isViewer || isEditor)
                  ? () => handleDownload(actionCardState.document.doc_id, localStorage.getItem("user_info_id"))
                  : undefined
              }
              onReplaceDoc={
                (isESGConsultantOwner || isESGConsultantAdmin || isEditor)
                  ? (file) => handleReplace(actionCardState.document.doc_id, file, localStorage.getItem("user_info_id"))
                  : undefined
              }
              onRenameDoc={
                (isESGConsultantOwner || isESGConsultantAdmin || isEditor)
                  ? (new_name) => handleRename(actionCardState.document.doc_id, "sample_177707.pdf", localStorage.getItem("user_info_id"))
                  : undefined
              }
              onDeleteDoc={
                (isESGConsultantOwner || isESGConsultantAdmin)
                  ? () => handleDelete(actionCardState.document.doc_id, localStorage.getItem("user_info_id"))
                  : undefined
              }
            />
          )}
        </>
      );
      
      
};

export default CompanyDocuments;
