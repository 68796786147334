import React, { useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import axios from "../../config/axiosConfig.jsx";
import "../../Reusable/ReusableStyles/fileUpload.css";
import { useNavigate } from "react-router-dom";
import { LuUpload } from "react-icons/lu";
import { FaPlus } from "react-icons/fa6";
import { FiFile } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
import Loader from "../../config/Loader.jsx";
import SubmissionCompleted from "../../components/PopUpCards/PopupCardComponents/SubmissionCompleted.jsx";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const constants = require("../../config/constants.jsx");

function FileUploadComponent({ popupCard }) {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 767);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleDrop = (acceptedFiles) => {
    const pdfFiles = acceptedFiles.filter(
      (file) => file.type === "application/pdf"
    );
    const newFiles = pdfFiles.filter(
      (file) => !files.some((existingFile) => existingFile.name === file.name)
    );
    if (pdfFiles.length !== newFiles.length) {
      toast.error("File already exists!", {
        toastId: 123,
        position: "top-center",
      });
      return;
    }
    setFiles([...files, ...newFiles]);
  };

  const handleRemove = (index, event) => {
    event.stopPropagation();
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  };

  const access_token = localStorage.getItem("access_token");

  const handleUpload = async () => {
    setLoading(true);

    // Insert new iteration (report) with status with the status id 0 (0-Inserting Data related to iteration) in database
    const iteration_id = await axios.post(
      `${constants.BACKEND_SERVER_BASEURL}/iterations/insertNewIteration`,
      {},
      { headers: { Authorization: `Bearer ${access_token}` } }
    );

    // Create a empty datapoints for with above iteration_id
    await axios.post(
      `${constants.BACKEND_SERVER_BASEURL}/dataPoints/createDataPoints`,
      { iteration_id: iteration_id.data },
      { headers: { Authorization: `Bearer ${access_token}` } }
    );

    // Upload documents to Vultr Objects and store URLs in database along with above iteration_id
    const formData = new FormData();
    // adding number of files into formdata
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }
    formData.append("iteration_id", iteration_id.data);
    try {
      await axios.post(
        `${constants.BACKEND_SERVER_BASEURL}/fileUpload/uploadFilesToStorage`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
    } catch (error) {
      console.error("Upload error:", error);
    }
    setFiles([]);
    // Update status of iteration (report) with the status id 1 (1-Data Inserted successfully) in database
    await axios.post(
      `${constants.BACKEND_SERVER_BASEURL}/iterations/updateIterationStatus`,
      { iteration_id: iteration_id.data },
      { headers: { Authorization: `Bearer ${access_token}` } }
    );
    setLoading(false);
    setModalOpen(true);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    multiple: true,
    accept: { 'application/pdf': ['.pdf'] },
  });
  //submission completed popup component functionality
  const handlePopupModal = () => {
    setModalOpen(false);
  };

  return (
    <div className={`container-fluid px-4 upload-container-file-upload`}>
      {files.length === 0 && !popupCard && (
        <div className="d-flex justify-content-center file_image_card">
          <img src="./images/fillesImage.svg" alt="file" className="file_image" />
        </div>
      )}
      {!isMobile && (
        <div className="row" {...getRootProps()}>
          <div className=" d-flex flex-column justify-content-center ">
            <div className="dropzone">
              <input {...getInputProps()} />
              <div
                className={`d-flex justify-content-center dropzone-content ${
                  isDragActive ? "active" : ""
                }`}
              >
                {isDragActive ? (
                  <p>Drop the files here...</p>
                ) : (
                  <>
                    <div className="d-flex align-items-center fileupload_card">
                      <LuUpload className="upload-icon" />
                      <div className="d-flex flex-column justify-content-center drop-text">
                        <p className="dropzone_text desktop-only">
                          Select a file or drag and drop here
                        </p>
                        <p className="dropzone_text mobile-only">
                          Upload your files
                        </p>
                        <span className="pdf_text">PDF file</span>
                      </div>
                      <button className="upload-button desktop-only">
                        <FaPlus /> Upload Documents
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
            {files.length === 0 && (
              <button className="upload-button mobile-only">
                <FaPlus /> Upload Documents
              </button>
            )}
            {files.length > 0 && (
              <button className="upload-button mobile-top">
                <FaPlus /> Upload Documents
              </button>
            )}
          </div>
        </div>
      )}
      {isMobile && (
        <div className="row" {...getRootProps()}>
          <div className=" d-flex flex-column justify-content-center ">
            {files.length > 0 && <div></div>}
            {files.length === 0 && (
              <div className="dropzone">
                <input {...getInputProps()} />
                <div
                  className={`d-flex justify-content-center dropzone-content ${
                    isDragActive ? "active" : ""
                  }`}
                >
                  {isDragActive ? (
                    <p>Drop the files here...</p>
                  ) : (
                    <>
                      <div className="d-flex align-items-center fileupload_card">
                        <LuUpload className="upload-icon" />
                        <div className="d-flex flex-column justify-content-center drop-text">
                          <p className="dropzone_text desktop-only">
                            Select a file or drag and drop here
                          </p>
                          <p className="dropzone_text mobile-only">
                            Upload Documents
                          </p>
                          <span className="pdf_text">PDF file</span>
                        </div>
                        <button className="upload-button desktop-only">
                          <FaPlus /> Upload Documents
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
            {files.length === 0 && (
              <button className="upload-button mobile-only">
                <FaPlus /> Upload Documents
              </button>
            )}
            {files.length > 0 && (
              <button className="upload-button mobile-top">
                <FaPlus /> Upload Documents
              </button>
            )}
          </div>
        </div>
      )}

      <div className="uploaded-files">
        {files.length > 0 && (
          <p className="files_heading">
            {popupCard ? "New Files" : "File's added"}
          </p>
        )}
        <div className="row">
          {files.map((file, index) => (
            <div
              key={index}
              className={`${popupCard ? "" : "col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-3"}`}
            >
              <div className="uploaded-file">
                <div className="d-flex align-items-center">
                  <FiFile
                    className={`file-icon `}
                  />
                  <span className="uploaded-filename" title={file.name}>
                    {file.name.length > 20
                      ? `${file.name.slice(0, 20)}...`
                      : file.name}
                  </span>
                </div>
                <span className="file-size">
                  {(file.size / (1024 * 1024)).toFixed(2)}MB
                </span>
                <button
                  onClick={(event) => handleRemove(index, event)}
                  className="remove-button"
                >
                  <RxCross2 className="cross_mark_icon" />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div>
        {files.length > 0 && !loading && (
          <div className={popupCard ? "upload-btn-popup" : "upload-btn"}>
            <button
              className={`upload-button ${
                popupCard ? "submit-button-popup" : "submit-button"
              } mobile-submit-button`}
              onClick={handleUpload}
            >
              Submit For Analysis
            </button>
          </div>
        )}
      </div>
      
      {/* {files.length < 1 && (
                <div className='start-manually'>
                  <button className='manual-start-button'>Start Manually</button>
                  <div className='manual-text'>You can also upload a document while you are manually entering data.</div>
                </div>
              )} */}

      {loading && <Loader />}

      {modalOpen && (
        <SubmissionCompleted
          onCancel={handlePopupModal}
          onClose={handlePopupModal}
        />
      )}
      <ToastContainer 
       position='top-center'
       hideProgressBar={true}
       autoClose={2000}
      />
    </div>

  );
}

export default FileUploadComponent;
