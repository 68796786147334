import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Navbar from './Navbar';
import Select from 'react-select';
import { updateFormData, selectLetsGetStartedFormData } from '../../Redux/Slices/popupCardSlices/letsGetStartedSlice';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import TimezoneSelect from 'react-timezone-select';
import { Country, State, City } from 'country-state-city';
import { GoPencil } from "react-icons/go";
import { fetchUserProfile, updateUserProfile } from '../../Redux/Actions/Actions';
import Loader from '../../config/Loader';
import { toast } from 'react-toastify';


const customStyles = {
    control: (provided) => ({
        ...provided,
        border: 'none',
        boxShadow: 'none',
        padding: '0px',
    }),
    singleValue: (provided) => ({
        ...provided,
        color: '#777E90',
    }),
    indicatorSeparator: () => ({
        display: 'none',
    }),
    dropdownIndicator: (provided) => ({
        ...provided,
        color: '#000',
    }),
};

const UserProfile = () => {
    const dispatch = useDispatch();
    const formData = useSelector(selectLetsGetStartedFormData);
    const { profile, companies, status, error } = useSelector(state => state.userProfile);
    const [isEditing, setIsEditing] = useState(false);
    const [editedProfile, setEditedProfile] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [image, setImage] = useState('/images/default_profile.svg');
    const [countryCode, setCountryCode] = useState('')



    const handleImageUpload = (e) => {
        const file = e.target.files[0];
        dispatch(updateFormData({ profileImage: file }));

        const base64Image = URL.createObjectURL(file);
        setEditedProfile(prevState => ({ ...prevState, profile_image: base64Image }))
    };

    useEffect(() => {
        getUserDetails();
    }, [dispatch]);

    useEffect(() => {
        if (profile) {
            setEditedProfile(profile);
            dispatch(updateFormData({ ...formData, email: profile.email }));
        }
    }, [profile, dispatch]);

    const handleEditProfile = () => {
        setIsEditing(true);
    };

    const handleCountryChange = (selectedOption) => {
        dispatch(updateFormData({ ...formData, country: selectedOption, State: null, city: null }));
        setCountryCode(selectedOption.value)
        setStates(State.getStatesOfCountry(selectedOption.value).map(state => ({
            value: state.isoCode,
            label: state.name
        })));
        setEditedProfile(prevState => ({ ...prevState, 'country': selectedOption.label, state: null, city: null, phone_number: null }))
        setCities([]);
    };

    const handleStateChange = (selectedOption) => {
        dispatch(updateFormData({ ...formData, State: selectedOption, city: null }));
        setCities(City.getCitiesOfState(formData.country.value, selectedOption.value).map(city => ({
            value: city.name,
            label: city.name,
            latitude: city.latitude,
            longitude: city.longitude
        })));
        setEditedProfile(prevState => ({ ...prevState, 'state': selectedOption.label, city: null }))

    };

    const handleCityChange = (selectedOption) => {
        dispatch(updateFormData({ ...formData, city: selectedOption }));
        setEditedProfile(prevState => ({ ...prevState, 'city': selectedOption.label }))
    };

    const handlePhoneNumberChange = (value) => {
        dispatch(updateFormData({ ...formData, phoneNumber: value }));
    };

    const handleTimezoneChange = (timezone) => {
        dispatch(updateFormData({ ...formData, timeZone: timezone }));
    };

    const handleCancelEdit = () => {
        setEditedProfile(profile);
        setIsEditing(false);
    };

    const getUserDetails = () => {
        const user_info_id = localStorage.getItem('user_info_id');
        if (user_info_id) {
            dispatch(fetchUserProfile({_id:{user_info_id}}));
        }
    }

    const handleSaveProfile = async () => {
        setIsLoading(true);
        try {

            let res = await dispatch(updateUserProfile(formData));
            setIsLoading(false);
            setIsEditing(false);
            getUserDetails();
            toast.success("Profile updated successfully!");
        } catch (error) {
            setIsLoading(false);
            console.error("Error saving profile:", error);
            toast.error("Error saving profile.");
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        dispatch(updateFormData({ ...formData, [name]: value }));
        if (name == "firstName") {
            setEditedProfile(prevState => ({
                ...prevState,
                ["first_name"]: value
            }));
        } else if (name == "lastName") {
            setEditedProfile(prevState => ({
                ...prevState,
                ["last_name"]: value
            }));
        } else {
            setEditedProfile(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    if (status === 'loading' || !profile) {
        return <Loader />;
    }

    if (status === 'failed') {
        return <div>Error: {error}</div>;
    }

    const countries = Country.getAllCountries().map(country => ({
        value: country.isoCode,
        label: country.name
    }));

    return (
        <div className='container-fluid user-profile-container'>
            <Navbar />
            <div className='row'>
                <div className='col-12'></div>
                <div className='col-12 container-card mt-5'>
                    <div className='user-profile-div'>
                        <p className='user-profile-text'>User Profile</p>
                        {isEditing ? (
                            <div className='d-flex flex-column align-items-start user-profile-edit-card'>
                                <div className='d-flex justify-content-between align-items-center w-100 profile-container'>
                                    <div className='profile-card d-flex align-items-center'>
                                        <img
                                            src={editedProfile?.profile_image || image}
                                            alt="Profile"
                                            className="profile-img"
                                        />
                                        <label htmlFor="customUploadButton" className="upload-button-label">
                                            Upload New Photo
                                        </label>
                                        <input
                                            type="file"
                                            accept="image/*"
                                            className="upload-button"
                                            id="customUploadButton"
                                            onChange={handleImageUpload}
                                        />
                                    </div>


                                </div>
                                <div className='contact_details_card'>
                                    <div className='row user_information'>
                                        <div className='col-6 '>
                                            <div className='user-input-field w-100'>
                                                <label className='user-profile-label' htmlFor="first_name">First Name</label>
                                                <br />
                                                <input
                                                    type="text"
                                                    id="first_name"
                                                    name="firstName"
                                                    className='user-profile-value w-100'
                                                    value={editedProfile.first_name || ''}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='user-input-field w-100'>
                                                <label className='user-profile-label' htmlFor="last_name">Last Name</label>
                                                <br />
                                                <input
                                                    type="text"
                                                    id="last_name"
                                                    name="lastName"
                                                    className='user-profile-value'
                                                    value={editedProfile.last_name || ''}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row user_information'>
                                        <div className='col-6'>
                                            <div className='user-input-field'>
                                                <label className='user-profile-label' htmlFor="designation">Designation</label>
                                                <br />
                                                <input
                                                    type="text"
                                                    id="designation"
                                                    name="designation"
                                                    className='user-profile-value'
                                                    value={editedProfile.designation || ''}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='user-input-field'>
                                                <label className='user-profile-label' htmlFor="department">Department</label>
                                                <br />
                                                <input
                                                    type="text"
                                                    id="department"
                                                    name="department"
                                                    className='user-profile-value'
                                                    value={editedProfile.department || ''}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row user_information'>
                                        <div className='col-6'>
                                            <div className='user-input-field'>
                                                <label className='user-profile-label' htmlFor="email">Email</label>
                                                <br />
                                                <input
                                                    type="text"
                                                    id="email"
                                                    name="email"
                                                    className='user-profile-value'
                                                    value={editedProfile.email || ''}
                                                    onChange={handleChange}
                                                    readOnly
                                                />
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='user-input-field'>
                                                <label htmlFor="phoneNumber" className="user-profile-label">
                                                    Phone Number
                                                </label>
                                                <br />
                                                <PhoneInput
                                                    className="user-profile-value"
                                                    id="phoneNumber"
                                                    name="phoneNumber"
                                                    placeholder="Enter phone number"
                                                    value={editedProfile.phone_number || ''}
                                                    onChange={handlePhoneNumberChange}
                                                    defaultCountry={countryCode}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row user_information'>
                                        <div className='col-6 mb-3'>

                                            <label htmlFor="country" className="input_label">
                                                Country
                                            </label>
                                            <Select
                                                options={countries}
                                                value={{ value: editedProfile.country, label: editedProfile.country }}
                                                onChange={handleCountryChange}
                                                styles={customStyles}
                                                placeholder="Select Country"
                                                className="select_input_box"
                                            />
                                        </div>
                                        <div className='col-6'>

                                            <label htmlFor="State" className="input_label">
                                                State
                                            </label>
                                            <Select
                                                options={states}
                                                value={{ value: editedProfile.state, label: editedProfile.state }}
                                                onChange={handleStateChange}
                                                styles={customStyles}
                                                placeholder="Select State"
                                                isDisabled={!formData.country}
                                                className="select_input_box"
                                            />
                                        </div>
                                    </div>
                                    <div className='row user_information'>
                                        <div className='col-6'>
                                            <label htmlFor="city" className="input_label">
                                                City
                                            </label>
                                            <Select
                                                options={cities}
                                                value={{ value: editedProfile.city, label: editedProfile.city }}
                                                onChange={handleCityChange}
                                                styles={customStyles}
                                                placeholder="Select City"
                                                isDisabled={!formData.State}
                                                className="select_input_box"
                                            />
                                        </div>
                                        <div className='col-6'>
                                            <div className='user-input-field'>
                                                <label htmlFor="zipcode" className="input_label">
                                                    Zipcode
                                                </label>
                                                <input
                                                    type="text"
                                                    className="user_profile_input_box"
                                                    id="zipcode"
                                                    name="zipcode"
                                                    autoComplete="off"
                                                    value={editedProfile.zipcode}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row user_information'>

                                        <div className='col-6'>
                                            <label htmlFor="timeZone" className="input_label">
                                                Time Zone
                                            </label>
                                            <TimezoneSelect
                                                className="select_input_box"
                                                id="timeZone"
                                                name="timeZone"
                                                value={{ label: editedProfile.time_zone || '', value: editedProfile.time_zone || '' }}
                                                onChange={handleTimezoneChange}
                                                styles={customStyles}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='contact_details_card'>
                                    {companies.length > 0 && (
                                        <>
                                            <p className='contact_details_heading'>Companies you’re working with</p>
                                            <div className='row user_companies'>
                                                <div className='col-10 d-flex align-items-start'>
                                                    <span className='company-name-heading'>Company Name</span>
                                                </div>
                                                <div className='col-2 d-flex align-items-start'>
                                                    <span className='user-company-name'>Role</span>
                                                </div>
                                            </div>
                                            {companies.map(company => (
                                                <div className='row pt-3' key={company.user_company_id}>
                                                    <div className='col-10 d-flex align-items-start'>
                                                        <span className='company-name-heading'>{company.company_name}</span>
                                                    </div>
                                                    <div className='col-2 d-flex align-items-start'>
                                                        <span className='user-company-name'>{company.role_name}</span>
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </div>

                                <div className='d-flex profile-btn-container'>
                                    <button className='edit-cancel-btn' onClick={handleCancelEdit}>Cancel</button>
                                    <button className='edit-save-btn' onClick={handleSaveProfile}>Save</button>
                                    {isLoading && <Loader />}
                                </div>
                            </div>
                        ) : (
                            <div className='d-flex flex-column align-items-start user-profile-card'>
                                <div className='d-flex justify-content-between align-items-center w-100 profile-container'>
                                    <div className='profile-card d-flex align-items-center'>
                                        {profile?.profile_image ? (
                                            <img src={profile.profile_image} alt="Profile" className='profile-img' />
                                        ) : (
                                            <img src="/images/default_profile.svg" alt="Default Profile" className='profile-img' />
                                        )}
                                        <span className='d-flex flex-column'>
                                            <span className='user-name'>{`${profile.first_name || ''} ${profile.last_name || ''}`}</span>
                                            {profile.designation && profile.department ? `${profile.designation}, ${profile.department}` :
                                                profile.designation ? profile.designation :
                                                    profile.department ? profile.department : ''}
                                        </span>
                                    </div>
                                    <div className='profile-card'>
                                        <button className='profile-edit-btn' onClick={handleEditProfile}><GoPencil /> Edit</button>
                                    </div>
                                </div>
                                <div className='contact_details_card'>
                                    <p className='contact_details_heading'>Contact Details</p>
                                    <div className='row user_information'>
                                        <div className='col-3 d-flex align-items-start mb-2'>
                                            <span className='email-heading'>Email</span>
                                        </div>
                                        <div className='col-7 d-flex align-items-start'>
                                            <span className='email'>{profile?.email}</span>
                                        </div>
                                        <div className='col-3 d-flex align-items-start mb-2'>
                                            <span className='email-heading'>Phone Number</span>
                                        </div>
                                        <div className='col-7 d-flex align-items-start'>
                                            <span className='email'>{profile?.phone_number}</span>
                                        </div>
                                        <div className='col-3 d-flex align-items-start mb-2'>
                                            <span className='email-heading'>Location</span>
                                        </div>
                                        <div className='col-7 d-flex align-items-start'>
                                            <span className='email'>{profile?.state}</span>
                                        </div>
                                        <div className='col-3 d-flex align-items-start'>
                                            <span className='email-heading'>Time Zone</span>
                                        </div>
                                        <div className='col-7 d-flex align-items-start'>
                                            <span className='email'>{profile?.time_zone}</span>
                                        </div>
                                    </div>
                                </div>
                                {companies?.length > 0 && (
                                    <>
                                        <div className='contact_details_card'>
                                            <p className='contact_details_heading'>Companies you’re working with</p>
                                            <div className='row user_companies'>
                                                <div className='col-10 d-flex align-items-start'>
                                                    <span className='company-name-heading'>Company Name</span>
                                                </div>
                                                <div className='col-2 d-flex align-items-start'>
                                                    <span className='user-company-name'>Role</span>
                                                </div>
                                            </div>
                                            {companies.map(company => (
                                                <div className='row pt-3' key={company.user_company_id}>
                                                    <div className='col-10 d-flex align-items-start'>
                                                        <span className='company-name-heading'>{company.company_name}</span>
                                                    </div>
                                                    <div className='col-2 d-flex align-items-start'>
                                                        <span className='user-company-name'>{company.role_name}</span>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserProfile;
