import React from 'react';
import PropTypes from 'prop-types';

const Pagination = ({
    currentPage,
    totalPages,
    itemsPerPage,
    totalItems,
    onPageChange,
    onItemsPerPageChange,
    onPageSelectChange
}) => {
    const startIndex = (currentPage - 1) * itemsPerPage;

    return (
        <div className="pagination-container">
            <div className='d-flex'>
                <div className="items-per-page mr-2">
                    <select className='page_size' value={itemsPerPage} onChange={(e) => onItemsPerPageChange(Number(e.target.value))}>
                        {[7, 10, 20].map((number) => (
                            <option key={number} value={number}>{number}</option>
                        ))}
                    </select>
                    <span className='items_per_page'>Items per page</span>
                </div>
                <div className="pagination-info">
                    {`${startIndex + 1}-${Math.min(startIndex + itemsPerPage, totalItems)} of ${totalItems} items`}
                </div>
            </div>
            <div className="pagination-buttons">
                <select className='page_size' value={currentPage} onChange={(e) => onPageSelectChange(Number(e.target.value))}>
                    {Array.from({ length: totalPages }, (_, index) => index + 1).map(page => (
                        <option key={page} value={page}>{page}</option>
                    ))}
                </select>
                <span className='items_per_page'> of {totalPages} pages</span>
                <button
                    onClick={() => onPageChange('prev')}
                    disabled={currentPage === 1}
                    className={`prev_page ${currentPage === 1 ? 'disabled' : ''}`}
                >
                    &lt;
                </button>
                <button
                    onClick={() => onPageChange('next')}
                    disabled={currentPage === totalPages}
                    className={`next_page ${currentPage === totalPages ? 'disabled' : ''}`}
                >
                    &gt;
                </button>
            </div>
        </div>
    );
};

Pagination.propTypes = {
    currentPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    itemsPerPage: PropTypes.number.isRequired,
    totalItems: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    onItemsPerPageChange: PropTypes.func.isRequired,
    onPageSelectChange: PropTypes.func.isRequired,
};

export default Pagination;
