import React, { useState, useEffect } from "react";
import { Offcanvas, Form, Row, Col, Button } from "react-bootstrap";
import Select, { components } from 'react-select';
import { FaMapMarkerAlt, FaSearch, FaBuilding } from 'react-icons/fa';
import { MdExpandLess } from "react-icons/md";


const FilterOffcanvas = ({ show, handleClose, locations, industries, companyNames, applyFilters }) => {
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedIndustries, setSelectedIndustries] = useState([]);
  const [selectedCompanyNames, setSelectedCompanyNames] = useState([]);

  const [locationsList, setLocationsList] = useState([]);
  const [industriesList, setIndustriesList] = useState([]);
  const [companyNamesList, setCompanyNamesList] = useState([]);

  const [showSections, setShowSections] = useState({
    roleCard: true,
    userStatusCard: true,
    userNameCard: true
  });


  useEffect(() => {
    if (locations && locations.length) setLocationsList(locations)
    if (industries && industries.length) setIndustriesList(industries)
    if (companyNames && companyNames.length) setCompanyNamesList(companyNames)
  }, [locations, industries, companyNames])



  const handleLocationChange = (selectedOptions) => {
    const newSelectedLocations = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setSelectedLocations(newSelectedLocations);
  };

  const handleIndustryChange = (selectedOptions) => {
    const newSelectedIndustries = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setSelectedIndustries(newSelectedIndustries);
  };

  const handleCompanyNameChange = (selectedOptions) => {
    const newSelectedCompanyNames = selectedOptions ? selectedOptions.map(option => option.value) : [];
    setSelectedCompanyNames(newSelectedCompanyNames);
  };

  const handleLocationCheckboxChange = (location) => {
    const newSelectedLocations = selectedLocations.includes(location)
      ? selectedLocations.filter(item => item !== location)
      : [...selectedLocations, location];
    setSelectedLocations(newSelectedLocations);
  };

  const handleIndustryCheckboxChange = (industry) => {
    const newSelectedIndustries = selectedIndustries.includes(industry)
      ? selectedIndustries.filter(item => item !== industry)
      : [...selectedIndustries, industry];
    setSelectedIndustries(newSelectedIndustries);
  };

  const handleCompanyNameCheckboxChange = (companyName) => {
    const newSelectedCompanyNames = selectedCompanyNames.includes(companyName)
      ? selectedCompanyNames.filter(item => item !== companyName)
      : [...selectedCompanyNames, companyName];
    setSelectedCompanyNames(newSelectedCompanyNames);
  };

  const handleApplyFilters = () => {
    const filters = {
      selectedLocations,
      selectedIndustries,
      selectedCompanyNames
    };
    applyFilters(filters);
    handleClose();
  };

  const handleCancelFilters = () => {
    setSelectedLocations([]);
    setSelectedIndustries([]);
    setSelectedCompanyNames([]);
  };

  const handleToggleSection = (section) => {
    setShowSections((prevState) => ({
      ...prevState,
      [section]: !prevState[section]
    }));
  };


  const CustomPlaceholder = (props) => {
    return (
      <components.Placeholder {...props}>
        <FaMapMarkerAlt style={{ marginRight: '8px' }} />
        e.g. Mumbai, India
      </components.Placeholder>
    );
  };

  const CustomPlaceholderIndusrty = (props) => {
    return (
      <components.Placeholder {...props}>
        <FaSearch style={{ marginRight: '8px' }} />
        e.g. Research
      </components.Placeholder>
    );
  };

  const CompanyPlaceholder = (props) => (
    <components.Placeholder {...props}>
      <FaBuilding style={{ marginRight: '8px' }} />
      e.g. Google
    </components.Placeholder>
  );


  return (
    <Offcanvas show={show} onHide={handleClose} style={{ width: '35%' }} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title className="heading-filter">Filters</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Form>
          <Form.Group className="mb-3" controlId="locationFilter">
            <Row>

              <Col xs={11}>
                <Form.Label className="location_filter">Location</Form.Label>
              </Col>
              <Col xs={1}>
                <img className={showSections.roleCard ? "img-reverse" : ""} src="./images/dropDownUp.svg" alt="dropdown" style={{ marginLeft: "-15px" }} onClick={() => handleToggleSection('roleCard')} />
              </Col>
            </Row>
            {showSections.roleCard && (
              <>
                <Select
                  className="location_dropdwon"
                  options={locationsList}
                  isSearchable
                  placeholder="e.g. Mumbai, India"
                  onChange={handleLocationChange}
                  isMulti
                  value={locationsList.filter(location => selectedLocations.includes(location.value))}
                  components={{ Placeholder: CustomPlaceholder }}
                />
                <div className="checkbox-group mt-2">
                  {locationsList.map((location, index) => (
                    <div key={index} className="form-check">
                      <label className="form-check-label" htmlFor={"location" + index}>
                        {location.label}
                      </label>
                      <input
                        className="form-check-input form_check_box label-filter"
                        id={"location" + index}
                        type="checkbox"
                        checked={selectedLocations.includes(location.value)}
                        onChange={() => handleLocationCheckboxChange(location.value)}
                      />
                    </div>
                  ))}
                </div>
              </>
            )}

          </Form.Group>
          <Form.Group className="mb-3" controlId="industryFilter">
            <Row>
              <Col xs={11}>
                <Form.Label className="location_filter">Industry</Form.Label>
              </Col>
              <Col xs={1}>
                <img className={showSections.userStatusCard ? "img-reverse" : ""} src="./images/dropDownUp.svg" alt="dropdown" style={{ marginLeft: "-15px" }} onClick={() => handleToggleSection('userStatusCard')} />
              </Col>
            </Row>
            {showSections.userStatusCard && (
              <>
                <Select
                  className="location_dropdown"
                  options={industriesList}
                  placeholder="e.g. Research"
                  isSearchable
                  onChange={handleIndustryChange}
                  isMulti
                  value={industriesList.filter(industry => selectedIndustries.includes(industry.value))}
                  components={{ Placeholder: CustomPlaceholderIndusrty }}

                />
                <div className="checkbox-group mt-2">
                  {industriesList.map((industry, index) => (
                    <div key={index} className="form-check">
                    <label className="form-check-label" htmlFor={"industry" + index}>
                      {industry.label}
                    </label>
                    <input
                      className="form-check-input form_check_box label-filter"
                      id={"industry" + index}
                      type="checkbox"
                      checked={selectedIndustries.includes(industry.value)}
                      onChange={() => handleIndustryCheckboxChange(industry.value)}
                    />
                  </div>
                  ))}
                </div>
              </>
            )}
          </Form.Group>
          <Form.Group className="mb-3" controlId="companyNameFilter">
            <Row>
              <Col xs={11}>
                <Form.Label className="location_filter">Company Name</Form.Label>
              </Col>
              <Col xs={1}>
                <img className={showSections.userNameCard ? "img-reverse" : ""} src="./images/dropDownUp.svg" alt="dropdown" style={{ marginLeft: "-15px" }} onClick={() => handleToggleSection('userNameCard')} />
               
              </Col>
            </Row>
            {showSections.userNameCard && (
              <>
                <Select
                  className="location_dropdwon"
                  options={companyNamesList}
                  isSearchable
                  placeholder="e.g. Google"
                  onChange={handleCompanyNameChange}
                  isMulti
                  value={companyNamesList.filter(companyName => selectedCompanyNames.includes(companyName.value))}
                  components={{ Placeholder: CompanyPlaceholder }}

                />
                <div className="checkbox-group mt-2 ">
                  {companyNamesList.map((companyName, index) => (
                    <div key={index} className="form-check">
                      <label className="form-check-label" htmlFor={"companyName" + index}>
                        {companyName.label}
                      </label>
                      <input
                        className="form-check-input form_check_box label-filter"
                        id={"companyName" + index}
                        type="checkbox"
                        checked={selectedCompanyNames.includes(companyName.value)}
                        onChange={() => handleCompanyNameCheckboxChange(companyName.value)}
                      />
                    </div>
                  ))}
                </div>
              </>
            )}
          </Form.Group>
        </Form>
      </Offcanvas.Body>
      <div className="filter_btn_container mt-auto mb-4 d-flex flex-row justify-content-end">
        <Button type="submit" className=" btn btn-success invite_cancel_btn" onClick={handleCancelFilters}>
          Clear
        </Button>
        <Button type="submit" className=" btn btn-success send_invite_btn" style={{ marginRight: "25px" }} onClick={handleApplyFilters}>
          Apply
        </Button>
      </div>
    </Offcanvas>
  );
};

export default FilterOffcanvas;




