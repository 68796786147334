import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { PiEyeLight, PiEyeSlash } from "react-icons/pi";
import { jwtDecode } from 'jwt-decode';
import ReactPasswordChecklist from "react-password-checklist";
import swal from 'sweetalert';
import constants from '../../../config/constants';
import { ROLES } from '../../../config/constants';

import {
  setEmail,
  setPassword,
  setConfirmPassword,
  setEmailError,
  setPasswordError,
  setConfirmPasswordError,
  setSignupError,
  setShowPasswordChecklist,
  updateCriteria,
  setRoleId,
  setCompanyId
} from '../../../Redux/Slices/UserLoginFlowSlices/SignupSlice';
import { userSignup } from '../../../Redux/Actions/Actions';


export default function Signup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    email,
    password,
    confirmPassword,
    role_id,
    company_id,
    emailError,
    passwordError,
    confirmPasswordError,
    signupError,
    showPasswordChecklist,
    criteria,
  } = useSelector((state) => state.signup);

  const { encrypted_email ,} = useParams();

  const [passwordVisible, setPasswordVisible] = React.useState(false);
  const [passwordType, setPasswordType] = React.useState("password");

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
    setPasswordType(passwordVisible ? "password" : "text");
  };

  const [confirmPasswordVisible, setConfirmPasswordVisible] = React.useState(false);
  const [confirmPasswordType, setConfirmPasswordType] = React.useState("password");

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
    setConfirmPasswordType(confirmPasswordVisible ? "password" : "text");
  };

  const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const passwordPattern = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>])/;

  async function validateAndExtract(token, secretKey) {
    if (token) {
      const decoded = jwtDecode(token, secretKey);
      const currentTime = Math.floor(Date.now() / 1000);
      const expirationTime = decoded.exp;
      return { valid: expirationTime > currentTime, email: decoded.email,role: decoded.role,company_id:decoded.company_id };
    }
    return { valid: false, email: null ,role:null,company_id:null };
  }

  useEffect(() => {
    async function checkTokenValidity() {
      const { valid, email, role,company_id } = await validateAndExtract(encrypted_email, constants.EMAIL_ACCESS_TOKEN);
      
      const role_id = Object.keys(constants.ROLES).find(key => constants.ROLES[key] === role)
  
      if (valid) {
        dispatch(setEmail(email));
        dispatch(setRoleId(role_id));
        dispatch(setCompanyId(company_id));

      } else {
        // Handle invalid token case if needed
        dispatch(setSignupError("Invalid or expired token"));
      }
    }
    checkTokenValidity();
  }, [encrypted_email, dispatch]);
  



  const validateEmail = () => {
    if (email.trim() === "") {
      dispatch(setEmailError("Enter Email Address"));
      return false;
    } else if (!emailPattern.test(email)) {
      dispatch(setEmailError("Enter Valid Email Address"));
      return false;
    } else {
      dispatch(setEmailError(""));
      return true;
    }
  };

  const validatePassword = () => {
    if (password.trim() === "") {
      dispatch(setPasswordError("Please Enter Password"));
      return false;
    } else if (password.trim().length < 8) {
      dispatch(setPasswordError("Password should be at least 8 characters"));
      return false;
    } else if (!passwordPattern.test(password)) {
      dispatch(setPasswordError("Password must contain one digit, lowercase, uppercase, and one special character"));
      return false;
    } else {
      dispatch(setPasswordError(""));
      return true;
    }
  };

  const validateConfirmPassword = () => {
    if (confirmPassword.trim() === "") {
      dispatch(setConfirmPasswordError("Confirm Password is Required"));
      return false;
    } else if (confirmPassword !== password) {
      dispatch(setConfirmPasswordError("Passwords do not match"));
      return false;
    } else {
      dispatch(setConfirmPasswordError(""));
      return true;
    }
  };

  const handleFocus = () => {
    dispatch(setShowPasswordChecklist(true));
  };

  const handleBlur = () => {
    dispatch(setShowPasswordChecklist(false));
  };

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    dispatch(setPassword(newPassword));
    dispatch(updateCriteria(newPassword));
  };

  const unmetCriteria = Object.keys(criteria).filter((key) => !criteria[key]);
  const allCriteriaMet = unmetCriteria.length === 0;

  const handleSignup = async (e) => {
    e.preventDefault();

    dispatch(setSignupError(""));
    dispatch(setEmailError(""));
    dispatch(setPasswordError(""));
    dispatch(setConfirmPasswordError(""));

    const isValidEmail = validateEmail();
    const isValidPassword = validatePassword();
    const isValidConfirmPassword = validateConfirmPassword();

    if (isValidEmail && isValidPassword && isValidConfirmPassword) {
      dispatch(userSignup({ email, password, role_id, company_id }))
        .unwrap()
        .then((data) => {
          if (data.status === 200 && data.error_code === 0 && data.message === 'User Registered') {
            swal({
              title: "Account created successfully!",
              text: "Please log in with your email and password.",
              icon: "success",
              button: "OK",
            }).then(() => {
              navigate('/');
            });
            localStorage.setItem('access_token', data.access_token);
          } else if (data.status === 200 && data.error_code === 0 && data.message === 'User already registered') {
            swal({
              title: "Account already registered!",
              text: "Please log in with your email and password.",
              icon: "warning",
              button: "OK",
            }).then(() => {
              navigate('/');
            });
            localStorage.setItem('access_token', data.access_token);
          }
        })
        .catch((error) => {
          dispatch(setSignupError(error.message));
        });
    }
   
}

  return (
    <div className='container-fluid'>
      <div className='bg_container row'>
        <div className='left_container col-12 col-md-6'>

          <p className='signup_heading'>
            Your Guide to <span className='signup_heading_highlight'>Responsible Investing and Beyond</span>
          </p>
          <p className='signup_paragraph'>
            By using ESGPilot, businesses and organisations can reduce the effort and resources required for ESG reporting, enabling them to focus more on their sustainability initiatives and less on the intricacies of report generation.
          </p>

        </div>
        <div className='right_container col-12 col-md-6 col-sm-8'>
          <div className='form_container'>
            <form className='signup-form' onSubmit={handleSignup}>
              <div className='input_box_logo'>
                <img src="./images/Vector.png" alt='vector' className='vector_image' />
                <p className='esgpilot_heading'>ESG<span className='pilot'>PILOT</span></p>
              </div>
              {signupError && (<p style={{ color: 'red', textAlign: "center" }}>{signupError}</p>)}
              <div>
                <input
                  type='email'
                  placeholder='Email Address'
                  id='email'
                  name='email'
                  value={email}
                  // onChange={(e) => dispatch(setEmail(e.target.value))}
                  disabled
                  className='input_box'
                />
              </div>
              {emailError && (<p style={{ color: 'red' }}>{emailError}</p>)}

              <div className='password_img_container'>
                <input
                  type={passwordType}
                  placeholder='Password'
                  name='password'
                  id='password'
                  value={password}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                  autoComplete='new-password'
                  onChange={(e) => handlePasswordChange(e)}
                  className='input_box hide-password'
                />
                <div>
                {passwordVisible ? <PiEyeLight className='eye_image' onClick={togglePasswordVisibility} /> : <PiEyeSlash className='eye_image' onClick={togglePasswordVisibility} />}
                </div>
                {showPasswordChecklist && !allCriteriaMet && (
                  <div className="password-checklist-card">
                    <ReactPasswordChecklist
                      rules={unmetCriteria}
                      minLength={5}
                      value={password}
                      onChange={(isValid) => { }}
                      invalidTextColor="#FF0033"
                      validTextColor="#4BCA81"
                      className="custom-checklist-styles"
                    />
                  </div>
                )}
              </div>
              {passwordError && (<p style={{ color: 'red' }}>{passwordError}</p>)}

              <div className='password_img_container'>
              <input
                  type={confirmPasswordType}
                  placeholder='Confirm Password'
                  id='confirmPassword'
                  name='confirmPassword'
                  value={confirmPassword}
                  onChange={(e) => dispatch(setConfirmPassword(e.target.value))}
                  className='input_box'
                />
                <div>
                {confirmPasswordVisible ? <PiEyeLight className='eye_image' onClick={toggleConfirmPasswordVisibility} /> : <PiEyeSlash className='eye_image' onClick={toggleConfirmPasswordVisibility} />}
                </div>
              </div>
              {confirmPasswordError && (<p style={{ color: 'red' }}>{confirmPasswordError}</p>)}

              <button type='submit' className='signup_button'>Sign Up</button>
              <p className='signup_text'>Already have an account? <span onClick={() => navigate('/')} className='login_link_sgu'>Login</span></p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
