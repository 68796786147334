import { createSlice } from '@reduxjs/toolkit';
import { resetPassword } from '../../../Redux/Actions/Actions.js';


const resetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState: {
    email: '',
    emailError: '',
    password: '',
    confirmPassword: '',
    passwordError: '',
    confirmPasswordError: '',
    showPasswordChecklist: false,
    formSubmitted: false,
    showSuccessMessage: false,
    showTokenExpiredMessage: false,
    buttonDisabled: false,
    error: null,
  },
  reducers: {
    setEmail(state, action) {
      state.email = action.payload;
    },
    setPassword(state, action) {
      state.password = action.payload;
    },
    setConfirmPassword(state, action) {
      state.confirmPassword = action.payload;
    },
    setPasswordError(state, action) {
      state.passwordError = action.payload;
    },
    setConfirmPasswordError(state, action) {
      state.confirmPasswordError = action.payload;
    },
    setShowPasswordChecklist(state, action) {
      state.showPasswordChecklist = action.payload;
    },
    setFormSubmitted(state, action) {
      state.formSubmitted = action.payload;
    },
    setShowSuccessMessage(state, action) {
      state.showSuccessMessage = action.payload;
    },
    setShowTokenExpiredMessage(state, action) {
      state.showTokenExpiredMessage = action.payload;
    },
    setButtonDisabled(state, action) {
      state.buttonDisabled = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetPassword.pending, (state) => {
        state.buttonDisabled = true;
      })
      .addCase(resetPassword.fulfilled, (state) => {
        state.buttonDisabled = false;
        state.showSuccessMessage = true;
        state.formSubmitted = true;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.buttonDisabled = false;
        state.error = action.payload;
      });
  },
});

export const {
  setEmail,
  setPassword,
  setConfirmPassword,
  setPasswordError,
  setConfirmPasswordError,
  setShowPasswordChecklist,
  setFormSubmitted,
  setShowSuccessMessage,
  setShowTokenExpiredMessage,
  setButtonDisabled,
  setError,
} = resetPasswordSlice.actions;

export default resetPasswordSlice.reducer;
