import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { PiEyeLight, PiEyeSlash } from 'react-icons/pi';
import CryptoJS from 'crypto-js';
import {
  setEmail,
  setPassword,
  setEmailError,
  setPasswordError,
  setRememberMe,
  togglePasswordVisibility,
  setRoleId,
  setLoginError,
  setIsEmailInvite,
  setUserInfo,
  setUniqueUserInfo,
  setSelectedRoleCompanyId
} from '../../../Redux/Slices/UserLoginFlowSlices/LoginSlice.js';
import { userLogin } from '../../../Redux/Actions/Actions.js';
import '../../UserLoginFlow/UserLoginFlowStyles/Login.css';
import '../../UserLoginFlow/UserLoginFlowStyles/Signup.css';


const constants = require('../../../config/constants.jsx');

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    email,
    password,
    emailError,
    passwordError,
    loginError,
    rememberMe,
    passwordVisible,
    is_email_invite,
    isLoading,
    isLoggedIn,
    role_id,   
  } = useSelector((state) => state.login);

  const { encrypted_email ,} = useParams();

  useEffect(() => {
    const storedCredentials = localStorage.getItem('credentials');
    if (storedCredentials) {
      try {
        const decryptedCredentials = decryptCredentials(storedCredentials);
        dispatch(setEmail(decryptedCredentials.email));
        dispatch(setPassword(decryptedCredentials.password));
        dispatch(setRememberMe(true));
      } catch (error) {
        console.error('Failed to decrypt stored credentials:', error);
      }
    }
  }, [dispatch]);

  useEffect(() =>{
    localStorage.clear();
  },[])

  const decryptCredentials = (encryptedCredentials) => {
    const bytes = CryptoJS.AES.decrypt(encryptedCredentials, constants.SECRET_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  };

  async function validateAndExtract(token, secretKey) {
    if (token) {
      const decoded = jwtDecode(token, secretKey);
      const currentTime = Math.floor(Date.now() / 1000);
      const expirationTime = decoded.exp;
      return { valid: expirationTime > currentTime, email: decoded.email,role: decoded.role,is_email_invite:decoded. is_email_invite};
    }
    return { valid: false, email: null ,role:null,is_email_invite:null};
  }

  const filterUniqueRoles = (objects)  =>{
    const uniqueSet = new Set();
    return objects.filter((obj) => !uniqueSet.has(obj.role_id) && uniqueSet.add(obj.role_id));
}

  useEffect(() => {
    async function checkTokenValidity() {
      const { valid, email, role,is_email_invite } = await validateAndExtract(encrypted_email, constants.EMAIL_ACCESS_TOKEN);
      const role_id = Object.keys(constants.ROLES).find(key => constants.ROLES[key] === role)
  
      if (valid) {
        dispatch(setEmail(email));
        dispatch(setRoleId(role_id));
        dispatch(setIsEmailInvite(is_email_invite))

      } else {
        if(is_email_invite === 1){
          dispatch(setLoginError("Invalid or expired token"));
        }    
      }
    }
    checkTokenValidity();
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();

    const isValidEmail = validateEmail();
    const isValidPassword = validatePassword();

    if (isValidEmail && isValidPassword) {

      try {
        const response = await dispatch(userLogin({ email, password,role_id,is_email_invite }));
        localStorage.setItem("access_token",response.payload.access_token)
        localStorage.setItem("user_info_id",response.payload.user_info.user_info_id)
        localStorage.setItem('user_email',email);
        localStorage.setItem('user_info_id',response.payload.user_info.user_info_id);
        dispatch(setUserInfo(response.payload.user_info.company_and_role));
        dispatch(setUniqueUserInfo(filterUniqueRoles(response.payload.user_info.company_and_role)))
        let company_and_role = filterUniqueRoles(response.payload.user_info.company_and_role)
        
        if (company_and_role.length > 1) {
          navigate('/selectRole', { state: { roles: company_and_role, email, is_first_time_login: response.payload.user_info.is_first_time_login === 1 } });
        }
        else
          if (company_and_role.length >= 1 && response.payload.user_info.is_first_time_login === 1) {
            localStorage.setItem("popUpData", 1)
            localStorage.setItem("selected_roleId", company_and_role[0].role_id)
            dispatch(setSelectedRoleCompanyId(company_and_role[0].company_id))
            // navigate('/home');
            navigate('/dashboard')
          }
          else {
            localStorage.setItem("selected_roleId", company_and_role[0].role_id)
            localStorage.setItem('logedInCompanyId',company_and_role[0].company_id)
            dispatch(setRoleId(company_and_role[0].role_id));
            dispatch(setSelectedRoleCompanyId(company_and_role[0].company_id))
            // navigate('/home');
            navigate('/dashboard')
          }
        if (rememberMe) {
          localStorage.setItem(
            'credentials',
            encryptCredentials({ email, password })
          );
        } else {
          localStorage.removeItem('credentials');
        }
      }        
      catch (error) {
        console.log('Error logging in:', error);
        dispatch(setLoginError('Failed to login. Please try again.'));
      }
    }
  };


  const encryptCredentials = (credentials) => {
    return CryptoJS.AES.encrypt(
      JSON.stringify(credentials),
      constants.SECRET_KEY
    ).toString();
  };

  const validateEmail = () => {
    if (email.trim() === '') {
      dispatch(setEmailError('Enter Email Address'));
      return false;
    } else if (!emailPattern.test(email)) {
      dispatch(setEmailError('Enter Valid Email Address'));
      return false;
    } else {
      dispatch(setEmailError(''));
      return true;
    }
  };

  const validatePassword = () => {
    if (password.trim() === '') {
      dispatch(setPasswordError('Enter Password'));
      return false;
    } else {
      dispatch(setPasswordError(''));
      return true;
    }
  };

  const emailPattern =
    /^(([^<>()[\]\\.,;:@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z\s]{2,}))$/;

  return (
    <div className="container-fluid px-0">
      <div className="bg_container_image">
        <div className="row px-0 mx-0">
          <div className="left_container_login col-12 col-md-6">
            <div>
              <p className="login_heading">
                Reduce <span className="login_heading_highlight">90% </span> of
                your <br />
                time and effort
              </p>
              <p className="login_paragraph">
                By using ESGPilot, businesses and organizations can reduce the
                effort and resources required for ESG reporting, enabling them
                to focus more on their sustainability initiatives and less on
                the intricacies of report generation. <br />
                <br />
                With Smart ESG AI, you can rapidly extract the relevant ESG
                mandatory fields, identify gaps in your ESG reporting, and
                generate stunning reports.
              </p>
            </div>
          </div>
          <div className="right_container_login col-12 col-md-6">
            <div className="form_container_login">
              <form className="login-form" onSubmit={handleLogin}>
                <div className="input_box_logo">
                  <img
                    src="./images/Vector.png"
                    alt="vector"
                    className="vector_image img-fluid"
                  />
                  <p className="esgpilot_heading">
                    ESG<span className="pilot">PILOT</span>
                  </p>
                </div>
                {loginError && (
                  <p style={{ color: 'red', textAlign: 'center' }}>
                    {loginError}
                  </p>
                )}
                <div>
                  <input
                    type="email"
                    placeholder="Email Address"
                    id="email"
                    value={email}
                    onChange={(e) => dispatch(setEmail(e.target.value))}
                    className="input_box"
                    disabled={is_email_invite === 1}
                  />
                </div>
                {emailError && <p style={{ color: 'red' }}>{emailError}</p>}
                <div className="password_img_container">
                  <input
                    type={passwordVisible ? 'text' : 'password'}
                    placeholder="Password"
                    id="password"
                    value={password}
                    onChange={(e) => dispatch(setPassword(e.target.value))}
                    className="input_box"
                  />
                  <div>
                    {passwordVisible ? (
                      <PiEyeLight
                        className="eye_image"
                        onClick={() => dispatch(togglePasswordVisibility())}
                      />
                    ) : (
                      <PiEyeSlash
                        className="eye_image"
                        onClick={() => dispatch(togglePasswordVisibility())}
                      />
                    )}
                  </div>
                </div>
                {passwordError && (
                  <p style={{ color: 'red' }}>{passwordError}</p>
                )}
                <div className="checkbox_container">
                  <div>
                    <label className="remember_me_text">
                      <input
                        type="checkbox"
                        checked={rememberMe}
                        onChange={(e) =>
                          dispatch(setRememberMe(e.target.checked))
                        }
                        className="checkbox_style"
                        id="remember_checkbox"
                      />
                      Remember me
                    </label>
                  </div>
                  <div>
                    <span
                      onClick={() => navigate('/forgotPassword')}
                      className="forget_password_text"
                    >
                      Forgot your password?
                    </span>
                  </div>
                </div>
                <button type="submit" className="login_button">
                  Login
                </button>
                <p className="signup_text">
                  {/* Don't have an account?{' '}
                  <span
                    onClick={() => navigate('/signup')}
                    className="login_link_sgu"
                  >
                    Sign Up
                  </span> */}
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}




