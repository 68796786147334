import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import CryptoJS from 'crypto-js';
import { selectLetsGetStartedFormData } from '../../Redux/Slices/popupCardSlices/letsGetStartedSlice.js';
const constants = require('../../config/constants.jsx');



export const userSignup = createAsyncThunk('/users/register_user', async (obj) => {
  let role = parseInt(obj.role_id)
  try {
    const encryptedEmail = CryptoJS.AES.encrypt(obj.email.trim(), constants.SECRET_KEY).toString();
    const encryptedPassword = CryptoJS.AES.encrypt(obj.password.trim(), constants.SECRET_KEY).toString();

    const reqObj = { email: encryptedEmail, password: encryptedPassword, role_id: role, company_id: obj.company_id?.trim() };
    const response = await axios.post(`${constants.BACKEND_SERVER_BASEURL}/users/register_user`, reqObj);
    return response.data;
  } catch (error) {
    throw Error('Failed to fetch data');
  }
});


export const userLogin = createAsyncThunk('/users/login_user', async (obj) => {
  try {
    const encryptedEmail = CryptoJS.AES.encrypt(obj.email.trim(), constants.SECRET_KEY).toString();
    const encryptedPassword = CryptoJS.AES.encrypt(obj.password.trim(), constants.SECRET_KEY).toString();
    let reqObj
    if (obj.is_email_invite === 1) {
      reqObj = { email: encryptedEmail, password: encryptedPassword, role_id: obj.role_id, is_email_invite: obj.is_email_invite };

    } else {
      reqObj = { email: encryptedEmail, password: encryptedPassword, };

    }
    const response = await axios.post(`${constants.BACKEND_SERVER_BASEURL}/users/login_user`, reqObj);

    return response.data;
  } catch (error) {
    throw Error('Failed to fetch data');
  }
});

export const updateUserProfile = createAsyncThunk(
  'user/updateUserProfile',
  async (_, thunkAPI) => {
    try {
      const formData = selectLetsGetStartedFormData(thunkAPI.getState());
      const encryptedEmail = CryptoJS.AES.encrypt(formData.email.trim(), constants.SECRET_KEY).toString();
      const mergedFormData = new FormData();
      console.log(formData.phoneNumber,"formdata in actions")
      const appendIfNotEmpty = (key, value) => {
        if (value) {
          if (key == 'file') {
            mergedFormData.append(key, value);
          } else {
            mergedFormData.append(key, value.trim().replace(/\s+/g, ' '));
          }
        }
      };

      formData.firstName.trim() !== '' && appendIfNotEmpty('first_name', formData.firstName);
      formData.lastName.trim() !== '' && appendIfNotEmpty('last_name', formData.lastName);
      encryptedEmail.trim() !== '' && appendIfNotEmpty('email', encryptedEmail);
      formData.designation.trim() !== '' && appendIfNotEmpty('designation', formData.designation);
      formData.department.trim() !== '' && appendIfNotEmpty('department', formData.department.trim());
      formData.country?.label !== undefined && appendIfNotEmpty('country', formData.country?.label);
      formData.city?.label !== undefined && appendIfNotEmpty('city', formData.city?.label);
      formData.State?.label !== undefined && appendIfNotEmpty('state', formData.State?.label);
      formData.zipcode.trim() !== '' && appendIfNotEmpty('zipcode', formData.zipcode.trim());
      formData.phoneNumber.trim() !== '' && appendIfNotEmpty('phone_number', formData.phoneNumber.trim());
      formData.timeZone?.label !== undefined && appendIfNotEmpty('time_zone', formData.timeZone?.label);
      typeof(formData.profileImage) == 'object' && appendIfNotEmpty('file', formData.profileImage)
      const response = await axios.post(
        `${constants.BACKEND_SERVER_BASEURL}/users/update_user_profile`,
        mergedFormData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
      return response;
    } catch (error) {
      throw new Error('Failed to update user profile');
    }
  }
);

export const sendPasswordResetEmail = createAsyncThunk('users/forgotPassword', async (email) => {
  try {
    const encryptedEmail = CryptoJS.AES.encrypt(email.trim(), constants.SECRET_KEY).toString();
    const response = await axios.post(`${constants.BACKEND_SERVER_BASEURL}/users/forgot_password`, { email: encryptedEmail });
    return response.data;
  } catch (error) {
    if (error.response && error.response.data.message === "Please enter a valid registered email") {
      throw new Error("Please enter your email associated with your account");
    } else {
      throw new Error("Something went wrong! Please try again.");
    }
  }
});


export const resetPassword = createAsyncThunk('users/reset_password', async ({ email, password, token }, { rejectWithValue }) => {
  try {
    const encryptedPassword = CryptoJS.AES.encrypt(password, constants.SECRET_KEY).toString();
    const encryptedEmail = CryptoJS.AES.encrypt(email, constants.SECRET_KEY).toString();
    const response = await axios.post(`${constants.BACKEND_SERVER_BASEURL}/users/reset_password`, { email: encryptedEmail, password: encryptedPassword, token });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data.message);
  }
}
);

export const fetchCompanies = createAsyncThunk(
  'companies/fetchCompanies',
  async ({ user_info_id, role_id }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${constants.BACKEND_SERVER_BASEURL}/companies/get_list_of_companies`, {
        user_info_id,
        role_id,
      });
      return response.data.companies; // Ensure this line matches the structure of your response
    } catch (error) {
      return rejectWithValue('Failed to fetch companies');
    }
  }
);

export const removeCompany = createAsyncThunk('companies/removeCompany', async (companyId, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${constants.BACKEND_SERVER_BASEURL}/companies/remove_company`, {
      company_id: companyId,
    });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data.message);
  }
});


export const addOrUpdateCompany = createAsyncThunk('companies/addOrUpdateCompany',
  async ({ companyData, isEdit }, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append('company_name', companyData.company_name);
      formData.append('industry', companyData.industry);
      formData.append(
        'company_email',
        CryptoJS.AES.encrypt(companyData.company_email.trim(), constants.SECRET_KEY).toString()
      );

      if (isEdit) {
        formData.append('company_id', companyData.company_id);
      }

      if (companyData.company_phone_number) formData.append('company_phone_number', companyData.company_phone_number);
      if (companyData.total_employees) formData.append('total_employees', companyData.total_employees);
      if (companyData.total_departments) formData.append('total_departments', companyData.total_departments);
      if (companyData.company_address) formData.append('company_address', companyData.company_address);
      if (companyData.company_city) formData.append('company_city', companyData.company_city);
      if (companyData.company_postal_code) formData.append('company_postal_code', companyData.company_postal_code);
      if (companyData.hq_country) formData.append('hq_country', companyData.hq_country);
      if (companyData.company_logo && (typeof (companyData.company_logo) != "string")) formData.append('file', companyData.company_logo);

      if (!isEdit) {
        formData.append('admin_user_id', localStorage.getItem('user_info_id'));
        formData.append('logged_in_role_id', localStorage.getItem('selected_roleId'));
      }

      const url = isEdit
        ? `${constants.BACKEND_SERVER_BASEURL}/companies/update_company_data`
        : `${constants.BACKEND_SERVER_BASEURL}/companies/add_company`;

      const response = await axios.post(url, formData);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message || `Failed to ${isEdit ? 'update' : 'add'} company`);
    }
  }
);

export const fetchUserProfile = createAsyncThunk(
  'userProfile/fetchUserProfile', async ({ _id }, { rejectWithValue }) => {
    let postParameters;
    try {
      if (_id.user_info_id) {
        postParameters = { user_info_id: _id.user_info_id, is_pending_invite: 0 }
      } else {
        postParameters = { user_id: _id.user_id, is_pending_invite: 1 }
      }
      const response = await axios.post(`${constants.BACKEND_SERVER_BASEURL}/users/user_profile`,
        postParameters

      );

      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const fetchDocuments = createAsyncThunk('documents/fetchDocuments', async (company_id, { rejectWithValue }) => {

  try {
    const response = await axios.post(`${constants.BACKEND_SERVER_BASEURL}/documents/get_all_documents_by_company_id`, {
      company_id: company_id,
    });
    return response.data.result;
  } catch (error) {
    return rejectWithValue(error.response.data.message);
  }
}
);

// Delete Document
export const deleteDocument = createAsyncThunk('documents/deleteDocument', async ({ doc_id, user_info_id }, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${constants.BACKEND_SERVER_BASEURL}/documents/delete_document`, {
      doc_id, user_info_id
    });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data.message);
  }
});

// Rename Document
export const renameDocument = createAsyncThunk('documents/renameDocument', async ({ doc_id, new_name, user_info_id }, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${constants.BACKEND_SERVER_BASEURL}/documents/rename_document`, {
      doc_id,
      new_file_name: new_name,
      user_info_id
    });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data.message);
  }
});

// Download Document
export const downloadDocument = createAsyncThunk('documents/downloadDocument', async ({ doc_id, user_info_id }, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${constants.BACKEND_SERVER_BASEURL}/documents/download_document`, {
      doc_id,
      user_info_id
    }, { responseType: 'blob' });

    // Extract filename from Content-Disposition header
    const contentDisposition = response.headers.get('content-disposition');
    let filename;

    if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
      const match = contentDisposition.match(/filename="?(.+)"?/);
      if (match && match[1]) {
        filename = match[1];
        filename = filename.replace(/["]+$/, '');
      }
    }
    const blob = new Blob([response.data], { type: response.headers['content-type'] });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(url);

  } catch (error) {
    return rejectWithValue(error.response.data.message);
  }
});

// Replace Document
export const replaceDocument = createAsyncThunk('documents/replaceDocument', async ({ doc_id, file, user_info_id }, { rejectWithValue }) => {
  const formData = new FormData();
  formData.append('doc_id', doc_id);
  formData.append('file', file);
  formData.append('user_info_id', user_info_id)
  try {
    const response = await axios.post(`${constants.BACKEND_SERVER_BASEURL}/documents/replace_document`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data.message);
  }
});






export const fetchCompanyActivities = createAsyncThunk('companyActivities/activities/get_all_activities', async (companyId) => {
  try {
    const response = await axios.post(`${constants.BACKEND_SERVER_BASEURL}/activities/get_all_activities`, {
      company_id: companyId,
    });
    return response.data.Activities; // Ensure this line matches the structure of your response
  } catch (error) {
    throw Error('Failed to fetch company activities');
  }
});






export const fetchCompanyUsers = createAsyncThunk(
  'companyUsers/fetchCompanyUsers',
  async (company_id) => {
    try {
      const response = await axios.post(`${constants.BACKEND_SERVER_BASEURL}/users/get_client_users_list`, {
        company_id,
      });
      return response.data;
    } catch (error) {
      throw Error('Failed to fetch company users');
    }
  }
);

export const selectCompanyUsers = (state) => state.companyUsers.users;




export const sendInvitations = createAsyncThunk(
  'inviteUser/sendInvitations',
  async ({ invites, company_id, company_name, admin_user_id }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${constants.BACKEND_SERVER_BASEURL}/users/invite_user`, {
        invites,
        company_id,
        company_name,
        admin_user_id
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDashboardData = createAsyncThunk('companyActivities/users/user_companies_reports', async ({ user_id, role_id }) => {
  try {
    const response = await axios.post(`${constants.BACKEND_SERVER_BASEURL}/users/user_companies_reports`, {
      "user_info_id": user_id,
      "role_id": role_id
    });
    return response.data.CompaniesData; // Ensure this line matches the structure of your response
  } catch (error) {
    throw Error('Failed to fetch company activities');
  }
});



export const resendInvite = createAsyncThunk('companyUsers/resendInvite', async ({ invites, company_id, company_name, admin_user_id }, { rejectWithValue }) => {
  try {
    const response = await axios.post(`${constants.BACKEND_SERVER_BASEURL}/users/invite_user`, {
      invites,
      company_id,
      company_name,
      admin_user_id
    });
    return response.data;
  } catch (error) {
    return rejectWithValue('Failed to resend invite');
  }
});

export const removeUser = createAsyncThunk(
  'companyUsers/removeUser',
  async (postParameters, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${constants.BACKEND_SERVER_BASEURL}/users/remove_user`, postParameters);
      return response.data.status;
    } catch (error) {
      return rejectWithValue('Failed to remove user');
    }
  }
);

export const fetchUsersByCompanyId = createAsyncThunk(
  "users/fetchUsersByCompanyId",
  async (companyId, { rejectWithValue }) => {
    try {
      let postParameters =
        localStorage.getItem("selected_roleId") == 1
          ? { logged_in_role_id: localStorage.getItem("selected_roleId") }
          : { logged_in_company_id: companyId,logged_in_role_id: localStorage.getItem("selected_roleId") };
      const response = await axios.post(
        `${constants.BACKEND_SERVER_BASEURL}/users/fetch_users_by_logged_id_company_id`,
        postParameters
      );
      return response.data.userprofiles;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const changeUserRole = createAsyncThunk(
  'userProfile/changeUserRole',
  async ({ loggedInUserInfoId, loggedInRoleId, userInfoId, currentRoleId, roleRequested, companyId }, thunkAPI) => {
    try {
      const response = await axios.post(`${constants.BACKEND_SERVER_BASEURL}/users/role_change`, {
        logged_in_user_info_id: loggedInUserInfoId,
        logged_in_role_id: loggedInRoleId,
        user_info_id: userInfoId,
        current_role_id: currentRoleId,
        role_requested: roleRequested,
        company_id: companyId
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);


export const removeUserAssociatedCompanies = createAsyncThunk(
  "users/removeUserAssociatedCompanies",
  async (postParameters, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `${constants.BACKEND_SERVER_BASEURL}/users/remove_user_associated_companies`,
        postParameters
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response.data.message || "Failed to remove user"
      );
    }
  }
);