import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateFormData, selectLetsGetStartedFormData } from '../../Redux/Slices/popupCardSlices/letsGetStartedSlice';

export default function LetsGetStarted({ showModal, onAction }) {
  const dispatch = useDispatch();
  const formData = useSelector(selectLetsGetStartedFormData);
  const [image, setImage] = useState('/images/default_profile.svg');


  const handleImageUpload = (e) => {
    const file = e.target.files[0];

    dispatch(updateFormData({
      profileImage: file,
    }))
    const base64Image = URL.createObjectURL(file);
    setImage(base64Image)
  };



  const handleChange = (e) => {
    const { name, value } = e.target;
    dispatch(updateFormData({ [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateFormData(formData));
    onAction();
  };

  if (!showModal) return null;

  return (
    <div className="overlay">
      <div className="container lgs-modal">
        <div className="lets_get_started_container">
          <div className="container_gap col-12">
            <p className="form_heading">Let's get you started</p>
            <p className="form_paragraph">
              This helps us determine the frameworks you have to fill in
            </p>
          </div>

          <form className="form_card" onSubmit={handleSubmit}>
            <div className="basic-info-card">
              <span className="step-text">Step 1/2</span>
              <span className="basic-info"> Basic info</span>
            </div>

            <div id="profileUploaderContainer" className="d-flex">
              <img src={image} alt="Profile" id="profileImage" className="profile-image" />
              <label htmlFor="uploadButton" className="upload-button-label">
                Upload Photo
              </label>
              <input
                type="file"
                accept="image/*"
                id="uploadButton"
                className="upload-button"
                onChange={handleImageUpload}
              />
            </div>



            <div className="input_field_container d-flex flex-wrap justify-content-between align-items-center">
              <div className="input_fields">
                <label htmlFor="firstName" className="form_label">
                  First Name
                </label>
                <input
                  type="text"
                  className="form_input_box"
                  id="firstName"
                  name="firstName"
                  autoComplete="off"
                  value={formData.firstName}
                  onChange={handleChange}
                />
              </div>
              <div className="input_fields">
                <label htmlFor="lastName" className="form_label">
                  Last Name
                </label>
                <input
                  type="text"
                  className="form_input_box"
                  id="lastName"
                  name="lastName"
                  autoComplete="off"
                  value={formData.lastName}
                  onChange={handleChange}
                />
              </div>
              <div className="input_fields">
                <label htmlFor="designation" className="form_label">
                  Designation
                </label>
                <input
                  type="text"
                  className="form_input_box"
                  id="designation"
                  name="designation"
                  autoComplete="off"
                  value={formData.designation}
                  onChange={handleChange}
                />
              </div>
              <div className="input_fields">
                <label htmlFor="department" className="form_label">
                  Department
                </label>
                <input
                  type="text"
                  className="form_input_box"
                  id="department"
                  name="department"
                  autoComplete="off"
                  value={formData.department}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="d-flex align-self-end lgs_button_container">
              <button type="submit" className="lets_get_you_started_button">
                Next
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
