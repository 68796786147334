import { createSlice } from "@reduxjs/toolkit";
import { fetchCompanyActivities } from "../../Actions/Actions";

const initialState = {
  company_activity: [],
};

const companyActivities = createSlice({
  name: "companyActivities",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanyActivities.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCompanyActivities.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.company_activity = action.payload;
      })
      .addCase(fetchCompanyActivities.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default companyActivities.reducer;
