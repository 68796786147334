/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

let constants = {
    // BACKEND_SERVER_BASEURL: 'http://localhost:3000',
    // BACKEND_SERVER_BASEURL: 'https://server.esgpilot.ai/app',
    BACKEND_SERVER_BASEURL: window.location.hostname === "localhost" ? "http://localhost:6754" : window.location.protocol + "//" + window.location.hostname + "/app",
    ESG_AI_BASE_URL: 'https://server.esgpilot.ai/esg',
    ITERATION_STATUS: { INSERTING_DATA: 0, DATA_INSERTED_SUCCESSFULLY: 1, PROCESSING_ITERATION: 2, DONE: 3 },
    ITERATION_STATUS_IDS: ['NO DATA', 'PENDING', 'PROCESS STARTED', 'DONE'],
    FRAMEWORK_IDS: { CSRD_CHECKBOX: 1, BRSR_CHECKBOX: 2, GRI_CHECKBOX: 3, CDP_CHECKBOX: 4 },
    CIPHER_KEY: 'GbDILJMEli1fSVF1sccY8cxNivkfDBzct3EG5Y4sg_0=',
    EMAIL_ACCESS_TOKEN: '123ABCabc456!@#def0987TewuwmnvmzxvWTQT)*^e2$#1b.d><XMCa.xSKDSIFSCNs',
    SECRET_KEY: 'secret',
    category_social: 'Social',
    category_environment: 'Environment',
    category_governance: 'Governance',
    bucket_total: 'TOTAL',
    subcategory_climatechange: 'Climate Change',
    ROLES: { 1: "ESG Consultant Owner", 2: "ESG Consultant", 3: "Editor", 4: "Viewer", 5: "Company Primary Admin", 6: "Workspace Admin" }, //because of consistancy we are using like this
    is_esg_consultant_owner: 1,
    is_esg_consultant: 2,
    is_editor: 3,
    is_viewer: 4,
    is_company_primary_admin: 5,
    is_workspace_admin: 6,
    our_organization: "Our Organization"
    
}
module.exports = Object.freeze(constants);

