import React, { useContext, useEffect, useState } from 'react';
import Card from '../Reusable/ReusableComponents/card';
import { customStyles } from '../Reusable/ReusableComponents/TopNavbar';
import Select from "react-select";
import { useDispatch, useSelector } from 'react-redux';
import './Timelines/timeline.css'
import moment from 'moment';
import { fetchCompanyActivities, fetchDashboardData } from '../Redux/Actions/Actions';
import NavbarContext from '../Reusable/ReusableComponents/NavbarContext';
import CircularProgressBar from '../Reusable/ReusableComponents/CircularProgressbar';
import Loader from '../config/Loader';
import PopupModal from './PopUpCards/PopupModal';

const task_data = [{
    task: "Conduct regular environmental audits",
    owner: "Ralph Edwards",
    due_date: "Apr 30, 2024",
    status: "Inprogress"
},
{
    task: "Stakeholder engagement activities",
    owner: "Martinez",
    due_date: "May 01, 2024",
    status: "To do"
},
{
    task: "Implement anti-corruption measures",
    owner: "Chloe Duncan",
    due_date: "Apr 28, 2024",
    status: "To do"
},
{
    task: "ESG ratings and rankings assessments",
    owner: "Ralph Edwards",
    due_date: "May 15, 2024",
    status: "Done"
}, {
    task: "Publish annual sustainability report",
    owner: "Martinez",
    due_date: "Apr 30, 2024",
    status: "Inprogress"
}]

const Dashboard = () => {
    const dispatch = useDispatch();
    const user_id = localStorage.getItem('user_info_id');
    const role_id = localStorage.getItem('selected_roleId');
    let [activities, setActivities] = useState([])
    let [companies, setCompanies] = useState()
    let { company } = useContext(NavbarContext)
    let activity
    let [frame_work, setFramework] = useState([])
    const [newlog,setNewlog]=useState(false)

    let colors = ['#00A656', '#EA580C', '#7E22CE', '#23719F']
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
           
            try {
                if(company?.value){
                    activity = await dispatch(fetchCompanyActivities(company?.value))
                setActivities(activity.payload)
                }
               

            } catch (err) {
                console.log(err)
            }
        };
        if (company)
            fetchData();
    }, [dispatch, company]);
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                let company_details = await dispatch(fetchDashboardData({ user_id, role_id }))
                company_details?.payload?.map(data_item => {
                    if (data_item.company_name == company.label) {
                        setFramework(data_item[data_item.frame_work])
                    }else{
                        setFramework({})
                    }
                })
                setCompanies(company_details?.payload)
                setLoading(false)
            } catch (err) {
                console.log(err)
            }
        };
        fetchData();
    }, [dispatch, company])


  useEffect(()=>{
    let user=localStorage.getItem("popUpData")
    if(user==1){
      setNewlog(true)
    }
  })
    return (
       <> 
        {newlog ?  <PopupModal/>:<>
       {loading ? 
            <Loader /> 
           : 
        <>
            <div className='home-container pt-5 '>
                {companies?.length > 0  && !loading?<>
                    <div className='container p-0 pt-5'>

                        <div className='d-flex justify-content-between'>
                            <div className=' col-lg-4 p-3'><h5>Dashboard Overview</h5></div>
                            <div className=' col-lg-1'>
                                <select className="form-select w-auto">
                                    <option>2023-2024</option>
                                </select>

                            </div>
                        </div>
                        {
                            company.label == "All Companies" ? <>
                                <div className='scrollable-container'>

                                    {
                                        companies.map(data_item => {
                                            return (
                                                <>  
                                                 {
                                                    typeof(data_item[data_item.frame_work]) == 'object' &&
                                                    <Card data_item={data_item} />
                                                 }
                                                </>
                                            )
                                        })
                                    } </div></> : <>
                                <div className='p-4 d-flex justify-content-between'>
                                    <div className='w-25'>
                                        <Select
                                            value={{ label: "BRSR", value: "BRSR" }}
                                            options={[{ label: "BRSR", value: "BRSR" }]}
                                            className='select_input_box'
                                            id="company"
                                            styles={customStyles}
                                            // classNamePrefix="react-select"
                                            placeholder="Select Framework"
                                        // onChange={getCompany}
                                        />
                                    </div>
                                    <div >

                                        <button className='edit_button flex-end'>
                                            Edit
                                        </button>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <button className='export_button flex-end'>
                                            Export
                                        </button>


                                    </div>
                                </div>
                                <div className='container d-flex'>
{JSON.stringify(frame_work) !== '{}' && <>
                                    {
                                        Object.keys(frame_work).map((key, index) => (
                                            <div className='card justify-content-center align-items-center pt-3'>
                                                <h5>{key}</h5>
                                                <CircularProgressBar percentage={frame_work[key]} colour={colors[index]} textColor={colors[index]} />
                                            </div>
                                            // <div key={key}>
                                            //   {key}: {frame_work[key]}
                                            // </div>
                                        ))
                                    }</>
                                   }
                                </div>
                            </>
                        }

                        <div className='d-flex justify-content-between'>
                            <div className=' col-lg-6'>
                                <div className='card w-100 mt-0'>
                                    <div className="d-flex w-100 justify-content-between">
                                        <div className="mr-auto p-2"><h5>Tasks Summary</h5></div>
                                        <div className="p-2"><button className='p-2 reports_export_button'>View All</button></div>
                                    </div>
                                    <table className="task-table">
                                        <thead>
                                            <tr>
                                                <th>Task</th>
                                                <th>Owner</th>
                                                <th>Due date</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {task_data.map((task, index) => (
                                                <tr key={index}>
                                                    <td>{task.task}</td>
                                                    <td>{task.owner}</td>
                                                    <td>{task.due_date}</td>
                                                    <td><span className={`status ${task.status.toLowerCase().replace(' ', '-')}`}>{task.status}</span></td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className=' col-lg-6'>
                                <>

                                    {activities?.length > 0 ? (
                                        <div className="card_comapny_details w-100 mt-0">
                                            <div className="d-flex w-100 justify-content-between">
                                                <div className="mr-auto p-2"><h5>Activity Log</h5></div>
                                                <div className="p-2"><button className='p-2 reports_export_button'>View All</button></div>
                                            </div>
                                            <div className='timeline mt-0'>

                                                {activities?.map((activity, index) => (
                                                    <div className="timeline-item" key={activity?.activity_id}>
                                                        <div className="timeline-item-content">
                                                            <div className="timeline-item-header">
                                                                {/* {activity.user_name} -{" "} */}
                                                                {moment(activity?.created_datetime).format("D MMM YYYY, h:mm A")}
                                                            </div>
                                                            {activity?.activities?.map((active, idx) => (
                                                                <div className="extra-timeline-info" key={idx}>
                                                                    <div className="timeline-event">
                                                                        <h5 className="activity_heading">
                                                                            <img
                                                                                src="./images/pointer_image.svg"
                                                                                alt=""
                                                                                className="pointer_image"
                                                                            />
                                                                            <span>{active?.activity_name}</span>
                                                                        </h5>
                                                                        <p className="grey">{active?.activity_description}</p>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ) : <>
                                        <div className="card_comapny_details justify-content-center align-items-center w-100 h-100 mt-0 ">

                                            <h5>No activities</h5>


                                        </div>
                                    </>}
                                </>
                            </div>
                        </div>
                    </div></> : <>

                    <div className="container centered-image-container">
                        {company.label == "All Companies" ? <>
                            <div className="centered-image d-flex flex-column justify-content-center align-items-center">
                                <img src="./images/all_companies_no_data.svg" alt="no_data_image"></img>
                                <div className="pt-2 d-flex flex-column justify-content-center align-items-center" >
                                    <h6>No Companies Available</h6>
                                    {role_id == 1 ? <p>Add companies to your profile</p> : <p>Please check with your ESG Consultant </p>}
                                    {role_id == 1 && <button className='w-100 p-2 reports_export_button'>Add Company</button>}

                                </div>
                            </div>
                        </> : <>
                            <div className="centered-image d-flex flex-column justify-content-center align-items-center">
                                <img src="./images/fillesImage.svg" alt="file" />
                                <div className="pt-2 d-flex flex-column justify-content-center align-items-center" >
                                    <h6>No Documents Available</h6>
                                    <button className='p-2 reports_export_button'>Request Documents</button>
                                    <p className='w-50'>If the documents are provided by other means of communication, you can upload documents manually here.</p>
                                    <p className='upload_documents pt-0'>Upload Documents</p>
                                </div>
                            </div></>}




                    </div>

                </>}



            </div>
        </>
                        }</>}</>
                    
    )
}
export default Dashboard;