// companiesSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { addOrUpdateCompany,fetchCompanies } from '../../Actions/Actions';


const initialState = {
  companies: [],
  isLoading: false,
  error: null,
};

const companiesSlice = createSlice({
  name: 'addcompanies',
  initialState,
  reducers: {
    setCompanies: (state, action) => {
        state.companies = action.payload;
      },
      setShowOffcanvas: (state, action) => {
        state.showOffcanvas = action.payload;
      },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanies.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchCompanies.fulfilled, (state, action) => {
        state.isLoading = false;
        state.companies = action.payload;
      })
      .addCase(fetchCompanies.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      })
      .addCase(addOrUpdateCompany.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(addOrUpdateCompany.fulfilled, (state, action) => {
        state.isLoading = false;
       
      })
      .addCase(addOrUpdateCompany.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
     
      });
  },
});

export const selectCompanies = (state) => state.companies.companies;
export const selectIsLoading = (state) => state.companies.isLoading;
export const selectError = (state) => state.companies.error;

export default companiesSlice.reducer;

