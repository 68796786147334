import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ReactPasswordChecklist from 'react-password-checklist';
import { PiEyeLight, PiEyeSlash } from 'react-icons/pi';
import { FaArrowLeft } from 'react-icons/fa6';
import { jwtDecode } from 'jwt-decode';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CryptoJS from 'crypto-js';

// Other imports
import NavbarContext from '../../../Reusable/ReusableComponents/NavbarContext.jsx';
import { resetPassword } from '../../../Redux/Actions/Actions.js';
import {
  setEmail,
  setPassword,
  setConfirmPassword,
  setPasswordError,
  setConfirmPasswordError,
  setShowPasswordChecklist,
  setFormSubmitted,
  setShowSuccessMessage,
  setShowTokenExpiredMessage,
  setButtonDisabled,
} from '../../../Redux/Slices/UserLoginFlowSlices/ResetpasswordSlice.js';
const constants = require('../../../config/constants.jsx');

// Component definition
export default function ResetPassword() {
  const { setIsLoginPage } = useContext(NavbarContext);
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    email,
    password,
    confirmPassword,
    passwordError,
    confirmPasswordError,
    showPasswordChecklist,
    formSubmitted,
    showSuccessMessage,
    showTokenExpiredMessage,
    buttonDisabled,
    error,
  } = useSelector((state) => state.resetPassword);

  const { access_token } = useParams();

  const loginClick = () => {
    navigate('/');
    setIsLoginPage(true);
  };

  const ForgotPasswordClick = () => {
    navigate('/ForgotPassword');
    setIsLoginPage(false);
  };

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [passwordType, setPasswordType] = useState('password');

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
    setPasswordType(passwordVisible ? 'password' : 'text');
  };

  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [confirmPasswordType, setConfirmPasswordType] = useState('password');

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
    setConfirmPasswordType(confirmPasswordVisible ? 'password' : 'text');
  };

  const passwordPattern = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*(),.?":{}|<>])/;

  async function validateAndExtract(token, secretKey) {
    if (token) {
      const decoded = jwtDecode(token, secretKey);
      const currentTime = Math.floor(Date.now() / 1000);
      const expirationTime = decoded.exp;
      return { valid: expirationTime > currentTime, email: decoded.data };
    }
    return { valid: false, email: null };
  }

  useEffect(() => {
    async function checkTokenValidity() {
      const { valid, email } = await validateAndExtract(access_token, constants.EMAIL_ACCESS_TOKEN);
      if (!valid) {
        dispatch(setShowTokenExpiredMessage(true));
        dispatch(setEmail(email));
      } else {
        dispatch(setEmail(email));
      }
    }
    checkTokenValidity();
  }, [access_token, dispatch]);

  const handleFocus = () => {
    dispatch(setShowPasswordChecklist(true));
  };

  const handleBlur = () => {
    dispatch(setShowPasswordChecklist(false));
  };

  const handleSignup = async (e) => {
    e.preventDefault();

    let passwordError = '';
    let confirmPasswordError = '';

    if (password.trim() === '') {
      passwordError = 'Password is required';
    } else if (password.trim().length < 8) {
      passwordError = 'Password should be at least 8 characters';
    } else if (!passwordPattern.test(password)) {
      passwordError = 'Password must contain at least one digit, one lowercase, one uppercase, and one special character';
    }

    if (confirmPassword.trim() === '') {
      confirmPasswordError = 'Password is required';
    } else if (confirmPassword !== password) {
      confirmPasswordError = 'Passwords do not match';
    }

    dispatch(setPasswordError(passwordError));
    dispatch(setConfirmPasswordError(confirmPasswordError));

    if (!passwordError && !confirmPasswordError) {
      dispatch(resetPassword({ email, password, token: access_token }));
    } else {
      toast.dismiss();
    }
  };

  return (
    <div className="container-fluid">
      <div className="bg_container row">
        <div className="left_container col-12 col-md-6">
          <div>
            <p className="login_heading_fpw">
              Reduce <span className="login_heading_highlight_fpw">90% </span> of your <br />time and effort
            </p>
            <p className="login_paragraph_fpw">
              By using ESGPilot, businesses and organizations can reduce the effort and resources required for ESG reporting, enabling them to focus more on their sustainability initiatives and less on the intricacies of report generation.
              <br />
              <br />
              With Smart ESG AI, you can rapidly extract the relevant ESG mandatory fields, identify gaps in your ESG reporting, and generate stunning reports.
            </p>
          </div>
        </div>
        {showTokenExpiredMessage ? (
          <div className="right_container col-12 col-md-6">
            <div className="form_container_email_sent d-flex justify-content-center align-items-center">
              <div>
                <p className="Forget_password email_sent">Link Expired!</p>
                <p className="forget_password_content email_sent">Your link has expired. Please request a new password reset link.</p>
                <button className="signup_button" onClick={ForgotPasswordClick}>
                  Back To Forgot Password
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="right_container col-12 col-md-6">
            {formSubmitted && showSuccessMessage ? (
              <div className="form_container_pw_success d-flex justify-content-center align-items-center">
                <div>
                  <p className="Forget_password email_sent">Password Updated Successfully!</p>
                  <p className="forget_password_content email_sent">Your password has been updated successfully.</p>
                  <button className="back_to_login" onClick={loginClick}>
                    <FaArrowLeft className="back_arrow" />Back To Login
                  </button>
                </div>
              </div>
            ) : (
              <div className="form_container_reset_pw">
                <form className="signup-form" onSubmit={handleSignup}>
                  <div className="">
                    <p className="new_password">Create New Password</p>
                  </div>

                  <div>
                    <input
                      type='email'
                      placeholder='Email Address'
                      id='email'
                      name='email'
                      value={email}
                      disabled
                      className='input_box'
                    />
                  </div>

                  <div className="password_img_container">
                    <input
                      type={passwordType}
                      placeholder="New Password"
                      name="password"
                      id="password"
                      autoComplete='new-password'
                      value={password}
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        dispatch(setPassword(e.target.value));
                        dispatch(setPasswordError(''));
                      }}
                      className="input_box hide-password"
                    />
                    <div>
                      {passwordVisible ? (
                        <PiEyeLight className="eye_image" onClick={togglePasswordVisibility} />
                      ) : (
                        <PiEyeSlash className="eye_image" onClick={togglePasswordVisibility} />
                      )}
                    </div>
                    {showPasswordChecklist && (
                      <div className="password-checklist-card">
                        <ReactPasswordChecklist
                          rules={['minLength', 'specialChar', 'number', 'capital']}
                          minLength={8}
                          value={password}
                          onChange={(isValid) => {}}
                          iconSize={13}
                          invalidTextColor="#FF0033"
                          validTextColor="#4BCA81"
                          className="custom-checklist-styles"
                        />
                      </div>
                    )}
                  </div>
                  {passwordError && <p style={{ color: 'red', textAlign: 'left' }}>{passwordError}</p>}

                  <div className="password_img_container">
                    <input
                      type={confirmPasswordType}
                      placeholder="Confirm Password"
                      name="confirmPassword"
                      id="confirmPassword"
                      value={confirmPassword}
                      onChange={(e) => {
                        dispatch(setConfirmPassword(e.target.value));
                        dispatch(setConfirmPasswordError(''));
                      }}
                      className="input_box hide-password"
                    />
                    <div>
                      {confirmPasswordVisible ? (
                        <PiEyeLight className="eye_image" onClick={toggleConfirmPasswordVisibility} />
                      ) : (
                        <PiEyeSlash className="eye_image" onClick={toggleConfirmPasswordVisibility} />
                      )}
                    </div>
                  </div>
                  {confirmPasswordError && <p style={{ color: 'red', textAlign: 'left' }}>{confirmPasswordError}</p>}

                  <button type="submit" className="signup_button" disabled={buttonDisabled}>
                    Submit
                  </button>
                </form>
              </div>
            )}
          </div>
        )}
        <ToastContainer
         position='top-center'
         hideProgressBar={true}
         autoClose={2000} 
        />
      </div>
    </div>
  );
}



