import { createSlice } from '@reduxjs/toolkit';
import { userLogin } from '../../Actions/Actions';

const initialState = {
  email: '',
  password: '',
  emailError: '',
  passwordError: '',
  rememberMe: false,
  passwordVisible: false,
  isLoading: false,
  isLoggedIn: false,
  role_id: '',
  loginError: '',
  is_email_invite:'',
  user_info : [],
  unique_user_info:[],
  selected_role_company_id:'',
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setEmail(state, action) {
      state.email = action.payload;
    },
    setPassword(state, action) {
      state.password = action.payload;
    },
    setEmailError(state, action) {
      state.emailError = action.payload;
    },
    setPasswordError(state, action) {
      state.passwordError = action.payload;
    },
    setRememberMe(state, action) {
      state.rememberMe = action.payload;
    },
    togglePasswordVisibility(state) {
      state.passwordVisible = !state.passwordVisible;
    },
    setRoleId(state, action) {
      state.role_id = action.payload;
    },
    setIsEmailInvite(state, action) {
      state.is_email_invite = action.payload;
    },
    setLoginError(state, action) {
      state.loginError = action.payload;
    },
    setUserInfo(state, action) {
      state.user_info = action.payload;
    },
    setUniqueUserInfo(state, action) {
      state.unique_user_info = action.payload;
    },
    setSelectedRoleCompanyId(state, action) {
      state.selected_role_company_id = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(userLogin.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(userLogin.fulfilled, (state) => {
      state.isLoading = false;
      state.isLoggedIn = true;
    });
    builder.addCase(userLogin.rejected, (state, action) => {
      state.isLoading = false;
      state.loginError = action.error.message;
    });
  },
});

export const {
  setEmail,
  setPassword,
  setEmailError,
  setPasswordError,
  setRememberMe,
  togglePasswordVisibility,
  setRoleId,
  setLoginError,
  setIsEmailInvite,
  setUserInfo,
  setUniqueUserInfo,
  setSelectedRoleCompanyId,
} = loginSlice.actions;

export default loginSlice.reducer;

