import React, { useState, useEffect } from 'react';
import axios from '../../../config/axiosConfig.jsx';
import HeaderComponent from '../../../Reusable/ReusableComponents/UserCompanyName.jsx';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../config/Loader.jsx';
import moment from 'moment-timezone';
import '../../pages/PagesStyles/home.css'
// import Table from 'react-bootstrap/Table';
const constants = require("../../../config/constants.jsx");

export default function Iterations() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [iterations, setIterations] = useState([]);
  
  const access_token = localStorage.getItem('access_token')
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  // const [activeTab, setActiveTab] = useState(
  //   localStorage.getItem("activeTab" || "home")
  // );

  // const access_token = localStorage.getItem("access_token");
  const headers = {
    Authorization: `Bearer ${access_token}`,
    "Content-Type": "application/json",
  };

  useEffect(() => {
    axios
      .get(`${constants.BACKEND_SERVER_BASEURL}/iterations/getAllIterations`, {
        headers,
      })
      .then((response) => {
        setIterations(response.data);
        setIsDataLoaded(true)
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching iterations:", error);
        setLoading(false);
      });
  }, []);

  const viewIteration = (iterationId, companyId) => {
    navigate(`/summaryReport/${iterationId}/${companyId}`);
  };

  const navigateToHome  = () => {
    // setActiveTab("home");
    // localStorage.setItem("activeTab", "home");
    // navigate("/home");
    navigate('/dashboard')
  };

  const [newlog,setNewlog]=useState(false)

  useEffect(()=>{
    let user=localStorage.getItem("popUpData")
    if(user==1){
      setNewlog(true)
    }
  })

  return (
    <div className="iterations-container">
    {newlog &&  <HeaderComponent/>}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "100px 0px 50px 0px",
        }}
      >
        {loading ? (
          <Loader />
        ) : ( isDataLoaded && iterations.length === 0 ? (
          <div className="d-flex flex-column align-items-center justify-content-center iterations_card">
            <img src="./images/fileImage.svg" alt="files" className="files_image" />
            <p className="empty-iterations-message">
              No reports available!
            </p>
            <p className="upload_doc_text">
              You need to upload documents, to view reports here.
            </p>
            <button onClick={navigateToHome } className="get_started_btn">
              Let’s Get Started
            </button>
          </div>
        ) : (
          <table
            className="table table-hover"
            style={{ maxWidth: "75%", borderRadius: "10px" }}
          >
            <thead>
              <tr className="table-active table_heading_style">
                <th>Iteration</th>
                {/* <th>Name</th> */}
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {iterations?.map((iteration) => (
                <tr className="table_body_style" key={iteration.iteration_id}>
                  <td className={`align-middle table_width`}>
                    {moment
                      .utc(iteration.inserted_date_time)
                      .local()
                      .format("DD MMM YYYY hh:mm A")}
                  </td>
                  {/* <td className={`align-middle`}>{iteration.iteration_name}</td> */}
                  <td className={`align-middle table_width_status`}>
                    {
                      constants.ITERATION_STATUS_IDS[
                        iteration.iteration_status_id
                      ]
                    }
                  </td>
                  <td className={`align-middle table_width_action`}>
                    {iteration.iteration_status_id === 3 && (
                      <button
                        className={`btn btn-outline-success table_body_style`}
                        onClick={viewIteration.bind(
                          null,
                          iteration.iteration_id,
                          iteration.company_id
                        )}
                      >
                        VIEW
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )
        )}
      </div>
    </div>
  );
}
