import React, { useState, useEffect } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from "react-bootstrap/Button";
import { CiSearch } from "react-icons/ci";
import { MdExpandLess } from "react-icons/md";

const FilterUsers = ({ show, handleClose, onApply, allUserNames, allCompanies }) => {
    const [showSections, setShowSections] = useState({
        userStatusCard: true,
        userNameCard: true,
        companyCard: true,
    });
    const [selectedFilters, setSelectedFilters] = useState({
        userStatus: [],
        userName: [],
        company: [],
    });
    const [searchName, setSearchName] = useState('');
    const [filteredNames, setFilteredNames] = useState([]);
    const [searchCompany, setSearchCompany] = useState('');
    const [filteredCompanies, setFilteredCompanies] = useState([]);

    useEffect(() => {
        if (searchName) {
            const filtered = allUserNames.filter(name => 
                name.toLowerCase().includes(searchName.toLowerCase())
            );
            setFilteredNames(filtered);
        } else {
            setFilteredNames(allUserNames);
        }
    }, [searchName, allUserNames]);

    useEffect(() => {
        if (searchCompany) {
            const filtered = allCompanies.filter(company => 
                company.toLowerCase().includes(searchCompany.toLowerCase())
            );
            setFilteredCompanies(filtered);
        } else {
            setFilteredCompanies(allCompanies);
        }
    }, [searchCompany, allCompanies]);

    const handleToggleSection = (section) => {
        setShowSections((prevState) => ({
            ...prevState,
            [section]: !prevState[section]
        }));
    };

    const handleUserStatusChange = (e) => {
        const { value, checked } = e.target;
        setSelectedFilters((prevState) => {
            const updatedStatus = checked 
                ? [...prevState.userStatus, value] 
                : prevState.userStatus.filter(status => status !== value);
            return { ...prevState, userStatus: updatedStatus };
        });
    };

    const handleUserNameChange = (e) => {
        setSearchName(e.target.value);
    };

    const handleCompanyChange = (e) => {
        setSearchCompany(e.target.value);
    };

    const handleNameSelect = (name) => {
        setSelectedFilters((prevState) => {
            const updatedNames = prevState.userName.includes(name)
                ? prevState.userName.filter(selectedName => selectedName !== name)
                : [...prevState.userName, name];
            return { ...prevState, userName: updatedNames };
        });
    };

    const handleCompanySelect = (company) => {
        setSelectedFilters((prevState) => {
            const updatedCompanies = prevState.company.includes(company)
                ? prevState.company.filter(selectedCompany => selectedCompany !== company)
                : [...prevState.company, company];
            return { ...prevState, company: updatedCompanies };
        });
    };

    const handleApply = () => {
        onApply(selectedFilters);
        handleClose();
    };

    const handleClearFilters = () => {
        setSelectedFilters({
            userStatus: [],
            userName: [],
            company: [],
        });
        setSearchName('')
        setSearchCompany('')
    };

    return (
        <Offcanvas show={show} onHide={handleClose} style={{ width: '33%' }} placement='end'>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title className='filter_User_text'>Filters</Offcanvas.Title>
            </Offcanvas.Header>

            <Offcanvas.Body className='offcanvas_body'>
                <div className='d-flex align-items-center justify-content-between role_container mb-3'>
                    <span className='role_heading'>User Status</span>
                    <MdExpandLess className={showSections.userStatusCard ? 'expand_icon' : 'expand_icon_reverse'} onClick={() => handleToggleSection('userStatusCard')} />
                </div>
                {showSections.userStatusCard && (
                    <div className='role_card d-flex flex-column'>
                        {['Active', 'Inactive'].map(status => (
                            <div key={status}>
                                <input type='checkbox' id={status} value={status} className='filter_checkbox' onChange={handleUserStatusChange} />
                                <label htmlFor={status}>{status}</label>
                            </div>
                        ))}
                    </div>
                )}

                <div className='d-flex align-items-center justify-content-between role_container mb-3'>
                    <span className='role_heading'>User Name</span>
                    <MdExpandLess className={showSections.userNameCard ? 'expand_icon' : 'expand_icon_reverse'} onClick={() => handleToggleSection('userNameCard')} />
                </div>
                {showSections.userNameCard && (
                    <div className='role_card d-flex flex-column'>
                        <div className='name_search_container'>
                            <CiSearch className='name_search_icon' />
                            <input type='search' placeholder='e.g. John' className='name_search_field' onChange={handleUserNameChange} />
                        </div>
                        <div className='name_list'>
                            {filteredNames.map(name => (
                                <div key={name} className='name_item d-flex flex-direction-column'>
                                    <input 
                                        type='checkbox' 
                                        id={name} 
                                        value={name} 
                                        className='filter_checkbox' 
                                        onChange={() => handleNameSelect(name)} 
                                        checked={selectedFilters.userName.includes(name)} 
                                    />
                                    <label htmlFor={name}>{name}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                )}

                <div className='d-flex align-items-center justify-content-between role_container mb-3'>
                    <span className='role_heading'>Company</span>
                    <MdExpandLess className={showSections.companyCard ? 'expand_icon' : 'expand_icon_reverse'} onClick={() => handleToggleSection('companyCard')} />
                </div>
                {showSections.companyCard && (
                    <div className='role_card d-flex flex-column'>
                        <div className='name_search_container'>
                            <CiSearch className='name_search_icon' />
                            <input type='search' placeholder='e.g. Company XYZ' className='name_search_field' onChange={handleCompanyChange} />
                        </div>
                        <div className='name_list'>
                            {filteredCompanies.map(company => (
                                <div key={company} className='name_item d-flex flex-direction-column'>
                                    <input 
                                        type='checkbox' 
                                        id={company} 
                                        value={company} 
                                        className='filter_checkbox' 
                                        onChange={() => handleCompanySelect(company)} 
                                        checked={selectedFilters.company.includes(company)} 
                                    />
                                    <label htmlFor={company}>{company}</label>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </Offcanvas.Body>
            <div className="filter_btn_container mt-auto mb-4">
                <Button type="button" className="invite_cancel_btn" onClick={handleClearFilters}>
                    Clear
                </Button>
                <Button type="submit" className="send_invite_btn" onClick={handleApply}>
                    Apply
                </Button>
            </div>
        </Offcanvas>
    );
}

export default FilterUsers;
