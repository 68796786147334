import { createSlice } from '@reduxjs/toolkit';
import { userSignup } from '../../../Redux/Actions/Actions.js';

const signupSlice = createSlice({
  name: 'signup',
  initialState: {
    email: '',
    password: '',
    confirmPassword: '',
    role_id: '',
    company_id: '',
    emailError: '',
    passwordError: '',
    confirmPasswordError: '',
    signupError: '',
    isSubmitting: false,
    showPasswordChecklist: false,
    criteria: {
      minLength: true,
      specialChar: true,
      number: true,
      capital: true,
    },
  },
  reducers: {
    setEmail(state, action) {
      state.email = action.payload;
    },
    setPassword(state, action) {
      state.password = action.payload;
    },
    setConfirmPassword(state, action) {
      state.confirmPassword = action.payload;
    },
    setRoleId(state, action) {
      state.role_id = action.payload;
    },
    setEmailError(state, action) {
      state.emailError = action.payload;
    },
    setPasswordError(state, action) {
      state.passwordError = action.payload;
    },
    setConfirmPasswordError(state, action) {
      state.confirmPasswordError = action.payload;
    },
    setSignupError(state, action) {
      state.signupError = action.payload;
    },
    setShowPasswordChecklist(state, action) {
      state.showPasswordChecklist = action.payload;
    },
    updateCriteria(state, action) {
      state.criteria = {
        minLength: action.payload.length >= 5,
        specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(action.payload),
        number: /\d/.test(action.payload),
        capital: /[A-Z]/.test(action.payload),
      };
    },
    setCompanyId(state,action){
      state.company_id = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(userSignup.pending, (state) => {
        state.isSubmitting = true;
      })
      .addCase(userSignup.fulfilled, (state) => {
        state.isSubmitting = false;
      })
      .addCase(userSignup.rejected, (state, action) => {
        state.isSubmitting = false;
        state.signupError = action.payload || 'Failed to fetch data';
      });
  },
});

export const {
  setEmail,
  setPassword,
  setConfirmPassword,
  setRoleId,
  setEmailError,
  setPasswordError,
  setConfirmPasswordError,
  setSignupError,
  setShowPasswordChecklist,
  updateCriteria,
  setCompanyId,
} = signupSlice.actions;

export default signupSlice.reducer;

