import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import Select from "react-select";
import { FaTimes } from "react-icons/fa";
import { sendInvitations, fetchCompanyUsers } from "../../Redux/Actions/Actions"; 
import { ROLES,is_company_primary_admin,is_editor,is_esg_consultant,is_esg_consultant_owner,is_viewer,our_organization,is_workspace_admin } from "../../config/constants";
import Loader from "../../config/Loader";
import { toast } from "react-toastify";
import { FaInfoCircle } from "react-icons/fa";
// const { 1: _, 5: __, ...newRoles } = ROLES;
let  roleOptions 
const InviteUser = ({ show, handleClose, companyName, companyId, activeCompanyUserTab }) => {
  const [selectedOption, setSelectedOption] = useState('');
  const [email, setEmail] = useState("");
  const [invitations, setInvitations] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const emailInputRef = useRef(null);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const current_role = localStorage.getItem("selected_roleId");
  let is_our_organization = (activeCompanyUserTab != our_organization)? false : true
  useEffect(() => {
    if (show && emailInputRef.current) {
      emailInputRef.current.focus();
    }
  }, [show]);
  const shouldHideInviteUser = (role, tab) => {
    if (role == is_esg_consultant_owner && !tab ) {
      const { 1: _, 2: __, 3:___,4:____,...newRoles } = ROLES;
      return Object.entries(newRoles).map(([value, label]) => ({ value: parseInt(value), label }));
    } else if (role == is_esg_consultant_owner && tab) {
      const { 1: _, 5: __,6:___,...newRoles } = ROLES;
      return Object.entries(newRoles).map(([value, label]) => ({ value: parseInt(value), label }));
    }
    if (role == is_esg_consultant && !tab ) {
      const { 1: _, 2: __, 3:___,4:____,...newRoles } = ROLES;
      return Object.entries(newRoles).map(([value, label]) => ({ value: parseInt(value), label }));
    } else if (role == is_esg_consultant && tab) {
      const { 1: _, 5: __,6:___,...newRoles } = ROLES;
      return Object.entries(newRoles).map(([value, label]) => ({ value: parseInt(value), label }));
    } else if (role == is_editor && !tab) {
      let above6Roles ={}
      for (const key in ROLES) {
        if (key >= 6) {
          above6Roles[key] = ROLES[key];
        }
      }
      return Object.entries(above6Roles).map(([value, label]) => ({ value: parseInt(value), label }));
    }else if (role == is_workspace_admin && !tab) {
      let above6Roles ={}
      for (const key in ROLES) {
        if (key > 6) {
          above6Roles[key] = ROLES[key];
        }
      }
      return Object.entries(above6Roles).map(([value, label]) => ({ value: parseInt(value), label }));
    }
    else if (role == is_company_primary_admin && !tab) {
      let above6Roles ={}
      for (const key in ROLES) {
        if (key > 5) {
          above6Roles[key] = ROLES[key];
        }
      }
      return Object.entries(above6Roles).map(([value, label]) => ({ value: parseInt(value), label }));
    }
    else{
      const { 1: _, 5: __, ...newRoles } = ROLES;
      return Object.entries(newRoles).map(([value, label]) => ({ value: parseInt(value), label }));
    }
  };
  roleOptions=shouldHideInviteUser(current_role,is_our_organization)
  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleAddInvite();
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleAddInvite = () => {
    if (!email) return;

    if (!isValidEmail(email)) {
      setErrorMessage("Invalid email address.");
      return;
    }

    if (invitations.some(invite => invite.email === email)) {
      setErrorMessage("Email already added.");
      return;
    }

    if (email && selectedOption) {
      setInvitations([...invitations, { email, role_id: selectedOption.value }]);
      setEmail("");
      setSelectedOption(roleOptions[0]);
      setErrorMessage("");
    }
  };

  const handleRoleChange = (index, newRole) => {
    const updatedInvitations = invitations.map((invitation, i) =>
      i === index ? { ...invitation, role_id: newRole.value } : invitation
    );
    setInvitations(updatedInvitations);
  };

  const handleRemoveInvite = (index) => {
    setInvitations(invitations.filter((_, i) => i !== index));
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (errorMessage) {
      setErrorMessage("");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let invitesToSend = [...invitations];

    if (email && selectedOption) {
      if (!isValidEmail(email)) {
        setErrorMessage("Invalid email address.");
        return;
      }

      if (invitations.some(invite => invite.email === email)) {
        setErrorMessage("Email already added.");
        return;
      }

      invitesToSend.push({ email, role_id: selectedOption.value });
    }

    if (invitesToSend.length === 0) {
      setErrorMessage("Please add at least one invitation.");
      return;
    }

    setLoading(true);

    const payload = {
      invites: invitesToSend,
      company_id: companyId,
      company_name: companyName,
      admin_user_id: localStorage.getItem('user_info_id'),
    };

    dispatch(sendInvitations(payload))
      .unwrap()
      .then(() => {
        toast.success("Invitations sent successfully!");
        resetState();
        handleClose();
        dispatch(fetchCompanyUsers(companyId))
      })
      .catch((error) => {
        toast.error(`Failed to send invitations: ${error.message}`);
        setErrorMessage(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const resetState = () => {
    setEmail("");
    setSelectedOption(roleOptions[0]);
    setInvitations([]);
    setErrorMessage("");
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      border: "none",
      boxShadow: "none",
      backgroundColor: "#F4F4F4",
      paddingRight: "10px"
    }),
    indicatorSeparator: () => ({
      display: "none"
    }),
    singleValue: (base) => ({
      ...base,
      textAlign: "right",
      width: "100%",
    }),
    valueContainer: (base) => ({
      ...base,
      justifyContent: "flex-end"
    }),
  };

  const reactSelectCustomStyles = {
    control: (base) => ({
      ...base,
      border: "none",
      boxShadow: "none",
      backgroundColor: "#fff",
      paddingRight: "10px"
    }),
    indicatorSeparator: () => ({
      display: "none"
    }),
    singleValue: (base) => ({
      ...base,
      textAlign: "right",
      width: "100%",
    }),
    valueContainer: (base) => ({
      ...base,
      justifyContent: "flex-end"
    }),
  };

  return (
    <Offcanvas show={show} onHide={() => { handleClose(); }} style={{ width: "40%" }} placement="end">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title className="invite_user_heading">Invite User</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="d-flex flex-column">
        <form onSubmit={handleSubmit} className="d-flex flex-column h-100">
          <label className="invite_by_email_text" htmlFor="email">
            Invite by email
          </label>
          <div className="invite_user_container d-flex align-items-center">
            <div className="input-container">
              <input
                type="email"
                placeholder="Email"
                className="invite-email"
                id="email"
                ref={emailInputRef}
                value={email}
                onChange={handleEmailChange}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div className="select-container">
              <Select
                id="role"
                value={selectedOption}
                onChange={setSelectedOption}
                options={roleOptions}
                className="react-select-container"
                classNamePrefix="react-select"
                placeholder="Select Role"
                styles={{ ...customStyles, width: "100%" }}
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>
          <div className="note mt-2">
            <FaInfoCircle className="me-1" />
            <span>To add multiple invites, enter the email and role, then press the Enter key.</span>
          </div>
          {errorMessage && <div className="error_message">{errorMessage}</div>}
          {invitations.length > 0 && (
          <div className="invite_container">
            <span className="invite_text">Invite</span>
            {invitations.map((invitation, index) => (
              <div key={index} className="d-flex justify-content-between align-items-center invitation_card">
                <span className="email_id">{invitation.email}</span>
                <Select
                  value={roleOptions.find(option => option.value === invitation.role_id)}
                  onChange={(newRole) => handleRoleChange(index, newRole)}
                  options={roleOptions}
                  classNamePrefix="react-select"
                  placeholder="Select Role"
                  styles={reactSelectCustomStyles}
                />
                <FaTimes
                  className="remove_icon"
                  onClick={() => handleRemoveInvite(index)}
                />
              </div>
            ))}
          </div>
          )}
          <div className="btn_container mt-auto">
            <Button type="button" className="invite_cancel_btn" onClick={() => { resetState(); handleClose(); }}>
              Cancel
            </Button>
            <Button type="submit" className="send_invite_btn">
              {loading ? (
                <Loader />
              ) : (
                "Send Invite"
              )}
            </Button>
          </div>
        </form>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default InviteUser;
