import { React, useState, useEffect, useContext } from "react";
import { FaBars, FaBell } from "react-icons/fa";
import { GoHome } from "react-icons/go";
import { TbReport, TbLogout } from "react-icons/tb";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { MdOutlineSignalCellularAlt, MdManageAccounts } from "react-icons/md";
import { GrDocumentUser } from "react-icons/gr";
import { LuUsers2 } from "react-icons/lu";
import { FaRegBell } from "react-icons/fa";
import { HiOutlineArrowLeft } from "react-icons/hi";
import { GoPeople } from "react-icons/go";
import NavbarContext from "./NavbarContext";
import "../../Reusable/ReusableStyles/Navbar.css";
import { useDispatch, useSelector } from 'react-redux';
import { setIsEmailInvite, setRoleId, setEmail } from '../../Redux/Slices/UserLoginFlowSlices/LoginSlice.js';
import { persistor } from "../../Redux/Store.js";
const Navbar = () => {

  const dispatch = useDispatch();

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { iterationId, companyId } = useParams();
  const [showIcons, setShowIcons] = useState(true);
  const [showCategoryName, setShowCategoryName] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const { categoryName, companyName } = useContext(NavbarContext);
  const mylocation = window?.location?.pathname;
  let user_roles = useSelector((state) => state?.login?.unique_user_info)
  const myurls = [
    {
      // url: "/home",
      url: "/dashboard",
      icon: <GoHome className="home-icon" />,
      tooltipid: "home-tooltip",
      content: "Dashboard",
    },
    {
      url: "/companieslist",
      icon: <MdOutlineSignalCellularAlt className="home-icon" />,
      tooltipid: "MdOutlineSignalCellularAlt-tooltip",
      content: "Companies",
    },
    {
      url: "/Users",
      icon: <GoPeople className="home-icon" />,
      tooltipid: "GoPeople-tooltip",
      content: "Users / Groups",
    },
    // {
    //   url: "#",
    //   icon: <LuUsers2 className="home-icon" />,
    //   tooltipid: "LuUsers2-tooltip",
    //   content: "Users / Groups",
    // },
    // {
    //   url: "#",
    //   icon: <GrDocumentUser className="home-icon" />,
    //   tooltipid: "GrDocumentUser-tooltip",
    //   content: "Documents",
    // },
    {
      url: "/iterations",
      icon: <TbReport className="reports-icon" />,
      tooltipid: "reports-tooltip",
      content: "Reports",
    },
  ];

  const onSwitchRoles = () => {
    navigate('/selectrole')
  }

  const onLogout = () => {
    // localStorage.removeItem("userId");
    // localStorage.removeItem("access_token");
    // localStorage.setItem("activeTab", "home");
    // localStorage.removeItem("thru_email");
    // localStorage.removeItem("iterationId");
    // localStorage.removeItem("companyId");
    // localStorage.removeItem("popUpData");
    dispatch(setIsEmailInvite(0))
    dispatch(setRoleId(0));
    dispatch(setEmail(''));
    localStorage.clear();
    persistor.flush();
    persistor.purge();
    localStorage.removeItem('persist:root');
    navigate("/");
    setSidebarOpen(false);

  };

  const onIconClick = (item) => {
    navigate(item.url);
    setSidebarOpen(false); // Close sidebar on navigation
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  useEffect(() => {
    if (location.pathname.includes('/report') || location.pathname.includes('/mobileReports')) {
      setShowIcons(false);
    } else {
      setShowIcons(true);
    }
    if (location.pathname.includes('/mobileReports')) {
      setShowCategoryName(true);
    } else {
      setShowCategoryName(false);
    }
  }, [location.pathname]);

  const navigateToSummaryReports = () => {
    // navigate(`/summaryReport/${iterationId}/${companyId}`);
    if (location.pathname.includes('/mobileReports')) {
      navigate(`/report/${iterationId}/${companyId}`);
    } else {
      navigate(`/summaryReport/${iterationId}/${companyId}`);
    }
  }

  return (
    <>
      <div className="navbar-container monitor-navbar">
        <div>
          <img src="./images/avatar.png" alt="company logo" className="company-logo" />

          <nav>
            <ul className="nav-list">
              {myurls.map((i) => (
                <div key={i.url}>
                  <li
                    className={`nav-item ${mylocation == i.url ? "bg-green" : "bg-white"}`}
                    onClick={() => onIconClick(i)}
                    data-tooltip-id={i.tooltipid}
                  >
                    {i.icon}
                  </li>
                  <Tooltip
                    id={i.tooltipid}
                    place="right-end"
                    content={i.content}
                    opacity={0.7}
                    style={{
                      backgroundColor: "#00A656",
                      color: "#fff",
                      fontWeight: "600",
                    }}
                  />
                </div>
              ))}
            </ul>
          </nav>
        </div>
        <div className="logout-section">
           <div
            className={`logout-container mb-2 ${user_roles?.length >1? '':'disabled'}`}
            onClick={onSwitchRoles}
            data-tooltip-id="switch-tooltip"
          >
            <img src="./images/switch_role.svg" />
          </div>
          <Tooltip
            id="switch-tooltip"
            place="right-end"
            content="Switch Roles"
            opacity={0.7}
            style={{
              backgroundColor: "#00A656",
              color: "#fff",
              fontWeight: "600",
            }}
          />
          <div
            className="logout-container"
            onClick={onLogout}
            data-tooltip-id="logout-tooltip"
          >
            <TbLogout className="logout-icon" />
          </div>
          <Tooltip
            id="logout-tooltip"
            place="right-end"
            content="Logout"
            opacity={0.7}
            style={{
              backgroundColor: "#00A656",
              color: "#fff",
              fontWeight: "600",
            }}
          />
        </div>
      </div>


      {/* Mobile Navbar */}
      <div className="navbar mobile-navbar">
        {
          !showIcons ? (
            <span className="d-flex justify-content-center"><HiOutlineArrowLeft className="back-icon" onClick={navigateToSummaryReports} /> {showCategoryName && <span className="category_name">{categoryName}</span>}</span>
          ) : (
            <>
              <div className="navbar-left">
                <FaBars className="icon" onClick={toggleSidebar} />
              </div>
              <div className="navbar-right">
                {/* <div className="company_container">
                  <img
                    src={userCompany}
                    alt="company logo"
                    className="userCompanylogo"
                  />
                </div> */}
                <span className="company-name">
                  {companyName}
                </span>
                <FaRegBell className="bell-icon" />
              </div>
            </>
          )
        }
      </div>
      <div className={`sidebar ${sidebarOpen ? "open" : ""}`}>
        <div className="d-flex flex-column justify-content-between mobile-navbar-container">
          <div>
            <div className="d-flex justify-content-center mb-4">
              <div className="navbar-logo-container">
                <img
                  src="./images/esgPilotLogo.svg"
                  alt="company logo"
                  className="esgPilot-logo"
                />
              </div>
            </div>
            {/* <div className="my_account_container mb-2">
              <p className="my_account_heading mb-2">My Account</p>
              <div className="profile_container">
                <div className="profile_card ">
                  <img src={logo} alt="profile" className="profile_pic" />
                  <p className="user_name">Mahesh</p>
                </div>
              </div>
            </div> */}
            <nav>
              <ul className="nav-list">
                <p className="my_account_heading">Menu</p>

                {myurls.map((i) => (
                  <div key={i.url}>
                    <li
                      className={`nav-item ${mylocation === i.url ? "active-tab" : "bg-white"
                        }`}
                      onClick={() => onIconClick(i)}
                      data-tooltip-id={i.tooltipid}
                    >
                      {i.icon}
                      <span className="nav-item-text">{i.content}</span>
                    </li>
                  </div>
                ))}
              </ul>
            </nav>
          </div>
          <div
            className={`logout-container  ${user_roles?.length >1? '':'disabled'}`}
            onClick={onSwitchRoles}
            data-tooltip-id="switch-tooltip"
          >
            <img src="./images/switch_role.svg" />
            <span className="nav-item-text">Switch Roles</span>
          </div>
          <div
            className="logout-container"
            onClick={onLogout}
            data-tooltip-id="logout-tooltip"
          >
            <TbLogout className="logout-icon" />
            <span className="nav-item-text">Logout</span>
          </div>
        </div>
      </div>
      <div
        className={`navbar-overlay ${sidebarOpen ? "show" : ""}`}
        onClick={toggleSidebar}
      ></div>
    </>
  );
};

export default Navbar;
