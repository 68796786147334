import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useDropzone } from 'react-dropzone';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import countriesData from '../../countries.json';
import { useDispatch, useSelector } from 'react-redux';
import { addOrUpdateCompany, fetchCompanies } from '../../Redux/Actions/Actions';
import Loader from '../../config/Loader.jsx';

const AddCompany = ({ show, handleClose, isEdit, companyData }) => {
  const dispatch = useDispatch();
  const [company, setCompany] = useState({
    company_logo: null,
    company_name: '',
    location: '',
    industry: '',
    company_email: '',
    company_phone_number: '',
    total_employees: '',
    total_departments: '',
    company_address: '',
    company_city: '',
    company_postal_code: '',
    hq_country: 'Other/Unspecified', // Default to "Other/Unspecified"
    company_id: '',
    admin_user_id: '',
  });
  const [logoPreview, setLogoPreview] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isEdit && companyData) {
      setCompany({
        ...companyData,
        company_logo: companyData.company_logo ? companyData.company_logo : null,
      });
      setLogoPreview(companyData.company_logo);
    } else {
      setCompany({
        company_logo: null,
        company_name: '',
        location: '',
        industry: '',
        company_email: '',
        company_phone_number: '',
        total_employees: '',
        total_departments: '',
        company_address: '',
        company_city: '',
        company_postal_code: '',
        hq_country: 'Other/Unspecified', // Default to "Other/Unspecified"
        company_id: '',
        admin_user_id: localStorage.getItem('user_info_id'),
      });
      setLogoPreview(null);
    }
  }, [isEdit, companyData]);

  const handleUploadClick = (e) => {
    // Your logic for handling the upload click goes here
    const company_logo = e.target.files[0];
    if (company_logo) {
      if (!['image/png', 'image/jpg','image/jpeg',].includes(company_logo.type)) {
        setErrorMessage('Only image files ( PNG, JPG,jpeg) are allowed.');
        return;
      }
      setErrorMessage('');

      setCompany({ ...company, company_logo });
      const previewUrl = URL.createObjectURL(company_logo);
      setLogoPreview(previewUrl);
    }
    // You can add more functionality here, like opening a file input or triggering an upload process.
  };



  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'company_postal_code' && value.length > 6) {
      return;
    }
    if (name === 'company_phone_number' && value.length >10) {
      return;
    }
    setCompany({ ...company, [name]: value });
  };

  const onDrop = (acceptedFiles) => {
    const company_logo = acceptedFiles[0];
    if (company_logo) {
      if (!['image/png', 'image/jpg','image/jpeg'].includes(company_logo.type)) {
        setErrorMessage('Only image files ( PNG, JPG,jpeg) are allowed.');
        return;
      }
      setErrorMessage('');
      setLogoPreview(URL.createObjectURL(company_logo));
      setCompany({ ...company, company_logo });
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple:false,
    accept: {
      'image/png': ['.png'],
      'image/jpeg':['.jpeg'],
      'image/jpg':['.jpg']
    }
    

  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const {payload} = await dispatch(addOrUpdateCompany({ companyData: company, isEdit }));
      if (payload.status === 200) {
        if (payload.message === "Company name already exist") {
          const errorMessage = payload.message || `Failed to ${isEdit ? 'update' : 'add'} company`;
          toast.warning(errorMessage, { toastId: 22 });
          handleClose();
        } else {
          if (payload.message === "Company Data Updated Successfully") {
            toast.success(payload.message);
            handleClose();
          } else if (payload.message === "Company added successfully and also invite email sent") {
            toast.success(payload.message);
            handleClose();
          }
          setLoading(true);
          const user_info_id = localStorage.getItem('user_info_id')
          const role_id = localStorage.getItem('selected_roleId')
          await dispatch(fetchCompanies({ user_info_id, role_id }));
        }
      } else {
        throw new Error(`Failed to ${isEdit ? 'update' : 'add'} company`);
      }
    } 
    catch (error) {
      console.error('Error:', error);
    }
    finally {
      setTimeout(() => {
        setLoading(false);
      }, 100);
    }
  };

  const isFormValid = () => {
    return company.company_name && company.industry && company.company_email;
  };

  return (
   
    <>
     {loading ? (
      <Loader /> // Show loader
    ) : (
      (<Offcanvas show={show} onHide={handleClose} style={{ width: '45%' }} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="custom-heading">
            {isEdit ? "Edit Company Details" : "Add Company Details"}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <form onSubmit={handleSubmit}>
            <div className="drop_zone_container">
              <div><h6 className="company_logo">Company Logo</h6></div>
              {!isEdit ? <div className="drop_zone_styles" {...getRootProps()}>
                <input {...getInputProps()}              
                 />
                {logoPreview ? (
                  <img src={logoPreview} alt="Company Logo" className="uploaded-logo" style={{ height: "100px", width: "100px" }} />
                ) : (
                  <>
                    <img src="./images/drag_drop.svg" alt="drop" className="drop-icon" />
                    <p className="drag_drop_text">Drop files to Attach or <span className="browse_span">Browse</span> </p>
                  </>
                )}
              </div> :
                <div className="image_edit">
                  <div className='row col-12'>
                    <div className="col-4 image_edit_body">
                      <img src={logoPreview ? logoPreview : company.company_logo} alt="Company Logo" className="uploaded-logo" style={{ height: "100px", width: "100px" }} />
                    </div>
                    <div className='col-4'></div>
                    <div className='col-4'>

                      <label htmlFor="customUploadButton" className="custom-upload-button-label-edit">
                        Upload New Logo
                      </label>
                      <input
                        type="file"
                        accept=".png, .jpeg, .jpg"
                        className="upload-button"
                        id="customUploadButton"
                        onChange={handleUploadClick}
                      />

                    </div>
                  </div>
                </div>
              }
              {errorMessage && <div className="error-message">{errorMessage}</div>}
            </div>

            <div className="drop_zone_container">
              <div className="row row_input_field">
                <div className="col-md-5 col-lg-6">
                  <label className="custom-label custom-label-top">Company Name <span style={{ color: 'red' }}>*</span></label>
                  <input
                    type="text"
                    name="company_name"
                    value={company.company_name}
                    onChange={handleChange}
                    className="form-control custom-input"
                    required
                  />
                </div>
                <div className="col-md-5 col-lg-6">
                  <label className="custom-label custom-label-top">Industry <span style={{ color: 'red' }}>*</span></label>
                  <select
                    name="industry"
                    value={company.industry}
                    onChange={handleChange}
                    className="form-control custom-input custom-select"
                    required
                  >
                    <option value="" disabled>Select Industry</option>
                    <option value="technology">Technology</option>
                    <option value="finance">Finance</option>
                    <option value="healthcare">Healthcare</option>
                    <option value="education">Education</option>
                    <option value="manufacturing">Manufacturing</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <label className="custom-label">Email <span style={{ color: 'red' }}>*</span></label>
                  <input
                    type="text"
                    name="company_email"
                    value={company.company_email}
                    onChange={handleChange}
                    className="form-control custom-input"
                    required
                  />
                </div>
                <div className="col-lg-6">
                  <label className="custom-label">Phone number</label>
                  <input
                    type="text"
                    name="company_phone_number"
                    value={company.company_phone_number}
                    onChange={handleChange}
                    className="form-control custom-input"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <label className="custom-label">Total Employees</label>
                  <input
                    type="number"
                    name="total_employees"
                    value={company.total_employees}
                    onChange={handleChange}
                    className="form-control custom-input"
                  />
                </div>
                <div className="col-lg-6">
                  <label className="custom-label">Total Departments</label>
                  <input
                    type="number"
                    name="total_departments"
                    value={company.total_departments}
                    onChange={handleChange}
                    className="form-control custom-input"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <label className="custom-label">Company address</label>
                  <input
                    type="text"
                    name="company_address"
                    value={company.company_address}
                    onChange={handleChange}
                    className="form-control custom-input"
                  />
                </div>
                <div className="col-lg-6">
                  <label className="custom-label">City</label>
                  <input
                    type="text"
                    name="company_city"
                    value={company.company_city}
                    onChange={handleChange}
                    className="form-control custom-input"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <label className="custom-label">Postal Code</label>
                  <input
                    type="number"
                    name="company_postal_code"
                    value={company.company_postal_code}
                    onChange={handleChange}
                    className="form-control custom-input"
                  />
                </div>
                <div className="col-lg-6">
                  <label className="custom-label">HQ Country</label>
                  <select
                    className="form-control custom-input custom-select"
                    id="country"
                    name="hq_country"
                    value={company.hq_country}
                    onChange={handleChange}
                    required
                  >
                    {countriesData.map((eachCountry) => (
                      <option key={eachCountry.id} value={eachCountry.name}>
                        {eachCountry.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            <div className="filter_btn_container d-flex flex-row justify-content-end">
              {isEdit ? (
                <>
                  <Button type="submit" className="btn btn-success invite_cancel_btn" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button type="submit" className=" btn btn-success send_invite_btn" disabled={!isFormValid()}>
                    Save
                  </Button>
                </>
              ) : (
                <Button type="submit" className="custom_button" disabled={!isFormValid()}>
                  Add company
                </Button>
              )}
            </div>
          </form>
        </Offcanvas.Body>
      </Offcanvas>)
       )}
      <ToastContainer
      position='top-center'
      hideProgressBar={true}
      autoClose={2000}
           
       />
            

    </>
  );
}

export default AddCompany;

