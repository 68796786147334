import React from 'react'
import './PopupCardStyles/WelcomeCard.css'
export default function WelcomeCard({showModal, onAction}) {
  
  if (!showModal) return null;
  return (

    <div>
      
          <div className='overlay'>
            <div className='container welcome-custom-modal'>
              <div className='row justify-content-center d-flex flex-column align-items-center'>
                <div className='col-12 col-md-10 col-lg-8  welcome_card_container '>
                    <img src="./images/Welcome.png" alt='welcomeNote' className='welcome_note_img'/>
                    <p className='welcome_heading '>Welcome!</p>
                    <p className='welcome_paragraph'> It seems like this is your first visit. To get started, just click here.</p>
                </div>
                <button className='start_button' onClick={onAction}>Start</button>
              </div>
            </div>
          </div>
        
        </div>
  
   
  )
}
