import { useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/pages/PagesComponents/Home'
import FileUpload from './Reusable/ReusableComponents/FileUpload'
import Report from './components/Reports/ReportsComponents/Report';
import Iterations from './components/pages/PagesComponents/Iterations';
import SummaryReport from './components/pages/PagesComponents/SummaryReport';
import Signup from './components/UserLoginFlow/UserLoginFlowComponents/Signup';
import Login from './components/UserLoginFlow/UserLoginFlowComponents/Login'
import NavbarContext from './Reusable/ReusableComponents/NavbarContext';
import ProtectedRoute from './components/ProtectedRoute';
import PdfGenerator from './components/Reports/ReportsComponents/PdfGenerator';
import ForgotPassword from './components/UserLoginFlow/UserLoginFlowComponents/ForgotPassword';
import ResetPassword from './components/UserLoginFlow/UserLoginFlowComponents/ResetPassword';
import FormDataSection from './components/Reports/ReportsComponents/FormDataSection';
import SelectRole from './components/PopUpCards/SelectRole';
import CompaniesList from './components/EsgConsultantCompaniesFlow/CompaniesList'
import ActionCard from './components/EsgConsultantCompaniesFlow/ActionCard';
import CompanyDetails from './components/EsgConsultantCompaniesFlow/CompanyDetails';
import UserProfile from './Reusable/ReusableComponents/UserProfile';
import Dashboard from './components/Dashboard';
import Users from './components/EsgConsultantUsersFlow/Users';

const App = () => {
  const [isLoginPage, setIsLoginPage] = useState(true);
  const [categoryName, setCategoryName] = useState('');
  const [category, setCategory] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [company,setCompany]= useState('');

  return (
    <NavbarContext.Provider value={{
      isLoginPage,
      setIsLoginPage,
      categoryName,
      setCategoryName,
      category,
      setCategory,
      companyName,
      setCompanyName,
      company,
      setCompany,
      

    }}>
      <Routes>
        <Route element={<ProtectedRoute />} >
          
          <Route path="/home" element={<Home />}  />
          <Route path= "/dashboard" element = {<Dashboard />} />

          <Route path="/home" element={<Home />} />
          <Route path="/selectrole" element={<SelectRole />} />
          <Route path="/companieslist" element={<CompaniesList />} />
          <Route path="/actionCard" element={<ActionCard />} />
          <Route path="/companyDetails" element={<CompanyDetails />} />

          <Route path="/fileUpload" element={<FileUpload />} />
          <Route path="/report/:iterationId/:companyId" element={<Report />} />
          <Route path="/mobileReports/:iterationId/:companyId" element={<FormDataSection />} />
          <Route path="/iterations" element={<Iterations />} />
          <Route path="/users" element={<Users />} />
          <Route path="/summaryReport/:iterationId/:companyId/:encrypted_email?" element={<SummaryReport />} />
        <Route path="/UserProfile" element={<UserProfile />} />
        </Route>
        <Route path="/signup" element={<Signup />} />
        <Route path="/signup/:encrypted_email?" element={<Signup />} />
        <Route path="/:encrypted_email?" element={<Login />} exact />
        <Route path="/pdf" element={<PdfGenerator />} />
        <Route path="/forgotPassword" element={<ForgotPassword />} />
        <Route path="/resetPassword/:access_token" element={<ResetPassword />} />

      </Routes>
    </NavbarContext.Provider>
  );
};

export default App;
