import { createSlice } from '@reduxjs/toolkit';
import { fetchDashboardData } from '../Actions/Actions';


const initialState = {
  loading: false,
  error: null,
  companies: [],
};

const dashboardSlice = createSlice({
  name: 'dashboardData',
  initialState,
  reducers: {
    // You can define reducers here if needed in the future
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDashboardData.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.companies = action.payload; 
      })
      .addCase(fetchDashboardData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// export const selectCompanyUsers = (state) => state.companyUsers.companies;

export default dashboardSlice.reducer;
