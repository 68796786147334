import { createSlice } from '@reduxjs/toolkit';
import { fetchUserProfile } from '../../../Redux/Actions/Actions.js';

const initialState = {
    profile: null,
    companies: [],
    status: 'idle',
    error: null
};

const userProfileSlice = createSlice({
    name: 'userProfile',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserProfile.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchUserProfile.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.profile = action.payload.user_profile;
                state.companies = action.payload.user_companies_roles;
            })
            .addCase(fetchUserProfile.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    }
});

export default userProfileSlice.reducer;
